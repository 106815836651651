import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import config from '../config/config';

import translation from "../config/translation.js";
import Printer from "@/services/printer";
import UCMotionScreenApi from "@/services/api";

import errors from "@/config/error_messages";
//import Logger from "@/services/logger";

const api = new UCMotionScreenApi(config.qFlowHost, config.endPointURL);

const index = new Vuex.Store({
    state: {
        logger: null,
        debug: false,
        /*********
         *
         * QFLOW STATE
         *
         */

        streamData: null,
        printer: null,

        qflowTimers: [], //timers used to get new tickets information
        qflowBusy: [],

        /* FAILURE MANAGEMENT */
        failureTimer: null,
        failureRecoveryRetrySeconds: 0,
        failureRecoveryAttempts: 0,
        failureRecoveryDefaultSeconds: 30,

        no_internet_connection: false,

        /* FOOTER */
        footerText: {
            "en": config.queuesScreen.footer_en, //"Follow the queues at ucstudenthub.uc.pt",
            "pt": config.queuesScreen.footer_pt //"Acompanhe as filas em ucstudenthub.uc.pt"
        },
        mode: null, // screen, dispenser or broadcast
        apiKeys: [],
        servicesData: [], //data to query services queues

        /* QUEUES SCREEN */
        autoLanguageChangeSeconds: 10,

        /* DISPENSER */
        selectedServicesIDS: [], //selected ids of the services to get tickets from
        printerIP: "", //IP of the ticket printer
        newTicketData: null, // loading or {}
        resetInterfaceTimer: null,

        /* GET NEW DATA */
        refreshDataSeconds: config.refreshDataIntervalSeconds, //30,

        /* TICKETS TO BE CALLED QUEUE */
        //array com senhas a serem chamadas ao mesmo tempo
        //(ex: se várias senhas são chamadas ao mesmo tempo estas passam para esta fila para serem
        // anunciadas uma de cada vez)
        callingTickets: [],
        callingTimeSeconds: config.callingNewTicketSeconds, //3,

        /* INTERFACE LANGUAGE */
        languagesAvailable: ['en','pt'],
        selectedLanguage: 'pt',
        interactiveScreens: [],

        /**********
         * Broadcast app state
         */
        screenID: "", // id do ecrã na rede
        token: "",
        infoPlaylist: [], //série de ecrãs/layouts a passar no modo informativo
        newInfoPlaylist: [], //for remote updates without interruptions
        carouselSeconds: config.carouselSeconds, //20, //tempo em cada layout

        //TODO: replace everything with this one:
        currentScreenData: null, //data of current page

        main_playlist_counter: 0,

        refreshDataTimer: null,
        currentDataRefreshRate: config.refreshDataMinutes*60, //in seconds

        // loading new data from server
        loading_data: false,

        /* ERROR MESSAGE */
        showError: false,
        errorMessage: "",
        errorTimer: null,

        /***
         *
         * INTERACTIVE SCREENS DATA
         */

        interactiveNavigationPath: [],
        //Timer of no activity
        no_activity_timer: null,

        auto_language_change_timer: null
    },
    mutations: {
        setDebug(state, status) {
            state.debug = status
        },
        setLogger(state, logger) {
          state.logger = logger;
        },
        setQflowBusyRequests(state, [index,status]) {
            Vue.set(state.qflowBusy,index,status);
        },
        setQflowTicketTimer(state, [index,timer]) {
            Vue.set(state.qflowTimers,index,timer);
        },
        setAutoLanguageTimer(state, timer) {
            state.auto_language_change_timer = timer;
        },
        autoChangeLanguage(state) {
          if(state.selectedLanguage === 'pt') {
              state.selectedLanguage = 'en';
          } else {
              state.selectedLanguage = 'pt';
          }
        },
        setPrinter(state, printer) {
            state.printer = printer;
        },

        /*****
         * Queues mutations
         *
         */

        /* FOOTER EDITOR */
        saveFooterText(state,data) {
            let language = data.language.toLowerCase();
            state.footerText[language] = data.text;
            localStorage.setItem('footer_'+language, JSON.stringify(data.text));
        },
        setFooterText(state,data) {
            let language = data.language.toLowerCase();
            state.footerText[language] = data.text;
        },

        setNoInternet(state,data) {
            state.no_internet_connection = data;
        },

        /* FAILURE RECOVERY */
        setFailureTimer(state, data) {
            state.failureTimer = data;
        },
        setFailureRetrySeconds(state, seconds) {
            state.failureRecoveryRetrySeconds = seconds;
        },
        setFailureRecoveryAttempts(state, attempts) {
            state.failureRecoveryAttempts = attempts;
        },

        /* API KEYS */
        setApiKeys(state, data) {
            state.apiKeys = data;
        },
        addApiKey(state,apiKey) {
            state.apiKeys.push(apiKey);
            localStorage.setItem('apiKeys', JSON.stringify(state.apiKeys));
        },
        /* SELECTED SERVICES IDS - DISPENSER */
        selectService(state,serviceID)  {
            state.selectedServicesIDS.push(serviceID)
            localStorage.setItem('selectedServicesIDS', JSON.stringify(state.selectedServicesIDS));
        },
        setSelectedServicesIDS(state,selectedServicesIDs) {
            state.selectedServicesIDS = selectedServicesIDs;
        },
        saveServices(state,selectedServicesIDs) {
            state.selectedServicesIDS = selectedServicesIDs;
            localStorage.setItem('selectedServicesIDS', JSON.stringify(state.selectedServicesIDS));
        },
        setPrinterIP(state,printerIP) {
            if(printerIP !== '0.0.0.0') {
                state.printerIP = printerIP;
            }
        },
        /* RESET INTERFACE */
        resetUI(state) {
            state.newTicketData = null;
        },
        setUIresetTimer(state, data) {
            state.resetUITimer = data;
        },

        /* CALL A TICKET */
        addCallingTicket(state, data) {
            if (state.callingTickets.filter(e => e.ticketNumber === data.ticketNumber && e.workstationName === data.workstationName).length === 0) {
                //only add if it doesn't exist
                if(state.debug) {
                    console.log("ADD CALLING TICKET", data)
                }
                state.callingTickets.push(data);
            } else {
                if(state.debug) {
                    console.log("TICKET ALREADY EXISTS IN THE CALLING QUEUE", data)
                }
            }
        },
        removeCallingTicket(state) {
            state.callingTickets.shift();
        },
        /* Add service */
        setServicesData(state, servicesData) {
            state.servicesData = servicesData;
        },
        addService(state, service) {
            state.servicesData.push(service);
        },
        setServiceQueues(state, data) {
            /*
            data.serviceIndex,
            data.queues
             */
            state.servicesData[data.serviceIndex].queues = data.queues;
        },
        /* TO ANIMATE LONG SERVICE NAMES */
        setLongQueueName(state,index) {
            state.servicesData[index].long_name = true;
        },
        removeAllServices(state) {
            state.servicesData = [];
        },
        /* Device mode: screen / dispenser */
        setCurrentMode(state, mode) {
            state.mode = mode;
            localStorage.setItem('deviceMode', JSON.stringify(mode));
        },
        restoreMode(state, mode) {
            state.mode = mode;
        },
        /* Change main screen layout */
        setCurrentScreen(state, screen) {
            state.currentScreenData = {
                'type': screen
            };
        },

        setCurrentLanguage(state, language) {
            state.selectedLanguage = language;
        },
        setNewTicketData(state, ticketData) {
            state.newTicketData = ticketData;
        },

        /*****
         *
         * Broadcast mutations
         */

        setRefreshDataTimer(state, timer) {
            state.refreshDataTimer = timer;
        },
        setRefreshDataRate(state, seconds) {
            state.currentDataRefreshRate = seconds;
        },
        /* SHOW ERROR MESSAGE */
        setShowError(state, data) {
            state.showError = data;
        },
        setErrorTimer(state, data) {
            state.errorTimer = data;
        },
        setErrorMessage(state, data) {
            state.errorMessage = data;
        },

        setLoading(state,data) {
            state.loading_data = data;
        },

        restoreApiKeys(state,data) {
            state.screenID = data.screenID;
            state.token = data.token;
        },
        setInfoPlaylistScreenData(state, data) {
            if(state.infoPlaylist.length>0) {
                state.newInfoPlaylist = data;
            } else {
                state.infoPlaylist = data;
            }
        },
        // Replace old playlist by the new playlist
        finishNewPlaylistTransition(state) {
            state.infoPlaylist = state.newInfoPlaylist;
            state.newInfoPlaylist = []
        },
        setSliderSeconds(state, seconds) {
            state.carouselSeconds = seconds;
        },
        resetCounters(state) {
            state.main_playlist_counter = 0;
        },
        setMainCounter(state, index) {
            state.main_playlist_counter = index;
        },
        setCurrentScreenData(state, data) {
            state.currentScreenData = data;
        },

        /****
         * INTERACTIVE SCREENS
         *
         */

        setNoActivityTimer(state, timer) {
            state.no_activity_timer = timer;
        },
        setInteractiveNavigationPath(state, path) {
            state.interactiveNavigationPath = path;
        },
        addInteractiveNavigationPath(state, path) {
            state.interactiveNavigationPath.push(path);
        },
        deleteFromInteractiveNavigationPath(state,index) {
            state.interactiveNavigationPath.splice(index, 1);
        }
    },
    actions: {
        /***********************************
         *              GENERAL            *
         ***********************************/
        openScreen(context, screenData) {
            if(screenData['type']==='settings') {
                //Ao abrir as definições, assume-se que é o técnico
                //faz reset do estado da impressora!
                context.dispatch('resetPrinterErrors');
            }
            context.commit('setCurrentScreenData',screenData);
        },
        /* SHOW ERROR MESSAGE */
        showError(context, errorMessage) {
            if(context.state.errorTimer!==null) {
                clearTimeout(context.state.errorTimer);
            }

            context.commit('setErrorMessage',errorMessage);
            context.commit('setShowError',true);

            //remove error message after 3 seconds
            context.commit('setErrorTimer', setTimeout(function(){
                context.commit('setShowError',false);
            }, 3000))
        },
        /* FAILURE RECOVERY SYSTEM */
        failureRecoveryTick(context) {
            // 1 second tick
            if(context.state.failureRecoveryRetrySeconds===1) {
                // eliminamos intervalo
                if(context.state.failureTimer!=null) {
                    clearInterval(context.state.failureTimer);
                }
                context.commit('setFailureRetrySeconds',0);
                //retry startup
                context.dispatch('start');
            } else {
                context.commit('setFailureRetrySeconds',--context.state.failureRecoveryRetrySeconds);
            }
        },
        failureRecovery(context) {
            if(context.state.failureTimer!=null) {
                clearInterval(context.state.failureTimer);
            }

            context.commit('setFailureRecoveryAttempts',++context.state.failureRecoveryAttempts);
            context.commit('setFailureRetrySeconds', 2^context.state.failureRecoveryAttempts*context.state.failureRecoveryDefaultSeconds);

            context.commit('setFailureTimer', setInterval(function(){
                context.dispatch('failureRecoveryTick');
            }, 1000))

        },
        resetDevice(context) {
            //Broadcast reset
            localStorage.removeItem('accessToken');
            localStorage.removeItem('screenID');
            context.commit('restoreApiKeys',{
                screenID: '',
                token: ''
            });

            //delete selected services of the dispenser
            localStorage.removeItem('selectedServicesIDS');
            context.dispatch('restoreSelectedServicesIDS', []);

            //Qflow reset
            localStorage.removeItem('apiKeys');
            localStorage.removeItem('deviceMode');
            context.commit('setApiKeys',[]);
            context.commit('removeAllServices');

            //Delete previous logger
            context.commit('setLogger', null);
            //Delete printer settings
            context.commit('setPrinter', null);

            //clear timers //TODO
            for (let i = 0; i < context.state.qflowTimers.length; i++) {
                if(context.state.qflowTimers[i]!==null && typeof context.state.qflowTimers[i] !== 'undefined') {
                    clearInterval(context.state.qflowTimers[i]);
                }
                context.commit('setQflowTicketTimer',[i,null]);
                context.commit('setQflowBusyRequests', [i, false]);
            }

            //RESTART SYSTEM
            context.dispatch('start');
        },
        /* CONFIGURATION */
        addService(context,data) {
            let activate = null;
            if(context.state.mode === 'dispenser') {
                activate = api.activateDispenser(data);
            } else {
                activate = api.activateQueueScreen(data);
            }

            activate.then(apiKey => {
                context.commit('addApiKey',apiKey)
                context.commit('removeAllServices');
                context.dispatch('start');
            })
            .catch(error_code => {
                console.error(errors[error_code][context.state.selectedLanguage]);
                context.dispatch('showError', errors[error_code][context.state.selectedLanguage]);
            })
        },
        changeDeviceMode(context, mode) {
            context.commit('setCurrentMode',mode);
        },
        refreshDispenserData(context) {
            console.log('updating queues data')
            let promises = []
            for (let j = 0; j < context.state.selectedServicesIDS.length; j++) {
                let serviceID = context.state.selectedServicesIDS[j];
                promises.push(api.getServiceInfo(serviceID));
            }

            Promise.all(promises).then(results => {
                let servicesData = []
                let oldData = context.state.servicesData
                for (let i = 0; i < results.length; i++) {
                    let servData = oldData[i];
                    servData['queues'] = results[i].data.queues;
                    servicesData.push(servData);
                }
                console.log('new service data: ', servicesData)
                context.commit('setServicesData', servicesData);
            })
        },
        start(context) {
            if(localStorage.getItem('debug')) {
                context.commit('setDebug', true);
            }

            if(context.state.debug) {
                console.log("UC MOTION SCREEN START");
            }

            if(localStorage.getItem('deviceMode')) {
                if(context.state.debug) {
                    console.log("Restoring device mode");
                }

                let deviceMode = JSON.parse(localStorage.getItem('deviceMode'));
                context.commit('restoreMode',deviceMode);

                /*let loggerAppName = 'unknownapp'
                if(deviceMode === 'dispenser') {
                    loggerAppName = config.dispenserAppName
                } else if(deviceMode === 'screen') {
                    loggerAppName = config.queuesAppName
                } else if(deviceMode==='broadcast') {
                    loggerAppName = config.broadcastAppName
                }*/

                /** SET LOGGER
                 */
                //new Logger(false, loggerAppName);

                if(deviceMode==='dispenser' || deviceMode==='screen') {
                    //QFlow System
                    if (localStorage.getItem('apiKeys')) {
                        if(context.state.debug) {
                            console.log("Restoring api Keys");
                        }

                        context.dispatch('restoreApiKeys', JSON.parse(localStorage.getItem('apiKeys')));
                    }

                    if (localStorage.getItem('selectedServicesIDS')) {
                        if(context.state.debug) {
                            console.log("Restoring selected services for dispenser");
                        }
                        context.dispatch('restoreSelectedServicesIDS', JSON.parse(localStorage.getItem('selectedServicesIDS')));
                    }

                    //TODO: startup script!!!
                    if(context.state.debug) {
                        console.log("QFLOW START")
                    }

                    context.commit('setNoInternet', false);
                    context.commit('setCurrentScreen', 'loading_screen');
                    /*
                    GET QUEUES AND INFORMATION ABOUT THE SERVICES ON STARTUP
                     */
                    let apiKeys = context.state.apiKeys;
                    let selectedServicesIDS = context.state.selectedServicesIDS;
                    let mode = context.state.mode;

                    if(context.state.debug) {
                        console.log(mode,apiKeys,selectedServicesIDS);
                    }


                    if ((mode === 'screen' && apiKeys.length > 0) || (mode === 'dispenser' && apiKeys.length > 0 && selectedServicesIDS.length > 0)) {
                        let promises = [];

                        promises.push(api.getServicesList());

                        for (let i = 0; i < apiKeys.length; i++) {
                            let apiKey = apiKeys[i];
                            if (context.state.mode === 'screen') {
                                /**** SCREEN ****/
                                //Get queues
                                promises.push(api.getScreenService(apiKey));

                                //Get Information about this screen
                                promises.push(api.getScreenInfo(apiKey));
                            } else {
                                /**** DISPENSER ****/
                                //Get queues
                                for (let j = 0; j < context.state.selectedServicesIDS.length; j++) {
                                    let serviceID = context.state.selectedServicesIDS[j];
                                    promises.push(api.getServiceInfo(serviceID));
                                }

                                // get printer IP address
                                promises.push(api.getPrinterIp(apiKey));
                            }
                        }

                        //let servicesData = [];

                        Promise.all(promises).then(results => {
                            if (results[0].status != 200) {
                                throw new Error("Erro ao retornar lista de serviços da UC");
                            }

                            if (context.state.mode === 'screen') {
                                /**** SCREEN ****/
                                for (let i = 0; i < apiKeys.length; i++) {
                                    let currentService = {}
                                    let index = (i * 2) + 1;
                                    if (results[index].status != 200 || results[index + 1].status != 200) {
                                        throw new Error("Erro ao retornar dados do serviço");
                                    }
                                    let queuesData = results[index].data.queues;
                                    let serviceID = results[index + 1].data.idService;
                                    currentService['id'] = serviceID;
                                    currentService['apiKey'] = apiKeys[i];
                                    currentService['queues'] = queuesData;

                                    for (let j = 0; j < results[0].data.length; j++) {
                                        if (results[0].data[j].id === serviceID) {
                                            currentService['name'] = results[0].data[j].name;
                                            break;
                                        }
                                    }
                                    if(context.state.debug) {
                                        console.log(currentService);
                                    }

                                    //add service to index
                                    context.commit('addService', currentService);
                                }

                                //FORCE REFRESH DATA NOW!
                                context.dispatch('refreshData');

                                //Activate auto-language changer
                                context.dispatch('autoLanguageChanger');

                                //change screen to queues system
                                context.commit('setCurrentScreen', 'queues_screen');
                            } else {
                                /**** DISPENSER ****/

                                let data = results[0].data;
                                let servicesData = []
                                let serviceIndex = 1;
                                for (let i = 0; i < data.length; i++) {
                                    if (context.state.selectedServicesIDS.includes(data[i].id)) {
                                        let servData = data[i];
                                        servData['queues'] = results[serviceIndex].data.queues;
                                        serviceIndex++;
                                        servicesData.push(servData);
                                    }
                                }

                                let printerIP = results[results.length - 1].data.ipPrinter;

                                if(printerIP !== '0.0.0.0') {
                                    let printer = new Printer(printerIP, config.ticketsPrinter.ePosPrint_Device_ID, true);
                                    printer.start();
                                    context.commit('setPrinter', printer);
                                    //Update printer status to OK
                                    context.dispatch('resetPrinterErrors');
                                }

                                context.commit('setPrinterIP', printerIP);
                                context.commit('setServicesData', servicesData);

                                //context.commit('setCurrentScreen', 'get_tickets_screen');

                                context.dispatch('openInteractiveScreen',['']);

                                //create timer to refresh queues data
                                console.log('Creating timer to update inactive queues')
                                context.commit('setQflowTicketTimer',[0,setInterval(function() {
                                    context.dispatch('refreshDispenserData');
                                }, 60000)])
                            }
                        }).catch(e => {
                            //context.dispatch('showError', "Ocorreu um erro ao ir buscar informação do serviço");
                            context.dispatch('failureRecovery');
                            context.commit('setCurrentScreen', 'error_screen');
                            console.error("Ocorreu um erro ao ir buscar informação do serviço", e)
                        })
                    } else {
                        context.commit('setCurrentScreen','settings');
                        if(apiKeys.length>0) {
                            context.dispatch('showError', "Seleccione os serviços a disponibilizar senha");
                            console.error("Sem serviços seleccionados para o dispensador. Seleccione serviços.");
                        } else {
                            context.dispatch('showError', "Dispositivo sem chaves API, necessário ativar!");
                            console.error("Dispositivo sem chaves API, necessário ativar!")
                        }
                    }
                } else if(deviceMode==='broadcast') {
                    //**** Broadcast Screen System ****//
                    //RESTORE KEYS+TOKEN
                    let screenID = JSON.parse(localStorage.getItem('screenID'));
                    let token = JSON.parse(localStorage.getItem('accessToken'));

                    if(localStorage.getItem('carouselSeconds')) {
                        let carouselSeconds = localStorage.getItem('carouselSeconds')
                        let slideSeconds = parseInt(carouselSeconds);
                        if(slideSeconds > 0) {
                            context.commit('setSliderSeconds', slideSeconds)
                        }
                    }

                    context.commit('restoreApiKeys', {
                        screenID: screenID,
                        token: token
                    })

                    let data = context.state.infoPlaylist[context.state.main_playlist_counter];
                    context.commit('setCurrentScreenData',data);

                    // start refresh data timer
                    //context.dispatch('refreshDataTimer',config.refreshDataMinutes*60);
                    //start timer by fetching new data from 30 in 30 seconds

                    //FORCE REFRESH DATA NOW!
                    context.dispatch('refreshData');
                    //CREATE TIMER TO REFRESH DATA:
                    context.dispatch('resetDataRefreshEngine',30);
                }else {
                    throw new Error("Device mode not recognized");
                }

                if(context.state.debug) {
                    console.log("Restoring footer text");
                }

                if(localStorage.getItem('footer_en')!==null) {
                    context.commit('setFooterText', {
                        language: "en",
                        text: JSON.parse(localStorage.getItem('footer_en'))
                    });
                }

                if(localStorage.getItem('footer_pt') !== null) {
                    context.commit('setFooterText',{
                        language: "pt",
                        text: JSON.parse(localStorage.getItem('footer_pt'))
                    });
                }
            }
        },

        /***********************************
         *          QUEUES SCREENS         *
         ***********************************/
        autoLanguageChanger(context) {
            if(context.state.auto_language_change_timer !== null) {
                clearInterval(context.state.auto_language_change_timer);
            }
            context.commit('setAutoLanguageTimer', setInterval(function(){
                context.commit('autoChangeLanguage')
            }, context.state.autoLanguageChangeSeconds * 1000))
        },
        /* RESET USER INTERFACE TIMER */
        startInferfaceResetTimer(context,seconds) {
            if(context.state.resetInterfaceTimer!==null) {
                clearTimeout(context.state.resetInterfaceTimer);
            }

            context.commit('setUIresetTimer', setTimeout(function(){
                context.dispatch('resetInterface');
            }, 1000 * seconds))
        },

        /***********************************
         *        BROADCAST SCREENS        *
         ***********************************/
        nextSlide(context) {
            if(context.state.debug) {
                console.log("NEXT SLIDE -->")
            }

            //verifica se existe conteudo na nova playlist
            if(context.state.newInfoPlaylist.length>0) {
                context.commit('resetCounters');
                context.commit('finishNewPlaylistTransition');
            }
            // get current slide data
            let data = context.state.infoPlaylist[context.state.main_playlist_counter];
            context.commit('setCurrentScreenData',data);
            //increase counter
            let newIndex = context.state.main_playlist_counter+1;
            if(context.state.debug) {
                console.log('newIndex',newIndex)
            }

            if(newIndex >= context.state.infoPlaylist.length) {
                context.commit('setMainCounter',0);
                if(context.state.debug) {
                    console.log('RESET COUNTER')
                }

            } else {
                context.commit('setMainCounter',newIndex);
            }
        },

        getAllNewTicketData(context) {
            let promises = [];
            for (let i = 0; i < context.state.servicesData.length; i++) {
                let currentServiceData = context.state.servicesData[i];
                promises.push(api.getScreenServiceInfo(currentServiceData.apiKey))
            }

            Promise.all(promises).then(results => {
                for (let i = 0; i < results.length; i++) {
                    let newServiceData = results[i].data
                    let oldServiceData = context.state.servicesData[i];

                    if (newServiceData !== null && typeof newServiceData.queues !== 'undefined') { //Ignora null do backend
                        let newServiceDataQueues = newServiceData.queues;
                        let oldServiceDataQueues = oldServiceData.queues;
                        //for (let q = 0; q < oldServiceData.queues.length; q++) {
                        /*
                        NOTA VERIFICAR SE A ORDEM dos
                         */
                        if(context.state.debug) {
                            console.log("NEW SERVICE DATA AND OLD COMPARE", newServiceData, oldServiceData)
                        }
                        for (let q = 0; q < newServiceDataQueues.length; q++) {
                            //TODO: USE service.index to get info of the queues without for loop

                            /*let oldQueueData = oldServiceData.queues[q];
                            let queueID = oldQueueData.id;
                            if(context.state.debug) {
                                console.log("searching for ID: "+queueID)
                            }

                            // Search information about this queue using its ID
                            let newQueueData = null;
                            for (let qi = 0; qi < newServiceDataQueues.length; qi++) {
                                if (newServiceDataQueues[qi].id === queueID) {
                                    newQueueData = newServiceDataQueues[qi];
                                    if(context.state.debug) {
                                        console.log("New queue data found", newQueueData);
                                    }

                                    break;
                                }
                            }*/
                            let newQueueData = newServiceDataQueues[q]
                            let oldQueueData = oldServiceDataQueues[q]

                            if(context.state.debug) {
                                console.log("AlertDates:", newQueueData.lastAlertDate, oldQueueData.lastAlertDate)
                                console.log("currentTicketNumber:", newQueueData.currentTicketNumber, oldQueueData.currentTicketNumber)
                            }

                            // COMPARE OLD DATA WITH NEW DATA
                            //newQueueData.lastAlertDate !== oldQueueData.lastAlertDate
                            if (newQueueData !== null && newQueueData.lastAlertDate !== oldQueueData.lastAlertDate) { //newQueueData.currentTicketNumber !== oldQueueData.currentTicketNumber) { //newQueueData.lastAlertDate !== oldQueueData.lastAlertDate) {
                                //call ticket
                                /*
                                    data.ticketNumber
                                    data.workstationName
                                    data.serviceName
                                 */

                                if(context.state.debug) {
                                    console.log("ADD TICKET TO BE CALLED")
                                }
                                context.commit('addCallingTicket', {
                                    ticketNumber: newQueueData.currentTicketNumber,
                                    workstationName: newQueueData.workstationName,
                                    serviceName: oldServiceData.name,
                                    queueName: newQueueData.name,
                                });
                            }
                            if(context.state.debug) {
                                console.log("newServiceDataQueues", newServiceDataQueues)
                            }


                            //CALL AGAIN
                            /*context.dispatch('getNewTicketsData',{
                                index:  newServiceData.index,
                                apiKey: newServiceData.apiKey,
                            })*/
                        }
                        //Update queues data
                        context.commit('setServiceQueues', {
                            serviceIndex: i,
                            queues: newServiceDataQueues
                        })
                    } else {
                        console.error("There is no queues property available for service ", i)
                    }
                }
            })
        },
        getNewTicketsData(context, service) {
            let busy = context.state.qflowBusy[service.index] === true ? true : false;
            if(!busy) {
                context.commit('setQflowBusyRequests',[service.index, true]);
                //console.log("SeRVICE: ", service);
                //console.log("Getting data for service " + service.index);
                //Get queues
                api.getScreenServiceInfo(service.apiKey).then((result) => {
                    /*context.dispatch('processNewTicketsData',{
                        index: service.index,
                        apiKey: service.apiKey,
                        results: result
                    });*/

                    //newServiceData.results
                    //newServiceData.index
                    if(context.state.debug) {
                        console.log("GETTING NEW DATA FROM FINISHED", result);
                    }
                    let oldServiceData = context.state.servicesData[service.index];
                    if(context.state.debug) {
                        console.log("oldServiceData (check if it has queues property:) ", oldServiceData)
                    }

                    //comparar informação com os dados que já tinhamos

                    if (result.data !== null && typeof result.data.queues !== 'undefined') { //Ignora null do backend
                        let newServiceDataQueues = result.data.queues;
                        for (let q = 0; q < oldServiceData.queues.length; q++) {
                            //TODO: USE service.index to get info of the queues without for loop
                            let oldQueueData = oldServiceData.queues[q];
                            let queueID = oldQueueData.id;
                            if(context.state.debug) {
                                console.log("searching for ID: " + queueID)
                            }
                            // Search information about this queue using its ID
                            let newQueueData = null;
                            for (let qi = 0; qi < newServiceDataQueues.length; qi++) {
                                if (newServiceDataQueues[qi].id === queueID) {
                                    newQueueData = newServiceDataQueues[qi];
                                    if(context.state.debug) {
                                        console.log("New queue data found", newQueueData);
                                    }
                                    break;
                                }
                            }

                            if(context.state.debug) {
                                console.log("AlertDates:", newQueueData.lastAlertDate, oldQueueData.lastAlertDate)
                                console.log("currentTicketNumber:", newQueueData.currentTicketNumber, oldQueueData.currentTicketNumber)
                            }
                            // COMPARE OLD DATA WITH NEW DATA
                            if (newQueueData !== null && newQueueData.currentTicketNumber !== oldQueueData.currentTicketNumber) { //newQueueData.lastAlertDate !== oldQueueData.lastAlertDate) {
                                //call ticket
                                /*
                                    data.ticketNumber
                                    data.workstationName
                                    data.serviceName
                                 */
                                if(context.state.debug) {
                                    console.log("ADD TICKET TO BE CALLED")
                                }
                                context.commit('addCallingTicket', {
                                    ticketNumber: newQueueData.currentTicketNumber,
                                    workstationName: newQueueData.workstationName,
                                    serviceName: oldServiceData.name,
                                    queueName: newQueueData.name,
                                });
                            }

                            if(context.state.debug) {
                                console.log("newServiceDataQueues", newServiceDataQueues)
                            }
                            //Update queues data
                            context.commit('setServiceQueues', {
                                serviceIndex: service.index,
                                queues: newServiceDataQueues
                            })

                            //CALL AGAIN
                            /*context.dispatch('getNewTicketsData',{
                                index:  newServiceData.index,
                                apiKey: newServiceData.apiKey,
                            })*/
                        }
                    } else {
                        console.error("There is no queues property available for service ", service.index)
                    }

                    //context.commit('setLoading', false);
                    //CALL AGAIN??? -> not in assinc system
                    //context.dispatch('refreshData');

                    /*             }).catch(e => {
                                     context.commit('setLoading', false);
                                     //context.dispatch('showError', "Ocorreu um erro ao atualizar informação");
                                     context.commit('setNoInternet', true);
                                     console.error("Ocorreu um erro ao atualizar informação", e)
                                 })*/

                    /* OLD IMPLEMENTATION
                     let promises = [];

                    for (let i = 0; i < servicesData.length; i++) {
                        let currentServiceData = servicesData[i];
                        //Get queues
                        promises.push(api.getScreenServiceInfo(currentServiceData.apiKey));
                    }

                    Promise.all(promises).then(results => {
                        console.log("GETTING NEW DATA FROM FINISHED");
                        //comparar informação com os dados que já tinhamos
                        for (let s = 0; s < results.length; s++) {
                            console.log("STATUS: ", results[s].status);
                            let oldServiceData = context.state.servicesData[s];
                            console.log("oldServiceData (check if it has queues property:)",oldServiceData)
                            if (results[s].data !== null && typeof results[s].data.queues !== 'undefined') { //Ignora null do backend
                                let newServiceDataQueues = results[s].data.queues;

                                for (let q = 0; q < oldServiceData.queues.length; q++) {
                                    let oldQueueData = oldServiceData.queues[q];
                                    let queueID = oldQueueData.id;
                                    // Search information about this queue using its ID
                                    let newQueueData = null;
                                    for (let qi = 0; qi < newServiceDataQueues.length; qi++) {
                                        if (newServiceDataQueues[qi].id === queueID) {
                                            console.log("New queue data found");
                                            newQueueData = newServiceDataQueues[qi];
                                            break;
                                        }
                                    }

                                    // COMPARE OLD DATA WITH NEW DATA
                                    if (newQueueData !== null && newQueueData.lastAlertDate !== oldQueueData.lastAlertDate) {
                                        //call ticket
                                        /*
                                            data.ticketNumber
                                            data.workstationName
                                            data.serviceName
                                         */
                    /*
                                                                    console.log("ADD TICKET TO BE CALLED")
                                                                    context.commit('addCallingTicket', {
                                                                        ticketNumber: newQueueData.currentTicketNumber,
                                                                        workstationName: newQueueData.workstationName,
                                                                        serviceName: oldServiceData.name,
                                                                        queueName: newQueueData.name
                                                                    });
                                                                }
                                                            }
                                                            console.log("newServiceDataQueues", newServiceDataQueues)
                                                            //Update queues data
                                                            context.commit('setServiceQueues', {
                                                                serviceIndex: s,
                                                                queues: newServiceDataQueues
                                                            })
                                                        }
                                                    }

                                                    context.commit('setLoading', false);
                                                    //CALL AGAIN???
                                                    context.dispatch('refreshData');*/
                    context.commit('setQflowBusyRequests',[service.index, false]);
                }).catch(e => {
                    //context.commit('setLoading', false);
                    context.commit('setQflowBusyRequests',[service.index, false]);
                    //context.dispatch('showError', "Ocorreu um erro ao atualizar informação");
                    context.commit('setNoInternet', true);
                    console.error("Ocorreu um erro ao atualizar informação ", e)
                })
            } else {
                if(context.state.debug) {
                    console.log("Service " + service.index + " is already busy. ignoring...")
                }
            }
        },
        refreshData(context) {
            if(context.state.debug) {
                console.log("REFRESH DATA - ONLY TO BE CALLED ONCE")
            }
                if(!context.state.loading_data) {
                    if (context.state.mode === 'broadcast') {
                        let lstore = localStorage
                        if (context.state.token !== '' && context.state.token !== null && context.state.screenID !== '' && context.state.screenID !== null) {
                            context.commit('setNoInternet', false);
                            context.commit('setLoading', true);
                            api.getBroadcastPlaylist(context.state.screenID, context.state.token).then(function (response) {
                                    //TODO: create agenda_today slide from events (30 minutes to the event)
                                    if(context.state.debug) {
                                        console.log(JSON.stringify(response.data[0].infoPlaylist));
                                    }

                                    if(lstore.getItem('carouselSeconds')) {
                                        let seconds = parseInt(lstore.getItem('carouselSeconds'))
                                        context.commit('setSliderSeconds', seconds);
                                    } else {
                                        if (response.data[0].sliderSeconds > 0) {
                                            context.commit('setSliderSeconds', response.data[0].sliderSeconds);
                                        }
                                    }


                                    if (Array.isArray(response.data[0].infoPlaylist)) {
                                        let newData = response.data[0].infoPlaylist;
                                        let cleanData = [];
                                        for (let i = 0; i < newData.length; i++) {
                                            let data = newData[i];
                                            let type = data['type'];
                                            let url = data['url'];
                                            let footerText = data['footerText'];
                                            let slideData = null;
                                            if ((type === 'highlights' || type === 'events') && data['events'] !== null) {
                                                if (type === 'highlights') {
                                                    // divide each event in a separate slide
                                                    for (let e = 0; e < data['highlights'].length; e++) {

                                                        slideData = {
                                                            type: 'agenda_today',
                                                            id: Math.round(Math.random() * 9999999)
                                                        }
                                                        let eventData = data['highlights'][e];
                                                        if (eventData['title'] !== null && eventData['summary'] !== null && eventData['location'] !== null && eventData['name'] !== null) {
                                                            slideData['title'] = eventData['title'];
                                                            slideData['summary'] = eventData['summary'];
                                                            slideData['image'] = eventData['thumb'];
                                                            slideData['video'] = eventData['video'];
                                                            slideData['date'] = eventData['date'];
                                                            slideData['time'] = eventData['time'];
                                                            slideData['location_type'] = eventData['location_type'];

                                                            //PARSE LOCATION
                                                            let location = ''
                                                            let locationType = eventData['location_type'].toLowerCase();
                                                            let rawLocation = eventData['location'];
                                                            let eventUrl = null;
                                                            if (locationType.includes('virtual')) {
                                                                if(context.state.debug) {
                                                                    console.log('parsing url');
                                                                }
                                                                let locationParts = [];

                                                                if (rawLocation.includes('<br>')) {
                                                                    locationParts = eventData['location'].split('<br>');
                                                                } else if (rawLocation.includes('<br/>')) {
                                                                    locationParts = eventData['location'].split('<br/>');
                                                                } else if (rawLocation.includes('<br />')) {
                                                                    locationParts = eventData['location'].split('<br />');
                                                                } else {
                                                                    locationParts = [rawLocation]
                                                                }
                                                                eventUrl = locationParts[0];
                                                                if (locationParts.length > 1) {
                                                                    location = locationParts[1];
                                                                }
                                                            } else {
                                                                location = rawLocation;
                                                            }
                                                            slideData['place'] = location;
                                                            //console.log("EVENT URL: "+eventUrl);
                                                            if(eventUrl===null) {
                                                                slideData['QRcodeUrl'] =  eventData['url']
                                                            } else {
                                                                slideData['QRcodeUrl'] = eventUrl
                                                            }


                                                            slideData['liveStream'] = false;
                                                            cleanData.push(slideData);
                                                        } else {
                                                            if(context.state.debug) {
                                                                console.log("No hightligths selected");
                                                            }
                                                        }
                                                    }
                                                } else {
                                                    // create a slide with all events
                                                    slideData = {
                                                        type: 'agenda',
                                                        events: [],
                                                        footerText: footerText,
                                                        QRcodeUrl: url,
                                                        id: Math.round(Math.random() * 9999999),
                                                        video: null,
                                                        image: null
                                                    }
                                                    let agendaHeroImage = null;
                                                    let agendaHeroVideo = null;
                                                    let videoIndex = -1;
                                                    let imageIndex = -1;

                                                    //console.log("agenda data: ", data['events']);
                                                    //Add events to agenda
                                                    for (let e = 0; e < data['events'].length; e++) {
                                                        let eventData = {
                                                            title: data['events'][e]['title'],
                                                            place: data['events'][e]['location'],
                                                            date: data['events'][e]['date'],
                                                            liveStream: false,
                                                            time: data['events'][e]['time'],
                                                            image: data['events'][e]['thumb'],
                                                            video: data['events'][e]['video'],
                                                            QRcodeUrl: url,//TODO: verify if its virtual and get the url for the virtual event
                                                        }
                                                        if (agendaHeroImage === null && (data['events'][e]['thumb'] !== null && data['events'][e]['thumb'] !== '' && typeof data['events'][e]['thumb'] !== 'undefined')) {
                                                            agendaHeroImage = data['events'][e]['thumb'];
                                                            imageIndex = e;
                                                        }

                                                        if (agendaHeroVideo === null && (data['events'][e]['video'] !== null && data['events'][e]['video'] !== '' && typeof data['events'][e]['video'] !== 'undefined')) {
                                                            agendaHeroVideo = data['events'][e]['video'];
                                                            videoIndex = e;
                                                        }

                                                        //PARSE LOCATION
                                                        let location = '';
                                                        let locationType = data['events'][e]['location_type'].toLowerCase();
                                                        let rawLocation = data['events'][e]['location'];
                                                        let eventUrl = null;
                                                        if (locationType.includes('virtual')) {
                                                            if(context.state.debug) {
                                                                console.log('parsing url');
                                                            }
                                                            let locationParts = [];

                                                            if (rawLocation.includes('<br>')) {
                                                                locationParts = rawLocation.split('<br>');
                                                            } else if (rawLocation.includes('<br/>')) {
                                                                locationParts = rawLocation.split('<br/>');
                                                            } else if (rawLocation.includes('<br />')) {
                                                                locationParts = rawLocation.split('<br />');
                                                            } else {
                                                                locationParts = [rawLocation]
                                                            }
                                                            eventUrl = locationParts[0];
                                                            if (locationParts.length > 1) {
                                                                location = locationParts[1];
                                                            }
                                                        } else {
                                                            location = rawLocation;
                                                        }
                                                        eventData['place'] = location;
                                                        if(eventUrl===null) {
                                                            eventData['QRcodeUrl'] = data['events'][e]['url'];
                                                        }else{
                                                            if(context.state.debug) {
                                                                console.log("EVENT URL: " + eventUrl);
                                                            }

                                                            eventData['QRcodeUrl'] = eventUrl;
                                                        }

                                                        if(context.state.debug) {
                                                            console.log("agenda EVENT LOCATION TYPE: " + data['events'][e]['location_type']);
                                                            console.log("agenda Place: " + data['events'][e]['location']);
                                                        }
                                                        //Max 3 events in agenda
                                                        if (slideData['events'].length < 3) {
                                                            slideData['events'].push(eventData);
                                                        } else {
                                                            if(context.state.debug) {
                                                                console.log("MAX EVENTS: " + slideData['events'].length)
                                                            }
                                                        }
                                                    }
                                                    if (slideData.events.length > 0) {
                                                        //change image/video hero
                                                        if (videoIndex < imageIndex && videoIndex >= 0 || imageIndex === -1) {
                                                            //mostra video quando não há imagem ou quando temos um evento com video antes de
                                                            //qualquer imagem
                                                            slideData['video'] = agendaHeroVideo;
                                                        } else {
                                                            slideData['image'] = agendaHeroImage;
                                                            slideData['video'] = null;
                                                        }
                                                        //only add this slide if we have events to show
                                                        cleanData.push(slideData);
                                                    }

                                                }
                                            } else if (type === 'articles' && data['articles'] !== null) {
                                                for (let a = 0; a < data['articles'].length; a++) {
                                                    slideData = {
                                                        type: 'announcement',
                                                        backgroundImage: data['articles'][a]['thumb'],
                                                        backgroundVideo: data['articles'][a]['video'],
                                                        title: data['articles'][a]['title'],
                                                        tag: 'Em Destaque', //TODO missing
                                                        detailText: data['articles'][a]['summary'],
                                                        QRcodeUrl: data['articles'][a]['url'], //url,
                                                        footerText: footerText,
                                                        id: Math.round(Math.random() * 9999999)
                                                    }
                                                    cleanData.push(slideData);
                                                }

                                            } else if (type === 'announcements' && data['announcements'] !== null) {
                                                for (let a = 0; a < data['announcements'].length; a++) {
                                                    slideData = {
                                                        type: 'marketing',
                                                        image: data['announcements'][a]['image'],
                                                        video: data['announcements'][a]['video'],
                                                        title: data['announcements'][a]['title'],
                                                        subtitle: data['announcements'][a]['summary'],
                                                        QRcodeUrl: data['announcements'][a]['url'],
                                                        QRcodeDescription: "Saiba mais", // "Veja o vídeo completo em uc.pt/unica ou utilize o QR-Code", //TODO: actionText missing
                                                        id: Math.round(Math.random() * 9999999)
                                                    }
                                                    cleanData.push(slideData);
                                                }
                                            } else {
                                                console.error("Slide type (" + type + ") not recognized")
                                            }
                                        }

                                        context.commit('setInfoPlaylistScreenData', cleanData);

                                        //We have a playlist
                                        if (cleanData.length > 0) {
                                            //make sure the refresh rate is normal
                                            context.dispatch('resetDataRefreshEngine', config.refreshDataMinutes * 60);
                                            if (typeof context.state.currentPageData === 'undefined' || context.state.currentPageData === null) {
                                                context.dispatch('nextSlide');
                                            }
                                        }

                                        //context.dispatch('nextSlide');

                                    } else {
                                        console.error("Response from server is not an Array")
                                        context.commit('setNoInternet', true);
                                        //if there is an error retry in 30 seconds
                                        //context.dispatch('refreshDataTimer',30);
                                        context.dispatch('resetDataRefreshEngine', 30);
                                        if(context.state.debug) {
                                            console.log("Quicker refresh time")
                                        }
                                    }
                                    context.commit('setLoading', false);
                                })
                                .catch(function (error) {
                                    console.error(error);
                                    context.commit('setLoading', false);
                                    context.commit('setNoInternet', true);
                                    //if there is an error retry in 30 seconds
                                    //context.dispatch('refreshDataTimer',30);
                                    context.dispatch('resetDataRefreshEngine', 30);
                                    if(context.state.debug) {
                                        console.log("Quicker refresh time");
                                    }
                                });
                        } else {
                            console.error("Invalid access token + screen ID");
                            context.commit('setNoInternet', true);
                        }
                    } else if (context.state.mode === 'screen') {

                        //NOTE: THIS SHOULD ONLY BE CALLED AT START FOR QUEUES SCREENS

                        if (context.state.no_internet_connection) {
                            context.commit('setNoInternet', false);
                        }

                        let servicesData = context.state.servicesData;
                        if (servicesData.length > 0) {
                            //console.log("GETTING NEW DATA FROM BACKEND");

                            //context.commit('setLoading', true);

                            //let promises = [];

                           /*** REDONE IT

                            for (let i = 0; i < servicesData.length; i++) {
                                let currentServiceData = servicesData[i];
                                if(typeof context.state.qflowTimers[i] !== undefined && context.state.qflowTimers[i] !== null) {
                                    clearInterval(context.state.qflowTimers[i]);
                                }
                                context.commit('setQflowTicketTimer',[i,setInterval(function() {
                                    context.dispatch('getNewTicketsData',{
                                        apiKey: currentServiceData.apiKey,
                                        index: i
                                    });
                                    }, 20000)])
                                //promises.push();
                            }

                            **/
                           //redone it result:
                           context.commit('setQflowTicketTimer',[0,setInterval(function() {
                               context.dispatch('getAllNewTicketData');
                           }, 5000)])


                           /* Promise.all(promises).then(results => {

                            }).catch(e => {
                                context.commit('setLoading', false);
                                //context.dispatch('showError', "Ocorreu um erro ao atualizar informação");
                                context.commit('setNoInternet', true);
                                console.error("Ocorreu um erro ao atualizar informação", e)
                            })
    */
                    }
                }
            }
        },
        resetDataRefreshEngine(context, refreshSeconds) {
            //recursive timer
            if(context.state.refreshDataTimer !== null) {
                clearTimeout(context.state.refreshDataTimer);
            }

            if(refreshSeconds>0) {
                context.commit('setRefreshDataRate',refreshSeconds);
            }

            context.commit('setRefreshDataTimer', setTimeout(function(){
                if(context.state.debug) {
                    console.log("REFRESHING DATA at a rate " + context.state.currentDataRefreshRate + ' secs');
                }
                context.dispatch('refreshData');
                //-1 to keep current refresh rate
                context.dispatch('resetDataRefreshEngine',-1);
            }, context.state.currentDataRefreshRate * 1000))
        },

        /***********************************
         * DISPENSER - INTERACTIVE SCREENS *
         ***********************************/
        rearmNoActivityTimer(context) {
            if (context.state.no_activity_timer !== null) {
                clearTimeout(context.state.no_activity_timer);
            }

            context.commit('setNoActivityTimer',setTimeout(function () {
                context.dispatch('resetInterface',''); //go to welcome screen
                context.commit('setCurrentLanguage','pt');
            }, config.noActivitySeconds * 1000))
        },
        resetInterface(context, page='select_service') { //reset interactive interface after taking ticket
            if(context.state.debug) {
                console.log("RESETING INTERFACE");
            }

            //this.interactiveNavigationPath = [];
            context.commit('setInteractiveNavigationPath',[]);

            // remove new ticket info
            context.commit('resetUI');

            //TODO: Verify if it can be integrated into the current Screen Data Missing???
            //this.service = null;
            //this.serviceData = null;

            //this.selectLanguage('pt'); //reset language to portuguese
            context.dispatch('openInteractiveScreen',[page]); //GO TO SELECTION OF SERVICES
        },
        backInteractiveScreen(context) {
            if(context.state.interactiveNavigationPath.length > 1) {
                if(context.state.debug) {
                    console.log(context.state.interactiveNavigationPath);
                }
                let pageRef = context.state.interactiveNavigationPath[context.state.interactiveNavigationPath.length-2];
                if(context.state.debug) {
                    console.log("pageREF:", pageRef);
                }
                let lastPageSplit = pageRef.split(":")

                //console.log('lastPageSplit',lastPageSplit);
                if(lastPageSplit[0]==='SERVICE' || lastPageSplit[0]==='GET_TICKET') {
                    //TODO: Verify if it can be integrated into the current Screen Data Missin
                    //this.service = null;
                    //this.serviceID = null;

                }

                //remove last from path
                if(context.state.interactiveNavigationPath.length > 0) {
                    context.commit('deleteFromInteractiveNavigationPath', context.state.interactiveNavigationPath.length - 1)
                }

                if(context.state.interactiveNavigationPath.length > 0) {
                    context.dispatch('openInteractiveScreen',[pageRef, true]);
                } else {
                    context.dispatch('openInteractiveScreen',['', true]);
                }
            } else {
                context.dispatch('openInteractiveScreen',['', true]);
            }
        },
        openInteractiveScreen(context, [id,goingBack=false]) {

            //restart no activity timer
            context.dispatch('rearmNoActivityTimer');

            let idsplit = id.split(':');
            if(context.state.debug) {
                console.log("OPENING:", id, idsplit);
            }

            if(idsplit[0] === 'SERVICE' || idsplit[0] === 'GET_TICKET' || idsplit[0] === 'insert_phone_number') {
                let serviceID = Number.parseInt(idsplit[1]);
                let queueID = 0;

                if(idsplit.length>1) {
                    queueID = Number.parseInt(idsplit[2]);
                }

                let screenData = {
                    id: serviceID,
                    type: idsplit[0]
                };

                if(queueID>0) {
                    screenData['queueID'] = queueID;
                }

                if(idsplit[0] === 'GET_TICKET') {
                    if(context.getters.isPrinterAvailable) {
                        screenData['type'] = 'select_ticket_method';
                    } else {
                        screenData['type'] = 'insert_phone_number';
                    }
                }
                // é um serviço do ecrã

                let servicesData = context.state.servicesData; //this.servicesData;

                for (let i = 0; i < servicesData.length; i++) {
                    if(servicesData[i].id === serviceID) {
                        if(context.state.debug) {
                            console.log("SERVICE DATA", servicesData[i])
                        }
                        screenData['serviceName'] = servicesData[i]['name']
                        screenData['serviceDescription'] = servicesData[i]['description'];

                        if(queueID>0) {
                            let queues = servicesData[i].queues;
                            for (let j = 0; j < queues.length; j++) {
                                if(queues[j].id === queueID) {
                                    if(context.state.debug) {
                                        console.log("QUEUES DATA", queues[j])
                                    }
                                    screenData['queueLetter'] = queues[j]['name']
                                    screenData['queueName'] = queues[j]['description'];
                                    break;
                                }
                            }
                        }
                        break;
                    }
                }

                context.commit('setCurrentScreenData', screenData);

                /*

                TODO!!!!!  NEEDS ATTENTION!!!!


                */

                if(goingBack == false) {
                    //this.interactiveNavigationPath.push(idsplit[0]);
                    context.commit('addInteractiveNavigationPath',id)
                }
            } else {
                // é uma página do menu
                //select_queue || select_service

                let interactiveScreenData = {};
                let searchPage = id != "" ? id : "home"
                if(searchPage === 'home') { //reset to normal dispenser
                    //this.interactiveNavigationPath = [];
                    context.commit('setInteractiveNavigationPath',[]);
                }

                if(idsplit.length>0 || context.state.interactiveScreens.length === 0) {
                    let max = 9999;
                    let min = 1;
                    let options = [];
                    let title = {}
                    let actionText = {}
                    let serviceTitle={}
                    let footerText = {}
                    let image = null;
                    let video = null;
                    let qrCodeUrl="";
                    let type = idsplit[0];
                    let ignoreQueuesIds = [];
                    if(localStorage.getItem('ignoreQueuesIds')) {
                        ignoreQueuesIds = JSON.parse(localStorage.getItem('ignoreQueuesIds'));
                        if(!Array.isArray(ignoreQueuesIds)) {
                            ignoreQueuesIds = [];
                        }
                    }
                    // SPECIAL MENUS
                    if(idsplit[0] === 'select_queue') {
                        let serviceID = Number.parseInt(idsplit[1]);
                        for (let i = 0; i < context.state.servicesData.length; i++) {

                            if(context.state.servicesData[i].id === serviceID) {
                                if(context.state.debug) {
                                    console.log(context.state.servicesData[i].id, "==", serviceID);
                                    console.log(context.state.servicesData[i].name);
                                    console.log(context.state.servicesData[i].queues)
                                }
                                for (let j = 0; j < context.state.servicesData[i].queues.length; j++) {
                                    let queue = context.state.servicesData[i].queues[j];
                                    if(!ignoreQueuesIds.includes(queue.id.toString())) {
                                        if(queue.active === false) {
                                            //TODO ?
                                            options.push({
                                                'pt': queue['description'],
                                                'en': queue['description'],
                                                'goTo': 'INACTIVE',
                                            })
                                        } else {
                                            options.push({
                                                'pt': queue['description'],
                                                'en': queue['description'],
                                                'goTo':'GET_TICKET:'+serviceID+':'+queue['id']
                                            })
                                        }
                                    }
                                }

                                serviceTitle['pt'] = context.state.servicesData[i]['name'];
                                serviceTitle['en'] = context.state.servicesData[i]['name'];

                                break;
                            }
                        }
                        //TODO: select_queue
                        title['pt'] = translation['SERVICES_SCREEN']['GET_A_TICKET']['pt'];
                        title['en'] = translation['SERVICES_SCREEN']['GET_A_TICKET']['en'];
                        actionText['pt'] = translation['SERVICES_SCREEN']['CHOOSE_QUEUE']['pt']
                        actionText['en'] = translation['SERVICES_SCREEN']['CHOOSE_QUEUE']['en']
                    }
                    else if(idsplit[0] === 'select_service') {
                        type = 'select_service';
                        //select_service
                        title['pt'] = translation['SERVICES_SCREEN']['GET_A_TICKET']['pt'];
                        title['en'] = translation['SERVICES_SCREEN']['GET_A_TICKET']['en'];
                        actionText['pt'] = translation['SERVICES_SCREEN']['CHOOSE_SERVICE']['pt'];
                        actionText['en'] = translation['SERVICES_SCREEN']['CHOOSE_SERVICE']['en'];

                        for (let i = 0; i < context.state.servicesData.length; i++) {

                            options.push({
                                'pt': context.state.servicesData[i]['name'],
                                'en': context.state.servicesData[i]['name'],
                                'goTo':'select_queue:'+context.state.servicesData[i]['id']
                            })

                        }
                    }
                    else {
                        // home screen - welcome
                        options.push({
                            'pt': 'Tirar senha',
                            'en': 'Get ticket',
                            'goTo':'select_service:'
                        })

                        type = 'menu'
                        title['pt'] = config.welcomePageDispenser.title_pt; //'Bem-vindo ao UC Student Hub';
                        title['en'] = config.welcomePageDispenser.title_en; //'Welcome to the UC Student Hub';
                        actionText['pt'] = '';
                        actionText['en'] = '';
                        footerText['pt'] = config.welcomePageDispenser.footer_pt; //'Para mais, vai a ucstudenthub.uc.pt';
                        footerText['en'] = config.welcomePageDispenser.footer_en; //'For more, go to ucstudenthub.uc.pt';
                        qrCodeUrl = config.welcomePageDispenser.qrcode_url //'https://ucstudenthub.uc.pt';
                        if(config.welcomePageDispenser.image!=='' && config.welcomePageDispenser.image!==null) {
                            image = config.welcomePageDispenser.image;
                        }

                        if(config.welcomePageDispenser.video!=='' && config.welcomePageDispenser.video!==null) {
                            video = config.welcomePageDispenser.video;
                        }
                    }

                    interactiveScreenData = {
                        id: 'generated_'+(Math.random() * (max - min) + min),
                        type: type,
                        title: title,
                        actionText: actionText,
                        options: options,
                        serviceTitle: serviceTitle,
                    }

                    if(video!==null) {
                        interactiveScreenData['backgroundVideo'] = video;
                    } else if(image !== null) {
                        interactiveScreenData['backgroundImage'] = image;
                    }

                    if(typeof footerText['pt'] !== 'undefined' && typeof footerText['en'] !== 'undefined') {
                        interactiveScreenData['footerText'] = footerText;
                    }
                    if(qrCodeUrl!=='') {
                        interactiveScreenData['QRcodeUrl'] = qrCodeUrl;
                    }
                    // END SPECIAL MENUS

                } else {
                    for (let i = 0; i < context.state.interactiveScreens.length; i++) {
                        if (context.state.interactiveScreens[i].id === searchPage) {
                            interactiveScreenData = context.state.interactiveScreens[i];
                            break;
                        }
                    }
                }
                //if (searchPage == 'home') {
                //reset path de navegação quando regressa a home
                //  this.interactiveNavigationPath = [];
                //} else if (interactiveScreenData != null) {
                if(goingBack === false && searchPage!=='home') {
                    //this.interactiveNavigationPath.push(searchPage);
                    context.commit('addInteractiveNavigationPath',searchPage);
                }
                //}

                //this.currentInteractiveScreenData = interactiveScreenData;
                context.dispatch('openScreen',interactiveScreenData);

            }
        },
        restoreSelectedServicesIDS(context, data) {
            //restore data from localstorage
            context.commit('setSelectedServicesIDS', data);
        },
        getTicket(context,data) {
            if(context.state.no_internet_connection) {
                context.commit('setNoInternet',false);
            }

            // data.serviceID -> NOT NEEDED
            // data.queueID
            // data.phoneNumber (optional)
            // data.print (boolean, optional)

            // FOR UI:
            // data.queueName
            // data.queueDescription

            context.commit('setNewTicketData', 'loading');

            let selectedLanguage = context.state.selectedLanguage

            let phoneNumber = null;
            if(data.phoneNumber && data.phoneNumber !== null && typeof data.phoneNumber !== 'undefined') {
                phoneNumber = "+351" + data.phoneNumber
            }

            let getTicket = api.getTicket(context.state.apiKeys[0], data.queueID, phoneNumber, selectedLanguage);
            getTicket
                .then(respData => {
                    respData['queueName'] = data.queueName;
                    respData['queueDescription'] = data.queueDescription;
                    respData['serviceName'] = data.serviceName;

                    respData['isNew'] = true; //to change the splash screen layout
                    context.commit('setNewTicketData',respData);
                    if(context.state.debug) {
                        console.log(respData, data);
                    }

                    if(data.print === true) {
                        context.dispatch('printTicket',respData);
                    }
                    context.dispatch('startInferfaceResetTimer', config.showNewTicketSeconds);
                }).catch(e=>{
                    let errorMessage = translation['SERVICES_SCREEN']['GET_TICKET_ERROR'][context.state.selectedLanguage];
                    context.dispatch('showError', errorMessage); //"Ocorreu um erro ao emitir a senha. Tenta de novo mais tarde.");
                    console.error("Ocorreu um erro ao emitir a senha",e);
                    context.commit('setNewTicketData', null);
                    context.commit('setNoInternet',true);
                })
        },
        testsPrinter(context, printObject = {}) {
            let printer = new Printer("192.168.0.110",'local_printer',true);
            printer.start()
                .then(()=> {
                    printObject['footerNote'] = config.ticketsPrinter.footNote[context.state.selectedLanguage];
                    printObject["title"] = config.ticketsPrinter.title;

                    //TODO: Print ticket
                    printObject['queueDescription'] = "Licenciaturas"
                    printObject['queueName'] = "A"
                    printObject['serviceName'] = "Servicos Academicos"
                    printObject['ticketNumber'] = 1;
                    return printer.print(printObject,"student_hub","dfsdf")
                })
                .then((suc) => {
                    if(context.state.debug) {
                        console.log("IMPRESSO!", suc)
                    }
                })
                .catch((e)=>{
                    console.error("ERRO IMPRIMIR",e)
                })
        },
        printTicket(context, printObject = {}) {
            if(context.state.printer!==null) {
                //let printer = context.state.printer;
                //let printObject= {}
                printObject['footerNote'] = config.ticketsPrinter.footNote[context.state.selectedLanguage];
                printObject["title"] = config.ticketsPrinter.title;

                //TODO: Print ticket
                //printObject['queueDescription'] = "Licenciaturas"
                //printObject['queueName'] = "A"
                //printObject['serviceName'] = "Servicos Academicos"
                //printObject['ticketNumber'] = 1;
                let jobID = Math.round(Math.random() * 9999999).toString();
                context.state.printer.print(printObject, config.ticketsPrinter.layoutStyle, jobID).then(suc=>{
                    if(context.state.debug) {
                        console.log("Printer job " + jobID + " succeded!", suc);
                    }
                }).catch(e => {
                    console.error(e);
                    let error_code = e.code;
                    let errorMessage = errors['PRINTER_ERROR'][context.state.selectedLanguage];
                    if(typeof errors[error_code] !== "undefined") {
                        errorMessage = errors[error_code][context.state.selectedLanguage];
                    }
                    context.dispatch('showError',errorMessage);
                    context.dispatch('updatePrinterStatus', error_code);
                })
            }

            /*
            printer.addEventListener('available', (e) => {
                console.log('Printer fired: "available".', e);
            });

            printer.addEventListener('unavailable', (e) => {
                console.log('Printer fired: "unavailable".', e);
            });

            //TODO: notificar servidores se o estado da impressora mudar


            console.log("STARTING PRINTER")
            printer.start().then(r => {
                console.log("PRINTING", r);
                //
                console.log("FINISHED PRINTING");
            }).catch(e=>{
                console.error("Printer error", e);
            })*/
            //throw new Error("Not implemented");
        },
        updatePrinterStatus(context, printer_error_code) {
            api.setPrinterStatus(context.state.apiKeys[0],printer_error_code);
        },
        resetPrinterErrors(context) {
            if(context.state.printer!==null) {
                context.dispatch('updatePrinterStatus',"OK");
            }
        },
        /* CONFIGURATION */
        saveServices(context, servicesIDs) {
            context.commit('saveServices',servicesIDs);
            //context.commit('setCurrentScreen', 'get_tickets_screen');
            context.dispatch('openInteractiveScreen',['']);
        },
        restoreApiKeys(context, data) {
            //restore data from localstorage
            context.commit('setApiKeys', data);
        },
    },
    getters: {
        isPrinterAvailable: state => {
            return state.printerIP !== "" && state.printerIP !== null && state.printerIP !== "0.0.0.0";
        }
    }
})

export default index;