<template>
  <div class="service-content h-screen w-screen absolute top-0 left-0 right-0 bottom-0 z-10 flex flex-col h-screen w-screen text-center text-white bg-ucframework">
    <div class="title">
      {{translation['GET_A_TICKET'][language]}}
    </div>
    <div class="main-title">
      {{currentScreenData['name']}}
    </div>
    <div class="actionText">
      {{translation['YOUR_PHONE_NUMBER'][language]}} (PT)
    </div>
    <div class="phoneInput">
      {{phoneNumber}}
    </div>
    <div class="keyboard grid grid-flow-col grid-cols-3 grid-rows-4">
      <div class="key select-none" @click="keyPress('1')">1</div>
      <div class="key select-none" @click="keyPress('4')">4</div>
      <div class="key select-none" @click="keyPress('7')">7</div>

      <div class="key empty select-none"></div>
      <div class="key select-none" @click="keyPress('2')">2</div>
      <div class="key select-none" @click="keyPress('5')">5</div>
      <div class="key select-none" @click="keyPress('8')">8</div>
      <div class="key select-none" @click="keyPress('0')">0</div>

      <div class="key select-none" @click="keyPress('3')">3</div>
      <div class="key select-none" @click="keyPress('6')">6</div>
      <div class="key select-none" @click="keyPress('9')">9</div>
      <div class="key select-none" @click="keyPress('DEL')" v-if="phoneNumber.length>0"><img src="@/assets/backspaceicon.svg"></div>
    </div>

    <div>
      <div :class="{'phoneIncomplete': !isPhoneValid}" @click="getTicket()" class="option inline-block withborder">
        {{translation['ACTION_GET_TICKET'][language]}}
      </div>
    </div>

  </div>
</template>

<script>
import translation from "@/config/translation";

export default {
  name: "PhoneNumberInsertScreen",
  data: function () {
    return {
      phoneNumber: "",
      phoneIncomplete: true
    }
  },
  computed: {
    currentScreenData() {
      return this.$store.state.currentScreenData;
    },
    //TRADUÇÃO DA USER INTERFACE
    translation() {
      return translation['SERVICES_SCREEN']; //both for get ticket and give feedback
    },
    language() {
      return this.$store.state.selectedLanguage;
    },
    isPhoneValid() {
      return this.phoneNumber.startsWith('9') && this.phoneNumber.length === 9;
    }
  },
  methods: {
    getTicket() {
      if(this.isPhoneValid) {
        this.$store.dispatch('getTicket', {
          queueID: this.currentScreenData.queueID,
          phoneNumber: this.phoneNumber,
          queueName: this.currentScreenData.queueLetter,
          queueDescription: this.currentScreenData.queueName,
          print: false
        });
      }
    },
    keyPress(key) {
      if(key=='DEL' && this.phoneNumber.length>0) {
        this.phoneNumber = this.phoneNumber.slice(0, -1);
      } else {
        if(this.phoneNumber.length<9) {
          this.phoneNumber+=key;
        }
        if(this.phoneNumber.length===9) {
          this.phoneIncomplete = false;
        }
      }
    }
  }
}
</script>

<style scoped>
.service-content .followPhone {
  color: #ffffff;
  font-weight: 700;
  font-size: 1.7vh;
  line-height: 3vh;
  margin-top: 1.2vh;
}

.service-content .estimatedTime {
  color: #ffffff;
  font-weight: 700;
  font-size: 2.6vh;
  line-height: 3.2vh;
}

.service-content .estimatedTimeLabel {
  color: #ffffff;
  font-weight: 700;
  font-size: 1.7vh;
  line-height: 3.2vh;
  margin-bottom: 6vh;
}


.service-content .center-qrcode {
  margin-left: auto;
  margin-right: auto;
}
.service-content .phoneIncomplete {
  opacity: 0.5;
  border: transparent 0.5vh solid !important;
}
.service-content .feedback-icon {
  height: 12vh;
  margin-bottom: 5vh;
}
.service-content .feedback-icon.big {
  height: 23vh;
  margin-bottom: 5vh;
}
.service-content .feedback-title {
  color: #ffffff;
  margin-top: 11vh;
  margin-bottom: 8vh;
  font-weight: 700;
  font-size: 4.8vh;
  line-height: 5.2vh;
  padding-left: 5vh;
  padding-right: 5vh;
}
.service-content .feedback-title.big {
  color: #ffffff;
  margin-top: 0vh;
  margin-bottom: 1vh;
  font-weight: 700;
  font-size: 6.5vh;
  line-height: 6.5vh;
  padding-left: 5vh;
  padding-right: 5vh;
}
.service-content .feedback-message {
  color: #ffffff;
  margin-top: 0vh;
  margin-bottom: 1vh;
  font-weight: 700;
  font-size: 3.5vh;
  line-height: 3.5vh;
  padding-left: 5vh;
  padding-right: 5vh;
}

.service-content .title {
  color: #ffffff;
  margin-top: 9vh;
  margin-bottom: 0vh;
  font-weight: 500;
  font-size: 2.6vh;
  line-height: 5.2vh;
  padding-left: 4vh;
  padding-right: 4vh;
}
.service-content .main-title {
  color: #ffffff;
  margin-bottom: 9vh;
  font-weight: 700;
  font-size: 3.5vh;
  line-height: 5.2vh;
  padding-left: 4vh;
  padding-right: 4vh;
}

.service-content .new-service-title {
  color: #ffffff;
  margin-bottom: 0.5vh;
  font-weight: 700;
  font-size: 3.2vh;
  margin-top: 1vh;
  line-height: 2.5vh;
  padding-left: 4vh;
  padding-right: 4vh;
}

.service-content .queue-title {
  color: #ffffff;
  margin-top: 1vh;
  margin-bottom: 9vh;
  font-weight: 500;
  font-size: 2.3vh;
  line-height: 4vh;
  padding-left: 4vh;
  padding-right: 4vh;
  margin-left: 0vh;
  position: initial;
}

.service-content .phoneInput {
  color: #000000;
  margin-bottom: 3vh;
  font-weight: 600;
  letter-spacing: 0.5vh;
  font-size: 4.5vh;
  line-height: 7.2vh;
  width: 35vh;
  height: 7.2vh;
  background: white;
  padding-left: 1vh;
  padding-right: 1vh;
  margin-left: auto;
  margin-right: auto;
}
.service-content .title.home {
  color: #ffffff;
  margin-top: 18vh;
  margin-bottom: 12vh;
  font-weight: 700;
  font-size: 4.8vh;
  line-height: 5.2vh;
  padding-left: 4vh;
  padding-right: 4vh;
}

.service-content .keyboard {
  width: 30vh;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4vh;
}
.service-content .keyboard .key {
  margin: 0.2vh;
  border: transparent 0.5vh solid;
  cursor: pointer;
  font-weight: 700;
  font-size: 5.5vh;
  line-height: 9vh;
  width: 10vh;
  height: 10vh;
  display: inline-block;
}

.service-content .keyboard .key img {
  height: 4.5vh;
  margin-left: 2vh;
  margin-top: 2.3vh;
}

.service-content .keyboard .key:hover {
  border: white 0.5vh solid;
}
.service-content .keyboard .key.empty {
  border: transparent 0.5vh solid;
  cursor: default;
}
.service-content .keyboard .key.empty:hover {
  border: transparent 0.5vh solid;
}

.service-content .actionText {
  text-transform: uppercase;
  color: #ffffff;
  font-size: 2vh;
  line-height: 2vh;
  font-weight: 700;
  margin-bottom: 2vh;
}

.service-content .option {
  margin: 0.8vh;
  padding: 1vh 1.5vh 1vh 1.5vh;
  border: transparent 0.5vh solid;
  cursor: pointer;
  font-weight: 700;
  font-size: 4.2vh;
  line-height: 5.2vh;
  display: inline-block;
}

.service-content .option.withborder {
  border: rgba(255,255,255,0.5) 0.5vh solid;
}

.service-content .option:hover {
  border: white 0.5vh solid;
}

.services-screen .no-printer-warning {
  margin:  7vh auto 7vh auto;
  padding: 1vh 1.5vh 1vh 1.5vh;
  border: transparent 0.5vh solid;
  background: white;
  color: #212121;
  font-weight: 700;
  font-size: 2.2vh;
  line-height: 3.2vh;
  max-width: 48vh;
}

.services-screen .main-title {
  color: #ffffff;
  font-weight: 700;
  font-size: 3.2vh;
  margin: 1vh 1vh 0vh 1vh;
  line-height: 2.5vh;
  padding-left: 4vh;
  padding-right: 4vh;
}
</style>