/***************************************************
 *
 * ERROR CODES
 * Translation of error codes to human language
 *
 ***************************************************/

module.exports = Object.freeze({
    SERVER_UNREACHABLE: {
        pt: 'Não foi possível estabelecer comunicação com o servidor.',
        en: 'There was a problem connecting to server.'
    },
    PRINTER_UNREACHABLE: {
        pt: 'Não foi possível estabelecer comunicação com impressora.',
        en: 'There was a problem connecting to printer.'
    },
    NO_CONNECTION: {
        pt: 'Não foi possível estabelecer comunicação com impressora.',
        en: 'There was a problem connecting to printer.'
    },
    PRINTER_UNAVAILABLE: {
        pt: 'Não existe qualquer impressora configurada, contacte os serviços.',
        en: 'The printer is not available right now, contact services.'
    },
    PRINTER_ERROR: {
        pt: 'Ocorreu um erro ao imprimir senha.',
        en: 'There was an error printing your ticket.'
    },
    EPTR_REC_EMPTY: {
        pt: 'A Impressora não tem papel, contacte os serviços.',
        en: 'There is no paper in the printer, contact the services.'
    },
    EPTR_COVER_OPEN: {
        pt: 'A tampa da impressora não está devidamente fechada, contacte os serviços.',
        en: 'The printer cover is not close, contact the services.'
    },
    DEFAULT_PRINT_ERROR: {
        pt: 'Ocorreu um erro com a impressora.',
        en: 'There was an error with the printer.'
    },
    ACTIVATION_CODE_INVALID: {
        pt: 'O código de ativação é inválido.',
        en: 'Activation code invalid.'
    },

    APIKEY_INVALID: {
        pt: 'Chave de API inválida.',
        en: 'API key is invalid.'
    },

    UNEXPECTED_SERVER_RESPONSE: {
        pt: 'Erro de servidor.',
        en: 'Server error.'
    }
});