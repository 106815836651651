<template>
  <div
    class="new-ticket-splash-screen h-screen w-screen absolute top-0 left-0 right-0 bottom-0 z-30 flex flex-col h-screen w-screen text-center text-white bg-ucframework justify-center"
  >
    <div :class="{ 'upper-part-calling': !newTicket, 'upper-part': newTicket }">
      <div class="title" v-if="newTicket">
        {{ translation["NEW_TICKET_TITLE"][selectedLanguage] }}
      </div>
      <div class="main-title">
        {{ ticketData["serviceName"] }}
      </div>
      <div class="title">
        {{ ticketData["queueDescription"] }}
      </div>
      <div class="title" v-if="!newTicket">
        {{ queues_translation["CALLING_TICKET"][selectedLanguage] }}
      </div>
    </div>

    <QueueTicket
      class="mx-auto"
      size="medium"
      :selected="true"
      :isNewTicket="newTicket"
      :ticketNumber="ticketNumber"
      :language="selectedLanguage"
      :queueName="ticketData['queueName']"
      :queuePos="0"
    ></QueueTicket>
    <!-- :post="ticketData['workstationName']" -->
    <!-- <QueueTicket2 v-else class="mx-auto" size="medium" :selected="true" :isNewTicket="true" :ticketNumber="ticketNumber" :language="selectedLanguage"  :post="ticketData['workstationName']" :queueName="ticketData['queueName']" :queuePos="0"></QueueTicket2> -->

    <div class="estimation" v-if="newTicket && config.showNewTicketEstimatedTime">
      <div class="estimatedTime">{{ estimatedTime }}</div>
      <div class="estimatedTimeLabel">{{ translation["ESTIMATED"][selectedLanguage] }}</div>
    </div>

    <div class="follow-section" v-if="newTicket">
      <div class="ticket-qrcode">
        <qr-code :url="config.followYourTicketURL" :color="'#ffffff'" :bg-color="'#212121'" :level="'L'"></qr-code>
      </div>
      <div class="followPhone">{{ translation["FOLLOW_ON_PHONE"][selectedLanguage] }}</div>
    </div>
    <div v-else class="follow-section goto-post">
      <div class="actionText">{{ queues_translation["GO_TO"][selectedLanguage] }}</div>
      <div class="actionText">
        {{ queues_translation["POST"][selectedLanguage] }} {{ ticketData["workstationName"] }}
      </div>
    </div>
  </div>
</template>

<script>
import QRcode from "@/components/QRcode.vue";
import QueueTicket from "@/components/qflow/QueueTicket";

import translation from "@/config/translation";
import config from "@/config/config";

export default {
  name: "NewTicketSplashScreen",
  components: {
    QueueTicket,
    "qr-code": QRcode,
  },
  computed: {
    newTicket() {
      return this.ticketData.isNew ? true : false;
    },
    qrcodeSize() {
      return window.innerWidth * 0.2;
    },
    estimatedTime() {
      if (this.ticketData["estimatedTimeLeft"]) {
        let parts = this.ticketData["estimatedTimeLeft"].split(":");
        if (parts[0] !== "00" && parts[1] !== "00") {
          return parts[0] + "h " + parts[1] + "min " + parts[2] + "s";
        } else if (parts[0] === "00") {
          return parts[1] + "min " + parts[2] + "s";
        } else {
          return "--:--";
        }
      } else {
        return "";
      }
    },
    config() {
      return config; //both for get ticket and give feedback
    },
    //TRADUÇÃO DA USER INTERFACE
    translation() {
      return translation["SERVICES_SCREEN"]; //both for get ticket and give feedback
    },
    queues_translation() {
      return translation["QUEUES_SCREEN"];
    },
    ticketData() {
      return this.$store.state.newTicketData;
    },
    ticketNumber() {
      if (this.ticketData !== null) {
        return this.ticketData["queueName"] + this.ticketData["ticketNumber"].toString();
      } else {
        return "";
      }
    },
    selectedLanguage() {
      return this.$store.state.selectedLanguage;
    },
  },
};
</script>

<style scoped>
.new-ticket-splash-screen .goto-post {
  margin-top: 5vh;
}
.new-ticket-splash-screen .title {
  color: #ffffff;
  margin-bottom: 0vh;
  font-weight: 700;
  font-size: 2.8vh;
  line-height: 5.2vh;
  padding-left: 4vh;
  padding-right: 4vh;
}
.new-ticket-splash-screen .main-title {
  color: #ffffff;
  font-weight: 700;
  font-size: 3.5vh;
  line-height: 5.2vh;
  padding-left: 4vh;
  padding-right: 4vh;
}

.new-ticket-splash-screen .upper-part {
  margin-bottom: 9vh;
  margin-top: 5vh;
}

.new-ticket-splash-screen .upper-part-calling {
  margin-bottom: 9vh;
}

.new-ticket-splash-screen .new-service-title {
  color: #ffffff;
  margin-bottom: 0.5vh;
  font-weight: 700;
  font-size: 2.5vh;
  line-height: 4vh;
  padding-left: 4vh;
  padding-right: 4vh;
}

.new-ticket-splash-screen .queue-title {
  color: #ffffff;
  margin-bottom: 9vh;
  font-weight: 700;
  font-size: 2.5vh;
  line-height: 4vh;
  padding-left: 4vh;
  padding-right: 4vh;
  margin-left: 0vh;
  position: initial;
}

.new-ticket-splash-screen .phoneInput {
  color: #000000;
  margin-bottom: 3vh;
  font-weight: 600;
  letter-spacing: 0.5vh;
  font-size: 4.5vh;
  line-height: 7.2vh;
  width: 35vh;
  height: 7.2vh;
  background: white;
  padding-left: 1vh;
  padding-right: 1vh;
  margin-left: auto;
  margin-right: auto;
}
.new-ticket-splash-screen .title.home {
  color: #ffffff;
  margin-top: 18vh;
  margin-bottom: 12vh;
  font-weight: 700;
  font-size: 4.8vh;
  line-height: 5.2vh;
  padding-left: 4vh;
  padding-right: 4vh;
}

.new-ticket-splash-screen .actionText {
  text-transform: uppercase;
  color: #ffffff;
  font-size: 3.5vh;
  line-height: 2.5vh;
  font-weight: 700;
  margin-bottom: 2vh;
}

.new-ticket-splash-screen .option {
  margin: 0.8vh;
  padding: 1vh 1.5vh 1vh 1.5vh;
  border: transparent 0.5vh solid;
  cursor: pointer;
  font-weight: 700;
  font-size: 4.2vh;
  line-height: 5.2vh;
  display: inline-block;
}

.new-ticket-splash-screen .option:hover {
  border: white 0.5vh solid;
}
.new-ticket-splash-screen .followPhone {
  margin-top: 2vh;
  font-weight: 700;
  font-size: 1.7vh;
  line-height: 3.2vh;
}
.new-ticket-splash-screen .ticket-qrcode {
  margin-top: 5vh;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  height: 10vh;
  width: 10vh;
}
.new-ticket-splash-screen .estimation {
  font-weight: bold;
  font-size: 1.7vh;
}
</style>
