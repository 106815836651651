<template>
  <div class="service-content h-screen w-screen absolute top-0 left-0 right-0 bottom-0 z-10 flex flex-col h-screen w-screen text-center text-white bg-ucframework">
    <div class="title">
      {{translation['GET_A_TICKET'][language]}}
    </div>
    <div class="new-service-title">
      {{currentScreenData['serviceName']}}
    </div>
    <div class="queue-title" v-if="currentScreenData['queueName']">
      {{currentScreenData['queueName']}}
    </div>

    <div class="flex-1">
      <div class="actionText">
        {{translation['CHOOSE_TICKET_DELIVERY'][language]}}
      </div>
      <div @click="insertPhoneNumber()" class="option inline-block">
        {{translation['TICKET_DELIVERY_PHONE'][language]}}
      </div>
      <div class="clear-both"></div>
      <div v-if="isPrinterAvailable" @click="getPrintedTicket" class="option inline-block">
        {{translation['TICKET_DELIVERY_SCREEN'][language]}}
      </div>
    </div>

    <div v-if="!isPrinterAvailable" class="container mx-auto no-printer-warning ">
      {{translation['NO_PRINTER_WARNING'][language]}}
    </div>
  </div>
</template>

<script>
import translation from "@/config/translation";

export default {
  name: "SelectTicketMethodScreen",
  computed: {
    currentScreenData: function () {
      return this.$store.state.currentScreenData;
    },
    //TRADUÇÃO DA USER INTERFACE
    translation: function () {
      return translation['SERVICES_SCREEN']; //both for get ticket and give feedback
    },
    language: function() {
      return this.$store.state.selectedLanguage;
    },
    isPrinterAvailable: function () {
      return this.$store.getters.isPrinterAvailable;
    }
  },

  methods: {
    insertPhoneNumber() {
      let pageRef= 'insert_phone_number:'+this.currentScreenData['id']+':'+this.currentScreenData['queueID'];
      this.$store.dispatch('openInteractiveScreen',[pageRef]);
    },
    getPrintedTicket() {
      console.log(this.currentScreenData)
      this.$store.dispatch('getTicket', {
        queueID: this.currentScreenData.queueID,
        queueName: this.currentScreenData.queueLetter,
        queueDescription: this.currentScreenData.queueName,
        serviceName: this.currentScreenData.serviceName,
        print: true
      });
    }

  }
}
</script>

<style scoped>
.service-content .followPhone {
  color: #ffffff;
  font-weight: 700;
  font-size: 1.7vh;
  line-height: 3vh;
  margin-top: 1.2vh;
}

.service-content .estimatedTime {
  color: #ffffff;
  font-weight: 700;
  font-size: 2.6vh;
  line-height: 3.2vh;
}

.service-content .estimatedTimeLabel {
  color: #ffffff;
  font-weight: 700;
  font-size: 1.7vh;
  line-height: 3.2vh;
  margin-bottom: 6vh;
}

.service-content .center-qrcode {
  margin-left: auto;
  margin-right: auto;
}
.service-content .phoneIncomplete {
  opacity: 0.5;
  border: transparent 0.5vh solid !important;
}
.service-content .feedback-icon {
  height: 12vh;
  margin-bottom: 5vh;
}
.service-content .feedback-icon.big {
  height: 23vh;
  margin-bottom: 5vh;
}
.service-content .feedback-title {
  color: #ffffff;
  margin-top: 11vh;
  margin-bottom: 8vh;
  font-weight: 700;
  font-size: 4.8vh;
  line-height: 5.2vh;
  padding-left: 5vh;
  padding-right: 5vh;
}
.service-content .feedback-title.big {
  color: #ffffff;
  margin-top: 0vh;
  margin-bottom: 1vh;
  font-weight: 700;
  font-size: 6.5vh;
  line-height: 6.5vh;
  padding-left: 5vh;
  padding-right: 5vh;
}
.service-content .feedback-message {
  color: #ffffff;
  margin-top: 0vh;
  margin-bottom: 1vh;
  font-weight: 700;
  font-size: 3.5vh;
  line-height: 3.5vh;
  padding-left: 5vh;
  padding-right: 5vh;
}

.service-content .title {
  color: #ffffff;
  margin-top: 9vh;
  margin-bottom: 0vh;
  font-weight: 500;
  font-size: 2.6vh;
  line-height: 5.2vh;
  padding-left: 4vh;
  padding-right: 4vh;
}
.service-content .main-title {
  color: #ffffff;
  margin-bottom: 9vh;
  font-weight: 700;
  font-size: 3.5vh;
  line-height: 5.2vh;
  padding-left: 4vh;
  padding-right: 4vh;
}

.service-content .new-service-title {
  color: #ffffff;
  margin-bottom: 0.5vh;
  font-weight: 700;
  font-size: 3.2vh;
  margin-top: 1vh;
  line-height: 2.5vh;
  padding-left: 4vh;
  padding-right: 4vh;
}

.service-content .queue-title {
  color: #ffffff;
  margin-top: 1vh;
  margin-bottom: 9vh;
  font-weight: 500;
  font-size: 2.3vh;
  line-height: 4vh;
  padding-left: 4vh;
  padding-right: 4vh;
  margin-left: 0vh;
  position: initial;
}

.service-content .phoneInput {
  color: #000000;
  margin-bottom: 3vh;
  font-weight: 600;
  letter-spacing: 0.5vh;
  font-size: 4.5vh;
  line-height: 7.2vh;
  width: 35vh;
  height: 7.2vh;
  background: white;
  padding-left: 1vh;
  padding-right: 1vh;
  margin-left: auto;
  margin-right: auto;
}
.service-content .title.home {
  color: #ffffff;
  margin-top: 18vh;
  margin-bottom: 12vh;
  font-weight: 700;
  font-size: 4.8vh;
  line-height: 5.2vh;
  padding-left: 4vh;
  padding-right: 4vh;
}

.service-content .keyboard {
  width: 23vh;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4vh;
}
.service-content .keyboard .key {
  margin: 0.2vh;
  border: transparent 0.5vh solid;
  cursor: pointer;
  font-weight: 700;
  font-size: 4.2vh;
  line-height: 6vh;
  width: 7vh;
  height: 7vh;
  display: inline-block;
}

.service-content .keyboard .key img {
  height: 4.5vh;
  margin-left: 0.75vh;
  margin-top: 0.75vh;
}

.service-content .keyboard .key:hover {
  border: white 0.5vh solid;
}
.service-content .keyboard .key.empty {
  border: transparent 0.5vh solid;
  cursor: default;
}
.service-content .keyboard .key.empty:hover {
  border: transparent 0.5vh solid;
}

.service-content .actionText {
  text-transform: uppercase;
  color: #ffffff;
  font-size: 2vh;
  line-height: 2vh;
  font-weight: 700;
  margin-bottom: 2vh;
}

.service-content .option {
  margin: 0.8vh;
  padding: 1vh 1.5vh 1vh 1.5vh;
  border: transparent 0.5vh solid;
  cursor: pointer;
  font-weight: 700;
  font-size: 4.2vh;
  line-height: 5.2vh;
  display: inline-block;
}

.service-content .option.withborder {
  border: rgba(255,255,255,0.5) 0.5vh solid;
}

.service-content .option:hover {
  border: white 0.5vh solid;
}

.services-screen .no-printer-warning {
  margin:  7vh auto 7vh auto;
  padding: 1vh 1.5vh 1vh 1.5vh;
  border: transparent 0.5vh solid;
  background: white;
  color: #212121;
  font-weight: 700;
  font-size: 2.2vh;
  line-height: 3.2vh;
  max-width: 48vh;
}

.services-screen .main-title {
  color: #ffffff;
  font-weight: 700;
  font-size: 3.2vh;
  margin: 1vh 1vh 0vh 1vh;
  line-height: 2.5vh;
  padding-left: 4vh;
  padding-right: 4vh;
}
</style>