<template>
<div id="no-content-screen" class="h-full w-full text-center flex flex-col justify-center">
  <div class="no-content-logo"></div>
</div>
</template>

<script>
export default {
  name: "NoContent"
}
</script>

<style scoped>
.no-content-logo {
  width: 30%;
  height: 10%;
  margin-left: auto;
  margin-right: auto;
  background-color: #000000;
  background-size: contain;
  -webkit-mask: url('../assets/logo.svg') no-repeat center;
  mask: url('../assets/logo.svg') no-repeat center;
  mask-size: contain, auto, contain;
  -webkit-mask-size: contain, auto, contain;
}
</style>