/***************************************************
 *
 * CONFIGURATION FILE
 * Setup for UC Motion Screen devices (before compilation)
 * Queue Screen, Broadcast Screen and dispenser
 *
 ***************************************************/
module.exports = Object.freeze({
  /************** Broadcast screen config **************/
  endPointURL: "https://ucpages.uc.pt/",
  refreshDataMinutes: 5, //time to get new data from server
  broadcastAppName: "ucmotionscreen_broadcast", //name used for logger
  carouselSeconds: 15, //each slide seconds

  /************** Queues System config ************/
  qFlowHost: process.env.VUE_APP_API_URL_QFLOW, //URL of the endpoint
  //*** DISPENSER ***//
  showNewTicketSeconds: 3, //How much seconds to show in the screen the new ticket information
  noActivitySeconds: 300, //Seconds to activate screen saver / go to welcome screen (default: 5 minutes / 300 seconds)
  welcomePageDispenser: {
    title_en: "Welcome to the UC Student Hub", //Title text in English
    title_pt: "Bem-vindo ao UC Student Hub", //Title text in Portuguese
    footer_pt: "Para mais, vai a ucstudenthub.uc.pt", //Footer text in Portuguese
    footer_en: "For more, go to ucstudenthub.uc.pt", //Footer text in English
    qrcode_url: "https://ucstudenthub.uc.pt", //Footer QR code
    image: "", //background image
    video: "screen_saver_video2.mp4", //background video
  },
  showNewTicketEstimatedTime: true, //Show how much time the user is expected to wait
  followYourTicketURL: "https://ucstudenthub.uc.pt", //QR code to follow a ticket (after the user asking for a ticket)
  ticketsPrinter: {
    ePosPrint_Device_ID: "local_printer",
    layoutStyle: "student_hub", //student_hub => apply brand font (Inter) and 2 tone printing; "default" => uses default text font
    title: "UC Student Hub", //text to print on top of a ticket
    footNote: {
      pt: "Tolerância 3 senhas",
      en: "Tolerance 3 tickets",
    },
  },
  dispenserAppName: "ucmotionscreen_dispenser", //name used for logger
  //*** QUEUE'S SCREEN ***//
  queuesScreen: {
    footer_pt: "Acompanhe as filas em ucstudenthub.uc.pt", //Footer text in Portuguese
    footer_en: "Follow the queues at ucstudenthub.uc.pt", //Footer text in English
  },
  callingNewTicketSeconds: 5,
  refreshDataIntervalSeconds: 30,
  queuesAppName: "ucmotionscreen_queuesscreen", //name used for logger
});
