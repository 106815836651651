/***************************************************
 *
 * Printer Class
 * Manages connection to printer and all its events;
 * Allows to print tickets
 *
 ***************************************************
 * Note: This class assumes that the Epson SDK was imported to HTML document and it is available as a global variable!
 */

export default class Printer extends EventTarget {

    constructor(ip, deviceID = 'local_printer', debug = false) {
        super();
        if(typeof epson === 'undefined') {
            throw new Error("EPSON SDK not found!");
        }

        if(ip==='0.0.0.0' && ip.split('.').length !== 4) {
            throw new Error("INVALID PRINTER IP: "+ip);
        }

        if(typeof deviceID !== 'string' || deviceID.length === 0) {
            throw new Error("INVALID PRINTER DEVICE ID: "+deviceID);
        }

        this.printer = null;
        this.deviceID = deviceID;
        this.printerIP = ip;
        this.started = false;
        this.available = false;
        this.errorCode = null;
        this.device = null;
        this.debug = debug;
        this.connected = false;
        this.log("Printer object created")
    }

    log(message) {
        if(this.debug) {
            console.log("[Printer:"+this.deviceID+":"+this.printerIP+"] " + message);
        }
    }

    start() {
        let self = this;
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line no-undef
            self.device = new epson.ePOSDevice();

            self.device.connect(self.printerIP, '8008', function (resultConnect){
                let deviceId = self.deviceID;
                let options = {'crypto' : true, 'buffer' : false};

                /*
                self.device.onreconnecting = function () {
                    self.log("Device reconnecting...")
                }

                self.device.onreconnect = function () {
                    self.log("Device reconnected");
                    self.available = true;
                    self.dispatchEvent(new Event('available'));
                }

                self.device.ondisconnect = function () {
                    self.log("Device disconnected!!");
                    self.available = false;
                    self.connected = false;
                    self.dispatchEvent(new Event('unavailable'));
                }*/

                self.log("resultConnect: "+JSON.stringify(resultConnect));

                if ((resultConnect == 'OK') || (resultConnect == 'SSL_CONNECT_OK')) {
                    self.log("Device connected");

                    self.connected = true;

                    //Retrieves the Printer object
                    self.device.createDevice(deviceId, self.device.DEVICE_TYPE_PRINTER, options, function (deviceObj, errorCode) {
                        if (deviceObj === null) {
                            //Displays an error message if the system fails to retrieve the Printer object
                            self.available = false;
                            self.errorCode = errorCode;
                            reject(errorCode);
                            return;
                        }
                        self.printer = deviceObj;
                        self.printer.interval = 5000;

                        self.errorCode = null;
                        self.available = true;
                        self.started = true;

                        //enables monitor for the printer!
                        //self.printer.startMonitor();

                        /*self.printer.onreceive = function (res) {
                            self.log("ON RECEIVE", JSON.stringify(res));
                        }*/
                        self.printer.onconveropen = function () {
                            self.log("COVER OPEN");
                            self.errorCode = "COVER_OPEN";
                            self.available = false;
                            self.dispatchEvent(new Event('unavailable'));
                        }
                        self.printer.onpaperok = function () {
                            self.log("PAPER OK!");
                            self.errorCode = null;
                            self.available = true;
                            self.dispatchEvent(new Event('available'));
                        }
                        self.printer.onpapernearend = function () {
                            self.log("PAPER NEAR END!");
                            self.errorCode = null;
                            self.available = true;
                            self.dispatchEvent(new Event('available'));
                        }
                        self.printer.onpaperend = function () {
                            self.log("PAPER ENDED :(");
                            self.errorCode = "PAPER_END";
                            self.available = false;
                            self.dispatchEvent(new Event('unavailable'));
                        }
                        self.printer.ononline= function () {
                            self.log("PRINTER ONLINE!");
                            self.errorCode = null;
                            self.available = true;
                            self.dispatchEvent(new Event('available'));
                        }
                        self.printer.onoffline= function () {
                            self.log("PRINTER OFFLINE");
                            self.errorCode = null;
                            self.available = false;
                            self.dispatchEvent(new Event('unavailable'));
                        }
                        self.printer.onpoweroff = function () {
                            self.log("PRINTER OFF");
                            self.errorCode = "PRINTER_OFF";
                            self.available = false;
                            self.dispatchEvent(new Event('unavailable'));
                        }
                        resolve("OK");
                    });
                }
                else {
                    //There was an error!
                    self.available = false;
                    self.errorCode = resultConnect;
                    self.dispatchEvent(new Event('unavailable'));
                    reject(resultConnect);
                }
            }, {
                "eposprint" : true
            });
        });
    }

    destroy() {
        let self = this;
        return new Promise((resolve, reject) => {
            //stop monitoring this printer
            self.printer.stopMonitor();

            self.device.deleteDevice(self.printer,function (result) {
                if(result==="OK") {
                    if(self.connected) {
                        self.device.disconnect();
                    }
                    resolve("OK");
                } else {
                    reject(result);
                }
            })
        });
    }

    print(ticketInfo, style = "default", printJobID) {
        return new Promise((resolve, reject) => {
            if (!this.started) {
                reject("Printer object not started!")
                //throw new Error("Printer not started!");
            }
            if (!this.available) {
                reject("Printer not available!")
                //throw new Error("Printer not available!");
            }

            if (ticketInfo.serviceName && ticketInfo.queueDescription && ticketInfo.queueName && ticketInfo.ticketNumber && ticketInfo.footerNote && ticketInfo.title) {

                //this.printer.stopMonitor();

                let date = new Date()//.toISOString();

                let day = ""+date.getDate();
                day = day.length === 1 ? "0" + day : day;

                let month = ""+(date.getMonth() + 1);
                month = month.length === 1 ? "0" + month : month;

                let hour = ""+date.getHours();
                hour = hour.length === 1 ? "0" + hour : hour;

                let min = ""+date.getMinutes();
                min = min.length === 1 ? "0" + min : min;

                let year_month_day = day + '/' + month + '/' + date.getFullYear() //date.split("T")[0].split("-");
                let hour_min =  hour + ':' + min; //date.split("T")[1].split('.')[0];

                let ticketFooter = year_month_day + " " + hour_min + " - " + ticketInfo.footerNote;

                let ticketNumber = ticketInfo.queueName + "" + ticketInfo.ticketNumber;
                if (typeof ticketInfo.ticketNumber === 'string' && ticketInfo.ticketNumber.length === 1) {
                    //is a string
                    ticketNumber = ticketInfo.queueName + "0" + ticketInfo.ticketNumber;
                } else if (ticketInfo.ticketNumber.toString().length === 1) {
                    //is a number
                    ticketNumber = ticketInfo.queueName + "0" + ticketInfo.ticketNumber;
                }

                let one_line_ticket_description = ticketInfo.serviceName + ' - ' + ticketInfo.queueDescription;
                let too_long = one_line_ticket_description.length > 35;
                if (style === 'student_hub') {
                    console.log("student_hub style")
                    let canvas = document.createElement("canvas");
                    canvas.width = 500;
                    if (too_long) {
                        canvas.height = 325;
                    } else {
                        canvas.height = 290;
                    }

                    let ctx = canvas.getContext('2d');

                    ctx.fillStyle = "#000000";
                    ctx.font = "bold 40px Inter";
                    ctx.textAlign = "center";
                    ctx.fillText(ticketInfo.title, 250, 70);
                    ctx.font = "26px Inter";
                    if (too_long) {
                        //max length 25
                        ctx.fillText(ticketInfo.serviceName, 250, 110);
                        ctx.fillText(ticketInfo.queueDescription, 250, 145);
                        ctx.font = "bold 70px Inter";
                        ctx.fillText(ticketNumber, 250, 230);
                        ctx.fillStyle = "#3d3d3d";
                        ctx.font = "20px Inter";
                        ctx.fillText(ticketFooter, 250, 285);
                        console.log("debug 3")
                    } else {
                        //max length 25
                        ctx.fillText(one_line_ticket_description, 250, 110);
                        ctx.font = "bold 70px Inter";
                        ctx.fillText(ticketNumber, 250, 195);
                        ctx.fillStyle = "#3d3d3d";
                        ctx.font = "20px Inter";
                        ctx.fillText(ticketFooter, 250, 250);
                        console.log("debug 4")
                    }
                    //console.log(canvas)
                    //var tag = document.getElementById("app");
                    //tag.appendChild(canvas);
                    this.printer.onreceive = function (res) {
                        //console.log("ON RECEIVE", res);
                        if(res.success) {
                            resolve(res);
                        } else {
                            reject(res);
                        }
                    };
                    this.printer.onerror = function (err) {
                        reject(err)
                        //console.log("ON ERROR", err);
                    };
                    this.printer.print(canvas, true, this.printer.MODE_GRAY16, printJobID);
                } else {
                    this.printer.addFeedUnit(0);
                    this.printer.addTextLang('mul');
                    this.printer.addTextAlign(this.printer.ALIGN_CENTER);
                    this.printer.addTextSmooth(true);
                    this.printer.addTextStyle(false, false, true, this.printer.COLOR_1);
                    this.printer.addTextFont(this.printer.FONT_B);
                    this.printer.addTextSize(2, 2);
                    this.printer.addText(ticketInfo.title + '\n');
                    this.printer.addTextStyle(false, false, false, this.printer.COLOR_1);
                    this.printer.addTextSize(1, 1);
                    this.printer.addFeedUnit(10);
                    this.printer.addText(one_line_ticket_description + '\n');
                    this.printer.addFeedUnit(12);
                    this.printer.addTextSize(4, 4);
                    this.printer.addText(ticketNumber + '\n');
                    this.printer.addFeedUnit(17);
                    this.printer.addTextSize(1, 1);
                    this.printer.addText(ticketFooter + '\n');
                    this.printer.addFeedUnit(25);
                    this.printer.addCut(this.printer.CUT_FEED);
                    this.printer.onreceive = function (res) {
                        //console.log("ON RECEIVE", res);
                        if(res.success) {
                            resolve(res);
                        } else {
                            reject(res);
                        }
                    };
                    this.printer.onerror = function (err) {
                        //console.log("ON ERROR", err);
                        reject(err);
                    };
                    this.printer.send(printJobID);
                }
                //this.printer.startMonitor();
            } else {
                reject("Ticket Information is invalid");
                //throw new Error("Ticket Information is invalid");
            }
        });
    }

}