<template>
<div id="qr-code" ref="thisqrcode" :style="{height: parentHeight+'px', width: parentWidth + 'px'}">
  <!-- <qrcode-vue :value="url" :background="bgColor" :foreground="color" :size="qrsize" :level="level" /> -->
  <canvas id="qr-code-canvas" ref="thisqrcode-canvas" :style="{height: parentHeight+'px', width: parentWidth + 'px'}"></canvas>
</div>
</template>

<script>
//import QrcodeVue from "qrcode.vue";
import QRCode from 'qrcode'

export default {
  name: "qr-code",
  props: ['url','bgColor','color','level'],
  data() {
    return {
      parentHeight: 50,
      parentWidth: 50,
      qrsize: 50
    }
  },
  mounted() {
    //console.log(this.$parent);
    //console.log("Largura: ", this.$refs['thisqrcode'].parentElement.offsetWidth)
    this.parentHeight = this.$refs['thisqrcode'].parentElement.offsetHeight;
    this.parentWidth = this.$refs['thisqrcode'].parentElement.offsetWidth;
    this.qrsize = this.parentWidth / window.devicePixelRatio;

    QRCode.toCanvas(this.$refs['thisqrcode-canvas'], this.url, {
      errorCorrectionLevel: this.level.toUpperCase(),
      quality: 0.99,
      margin: 0,
      width: this.parentWidth,
      color: {
        dark: this.color,
        light: this.bgColor,
      }
    }, function (error) {
      if (error) console.error(error)
    })

  },
  /*components: {
    'qrcode-vue': QrcodeVue,
  }*/
}
</script>

<style scoped>

</style>