/***************************************************
 *
 * TRANSLATION FILE
 * English and Portuguese Strings
 *
 ***************************************************/
module.exports = Object.freeze({
  QUEUES_SCREEN: {
    QUEUE: {
      pt: "Fila",
      en: "Queue",
    },
    CALLING_TICKET: {
      pt: "A CHAMAR",
      en: "CALLING",
    },
    TICKET: {
      pt: "Senha",
      en: "Ticket",
    },
    NEW_TICKET: {
      pt: "A Tua Senha",
      en: "Your Ticket",
    },
    GO_TO: {
      pt: "Dirija-se à",
      en: "Go to",
    },
    POST: {
      pt: "Mesa",
      en: "Post",
    },
    STATUS_ONGOING: {
      pt: "ATUAL",
      en: "ONGOING",
    },
    STATUS_NEXT: {
      pt: "Próximo",
      en: "Next",
    },
    STATUS_SCHEDULE: {
      pt: "MARCAÇÃO",
      en: "SCHEDULE",
    },
    STATUS_WAITING: {
      pt: "Espera",
      en: "Waiting",
    },
  },
  SERVICES_SCREEN: {
    // *** GENERAL UI *** //
    BACK_BUTTON: {
      pt: "Voltar",
      en: "Back",
    },
    //****** GET TICKET SERVICE ******//
    PAPERLESS_WARNING: {
      pt: "Apenas senhas SMS",
      en: "Paperless tickets only",
    },
    NO_PRINTER_WARNING: {
      pt: "Caso queira a sua senha impressa, dirija-se a um quiosque com impressora",
      en: "If you want your ticket printed please go to a quiosk with a printer",
    },
    //****** GET TICKET SERVICE ******//
    CHOOSE_SERVICE: {
      pt: "ESCOLHA O SERVIÇO",
      en: "CHOOSE SERVICE",
    },
    CHOOSE_QUEUE: {
      pt: "ESCOLHA A FILA",
      en: "CHOOSE THE QUEUE",
    },
    GET_A_TICKET: {
      pt: "Tirar senha",
      en: "Get a ticket",
    },
    CHOOSE_TICKET_DELIVERY: {
      pt: "Escolhe como receber a senha",
      en: "Choose how to receive the ticket",
    },
    TICKET_DELIVERY_PHONE: {
      pt: "No telemóvel",
      en: "On my phone",
    },
    TICKET_DELIVERY_SCREEN: {
      pt: "Em papel",
      en: "Printed",
    },
    YOUR_PHONE_NUMBER: {
      pt: "O teu número de telemóvel",
      en: "Your phone number",
    },
    ACTION_GET_TICKET: {
      pt: "Tirar senha",
      en: "Get ticket",
    },
    NEW_TICKET_TITLE: {
      pt: "Nova senha",
      en: "New ticket",
    },
    FOLLOW_ON_PHONE: {
      pt: "Seguir no telemóvel",
      en: "Follow on your phone",
    },
    // NEW:
    ESTIMATED: {
      pt: "ESTIMADO",
      en: "ESTIMATED",
    },
    YOUR_TICKET: {
      pt: "A tua senha",
      en: "Your ticket",
    },
    // ERRORS:
    GET_TICKET_ERROR: {
      pt: "Ocorreu um erro ao emitir a senha. Tenta de novo mais tarde.",
      en: "There was an error getting your ticket. Try again later.",
    },
    INVALID_PHONE_NUMBER_ERROR: {
      pt: "O número inserido é inválido",
      en: "The phone number is invalid",
    },

    //****** GIVE FEEDBACK SERVICE ******//
    FEEDBACK_TITLE: {
      pt: "Como foi a tua experiência?",
      en: "How was your experience with us?",
    },
    FEEDBACK_TITLE_AWESOME: {
      pt: "Fantástico!",
      en: "Awesome!",
    },
    FEEDBACK_TITLE_THANKYOU: {
      pt: "Obrigado!",
      en: "Thank you!",
    },
    FEEDBACK_PERFECT_MESSAGE1: {
      pt: "Estamos felizes",
      en: "We are so glad we",
    },
    FEEDBACK_PERFECT_MESSAGE2: {
      pt: "por te ajudar.",
      en: "could help you.",
    },
    FEEDBACK_OK_MESSAGE1: {
      pt: "Envia-nos as tuas sugestões",
      en: "We would love to hear your suggestions",
    },
    FEEDBACK_OK_MESSAGE2: {
      pt: "em help.uc.pt",
      en: "at help.uc.pt",
    },
    FEEDBACK_NOTOK_MESSAGE1: {
      pt: "Diz-nos o que melhorar",
      en: "Tell us what can we improve",
    },
    FEEDBACK_NOTOK_MESSAGE2: {
      pt: "em help.uc.pt",
      en: "at help.uc.pt",
    },
  },
});
