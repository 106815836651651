<template>
<div class="failed-screen bg-ucframework">
  <svg xmlns="http://www.w3.org/2000/svg" class="error-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>
  <div class="failed-message">&nbsp;&nbsp;&nbsp;Falha Crítica&nbsp;&nbsp;&nbsp;Critical Failure&nbsp;&nbsp;&nbsp;Erreur Critique&nbsp;&nbsp;&nbsp;Kritischer Fehler&nbsp;&nbsp;&nbsp;关键错误&nbsp;&nbsp;&nbsp;クリティカル・エラー&nbsp;&nbsp;&nbsp;Falha Crítica&nbsp;&nbsp;&nbsp;Critical Failure&nbsp;&nbsp;&nbsp;Erreur Critique&nbsp;&nbsp;&nbsp;Kritischer Fehler&nbsp;&nbsp;&nbsp;关键错误&nbsp;&nbsp;&nbsp;クリティカル・エラー</div>
  <div class="retrying_message" v-if="failure_timer!=null">Retrying in {{retry_seconds}} second(s)</div>
  <div class="reset inline-block" @click="resetDevice">
    Reset Device
  </div>
</div>
</template>

<script>
export default {
  name: "failedScreen",
  computed: {
    retry_seconds: function () {
      return this.$store.state.failureRecoveryRetrySeconds;
    },
    failure_timer: function () {
      return this.$store.state.failureTimer;
    }
  },
  methods: {
    resetDevice: function () {
      return this.$store.dispatch('resetDevice');
    }
  }
}
</script>

<style scoped>
.failed-screen {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.failed-screen .reset {
  margin: 0.8vh;
  padding: 1vh 1.5vh 1vh 1.5vh;
  border: transparent 0.5vh solid;
  cursor: pointer;
  font-weight: 700;
  font-size: 4.2vh;
  line-height: 5.2vh;
  display: inline-block;
  color: white;
  position: absolute;
  margin-top: 50vh;
}
.failed-screen .reset:hover {
  border: white 0.5vh solid;
}

.retrying_message {
  margin-top: 22vh;
  font-size: 2vh;
  color: white;
  position: absolute;
}
.error-icon {
  width: 7vh;
  height: 7vh;
  color: crimson;
  margin-bottom: 10vh;
}
.failed-message {
  margin-top: 10vh;
  font-weight: bold;
  font-size: 3vh;
  color: rgba(255,255,255,0.3);
  white-space: nowrap;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-timing-function: linear;
  animation-duration: 14s;
  position: absolute;
  left: 0px;
  text-align: left;
  animation-name: infiniteloopxslide;
}
@keyframes infiniteloopxslide {
  0% {
    transform: translateX(0%);
  }
 100% {
   transform: translateX(-50%);
 }
}
</style>