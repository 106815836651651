<template>
  <div id="configuration-menu" class="configuration-menu bg-ucframework relative h-screen w-screen">
    <div
      class="interactive-content h-screen w-screen absolute top-0 left-0 right-0 bottom-0 z-10 flex flex-col h-screen w-screen text-center text-white bg-ucframework"
    >
      <div class="container mx-auto overflow-y-auto overflow-x-hidden">
        <div class="title config">
          Configuração
        </div>
        <div v-if="page === 'device_type'">
          <div class="actionText">
            Escolha o tipo de dispositivo
          </div>

          <div class="flex flex-col justify-center option-selector">
            <div class="option-mode" @click="changeMode('broadcast')" :class="{ selected: mode === 'broadcast' }">
              ECRÃ DIVULGAÇÃO
            </div>
            <div class="option-mode" @click="changeMode('screen')" :class="{ selected: mode === 'screen' }">
              ECRÃ FILAS
            </div>
            <div class="option-mode" @click="changeMode('dispenser')" :class="{ selected: mode === 'dispenser' }">
              DISPENSADOR
            </div>
          </div>

          <div class="option inline-block" @click="setDeviceType">
            Seguinte
          </div>
        </div>
        <div v-else-if="page === 'broadcast_screen_settings'">
          <div class="actionText">
            Insira os dados deste ecrã
          </div>

          <input
            v-model="screenID"
            placeholder="Screen key id"
            class="input border-0 outline-none w-2/3 place-self-center mb-3"
          />

          <input
            v-model="token"
            placeholder="Token de acesso"
            class="input border-0 outline-none w-2/3 place-self-center mb-3"
          />

          <div class="option inline-block" @click="saveData">
            Guardar
          </div>
          <div></div>
          <div v-if="broadcastScreenConfigurated" class="option red-option" @click="resetDevice">
            Reset Dispositivo
          </div>
        </div>
        <div v-else-if="page === 'select_services'">
          <div class="actionText">
            Escolha os serviços a disponibilizar senha
          </div>

          <div
            @click="selectService(s)"
            class="serviceSelection"
            :class="{ keyselected: serviceSelectedKey === s, selected: service.selected }"
            v-for="(service, s) in dispenserServices"
            :key="'services_' + s"
          >
            {{ service.name }}
            <div class="tick"></div>
          </div>

          <div v-if="loading && dispenserServices.length === 0">
            Loading...
          </div>
          <div v-else-if="dispenserServices.length === 0">
            Ocorreu um erro ao carregar a lista de serviços
            <div></div>
            <div class="option" @click="getServicesData">
              Retentar
            </div>
          </div>
          <div v-else-if="dispenserServices.length > 0" class="option inline-block" @click="saveServices">
            Guardar
          </div>
          <div v-if="apikeys.length > 0" class="option inline-block red-option" @click="resetDevice">
            Reset Dispositivo
          </div>
        </div>
        <div v-else>
          <div class="actionText">
            Insira o código de ativação do <span v-if="mode === 'screen'">ecrã</span><span v-else>dispensador</span>
          </div>

          <input
            v-model="activation_code"
            placeholder="Código de Ativação"
            class="input border-0 outline-none w-2/3 place-self-center mb-3"
          />

          <div>
            <div class="option inline-block" @click="addService">
              Adicionar Serviço
            </div>
            <div></div>
            <div v-if="apikeys.length > 0" class="option inline-block red-option" @click="resetDevice">
              Reset Dispositivo
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config/config";
import axios from "axios";

export default {
  name: "Configuration",
  data() {
    return {
      activation_code: "",
      page: "",
      dispenserServices: [],
      serviceSelectedKey: -1,
      loading: false,
      //BROADCAST SCREEN DATA:
      screenID: "",
      token: "",
    };
  },
  computed: {
    broadcastScreenConfigurated() {
      return this.$store.state.screenID.length > 0 && this.$store.state.token.length > 0;
    },
    mode() {
      return this.$store.state.mode;
    },
    apikeys() {
      return this.$store.state.apiKeys;
    },
    servicesData() {
      return this.$store.state.servicesData;
    },
    selectedServicesIDS() {
      return this.$store.state.selectedServicesIDS;
    },
  },
  mounted() {
    //select correct page
    if (this.mode === "broadcast") {
      this.page = "broadcast_screen_settings";
    } else if (this.apikeys.length === 0) {
      if (this.mode === "screen") {
        this.page = "";
      } else {
        this.page = "device_type";
      }
    } else if (this.mode === "dispenser") {
      this.page = "select_services";
      this.getServicesData();
    }

    window.addEventListener("keyup", this._keyListener);
  },
  unmounted() {
    window.removeEventListener("keyup", this._keyListener);
  },
  methods: {
    //For broadcast screen
    saveData: function() {
      if (this.token !== "" && this.screenID !== "") {
        //save data on localstorage
        localStorage.setItem("screenID", JSON.stringify(this.screenID));
        localStorage.setItem("accessToken", JSON.stringify(this.token));
        //start script using local storage as priority
        this.$store.dispatch("start");
        this.$store.dispatch("refreshData");
      } else {
        console.error("Insira uma combinação token + screenID válidas");
        this.$store.dispatch("showError", "Insira uma combinação token + screen key válidas");
      }
    },
    //END For broadcast screen
    saveServices: function() {
      let servicesIDs = [];
      for (let i = 0; i < this.dispenserServices.length; i++) {
        if (this.dispenserServices[i].selected) {
          servicesIDs.push(this.dispenserServices[i].id);
        }
      }
      if (servicesIDs.length > 5) {
        console.error("Não pode seleccionar mais do que 5 serviços");
        this.$store.dispatch("showError", "Não pode seleccionar mais do que 5 serviços");
      } else if (servicesIDs.length > 0) {
        this.$store.dispatch("saveServices", servicesIDs);
        // get info!
        this.$store.dispatch("start");
      } else {
        console.error("Tem de seleccionar pelo menos um serviço");
        this.$store.dispatch("showError", "Tem de seleccionar pelo menos um serviço");
      }
    },
    selectService: function(i) {
      if (this.dispenserServices[i]["selected"]) {
        this.dispenserServices[i]["selected"] = false;
      } else {
        this.dispenserServices[i]["selected"] = true;
      }
    },
    getServicesData: function() {
      this.loading = true;
      axios
        .get(config.qFlowHost + "/1.0/get/services.json", {
          headers: {
            Accept: "application/json, text/plain, */*",
          },
        })
        .then((result) => {
          let data = result.data;
          for (let i = 0; i < data.length; i++) {
            if (this.selectedServicesIDS.includes(data[i]["id"])) {
              data[i]["selected"] = true;
            } else {
              data[i]["selected"] = false;
            }
          }
          this.dispenserServices = data;
        });
    },
    resetDevice: function() {
      this.$store.dispatch("resetDevice");
      this.page = "device_type";
    },
    changeMode(mode) {
      this.$store.dispatch("changeDeviceMode", mode);
    },
    setDeviceType() {
      if (this.mode === "screen" || this.mode === "dispenser") {
        this.page = "activate";
      } else if (this.mode === "broadcast") {
        this.page = "broadcast_screen_settings";
      } else {
        console.error("Seleccione o tipo de dispositivo que pretende configurar");
        this.$store.dispatch("showError", "Escolha o tipo de dispositivo");
      }
    },
    addService: function() {
      if (this.mode === "screen" || this.mode === "dispenser") {
        if (
          (this.mode === "screen" && this.apikeys.length === 5) ||
          (this.mode === "dispenser" && this.apikeys.length === 1)
        ) {
          if (this.mode === "dispenser") {
            console.error("Número máximo de serviços (1) atingido.");
            this.$store.dispatch("showError", "Numéro máximo de serviços atingido (1). Não é possível adicionar mais.");
          } else {
            console.error("Número máximo de serviços (5) atingido.");
            this.$store.dispatch("showError", "Numéro máximo de serviços atingido (3). Não é possível adicionar mais.");
          }
        } else {
          if (this.activation_code.length > 4) {
            this.$store.dispatch("addService", this.activation_code);
            if (this.mode === "dispenser") {
              this.page = "select_services";
              this.getServicesData();
            }
          } else {
            console.error("O código de ativação tem de ser superior a 4 caracteres");
            this.$store.dispatch("showError", "Código de ativação muito curto");
          }
        }
      } else {
        console.error("Escolha um tipo de dispositivo a ativar");
        this.$store.dispatch("showError", "Escolha um tipo de dispositivo");
      }
    },
    _keyListener: function(e) {
      if (e.which === 37) {
        if (this.page === "device_type") {
          e.preventDefault();
          //TODO:
          //this.changeMode('screen');
        }
      } else if (e.which === 40) {
        //arrow down
        if (this.page === "select_services") {
          if (this.serviceSelectedKey < this.dispenserServices.length - 1) {
            this.serviceSelectedKey++;
          }
        }
      } else if (e.which === 38) {
        //arrow up
        if (this.page === "select_services") {
          if (this.serviceSelectedKey > 0) {
            this.serviceSelectedKey--;
          }
        }
      } else if (e.which === 32) {
        //space
        if (this.page === "select_services") {
          if (this.serviceSelectedKey >= 0 && this.serviceSelectedKey < this.dispenserServices.length) {
            this.selectService(this.serviceSelectedKey);
          }
        }
      } else if (e.which === 39) {
        if (this.page === "device_type") {
          e.preventDefault();
          //TODO:
          //this.changeMode('dispenser');
        }
      } else if (e.which === 13) {
        //enter
        e.preventDefault();
        if (this.page === "device_type") {
          this.setDeviceType();
        } else if (this.page === "select_services") {
          this.saveServices();
        } else {
          this.addService();
        }
      }
    },
  },
};
</script>

<style scoped>
.serviceSelection {
  text-align: left;
  line-height: 5.3vh;
  height: 5.3vh;
  margin: 1vh 2vh;
  position: relative;
  padding-left: 5.5vh;
  font-weight: bold;
  font-size: 2.5vh;
  text-wrap: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.serviceSelection .tick {
  position: absolute;
  height: 2.28vh;
  width: 2.28vh;
  border-radius: 100%;
  background-color: #212121;
  top: 50%;
  margin-top: -1.14vh;
  left: 2vh;
  border: #ffffff solid 0.4vh;
}

.serviceSelection.keyselected {
  text-align: left;
  line-height: 5.3vh;
  height: 5.3vh;
  margin: 1vh 2vh;
  position: relative;
  padding-left: 5.5vh;
  background-color: white;
  color: #212121;
}

.serviceSelection.selected .tick {
  position: absolute;
  height: 2.28vh;
  width: 2.28vh;
  border-radius: 100%;
  background-color: #ffffff;
  top: 50%;
  margin-top: -1.14vh;
  left: 2vh;
  border: #ffffff solid 0.4vh;
}

.serviceSelection.keyselected .tick {
  position: absolute;
  height: 2.28vh;
  width: 2.28vh;
  border-radius: 100%;
  background-color: #212121;
  top: 50%;
  margin-top: -1.14vh;
  left: 2vh;
  border: #212121 solid 0.4vh;
}
.serviceSelection.keyselected.selected .tick {
  position: absolute;
  height: 2.28vh;
  width: 2.28vh;
  border-radius: 100%;
  background-color: #ffffff;
  top: 50%;
  margin-top: -1.14vh;
  left: 2vh;
  border: #212121 solid 0.4vh;
}
.configuration-menu .input {
  border: white 0.5vh solid;
  padding: 1vh 1.5vh 1vh 1.5vh;
  font-weight: 700;
  font-size: 4.2vh;
  line-height: 5.2vh;
  color: #212121;
  text-align: center;
}
.configuration-menu .option-mode {
  border: white 0.5vh solid;
  padding: 1vh 1.5vh 1vh 1.5vh;
  cursor: pointer;
  font-weight: 700;
  font-size: 4.2vh;
  line-height: 5.2vh;
  display: inline-block;
  width: auto;
  margin-bottom: 1.5vh;
}

.configuration-menu .option-mode.left-option {
  border-right: none;
}
.configuration-menu .option-mode.selected {
  background: white;
  color: #212121;
}

.configuration-menu .option-mode:hover {
  background: white;
  color: #212121;
}

.configuration-menu .title {
  color: #ffffff;
  margin-top: 10vh;
  margin-bottom: 2vh;
  font-weight: 700;
  font-size: 2.8vh;
  line-height: 5.2vh;
  padding-left: 4vh;
  padding-right: 4vh;
}

.configuration-menu .actionText {
  text-transform: uppercase;
  color: #ffffff;
  font-size: 2vh;
  line-height: 2vh;
  font-weight: 700;
  margin-bottom: 2vh;
}
.configuration-menu .option {
  margin: 0.8vh;
  padding: 1vh 1.5vh 1vh 1.5vh;
  border: transparent 0.5vh solid;
  cursor: pointer;
  font-weight: 700;
  font-size: 4.2vh;
  line-height: 5.2vh;
  display: inline-block;
}
.configuration-menu .option:hover {
  border: white 0.5vh solid;
}

.configuration-menu .option.red-option {
  border: crimson 0.5vh solid;
  margin-top: 3vh;
  padding: 0.5vh 1vh 0.5vh 1vh;
  font-size: 3vh;
}
.configuration-menu .option.red-option:hover {
  border: crimson 0.5vh solid;
  background-color: crimson;
}

.configuration-menu .option-selector {
  margin-top: 0vh;
  margin-bottom: 3vh;
}

.qrcode-interactive {
  background-color: #212121;
  border-radius: 1vh;
  height: 8vh;
  width: 8vh;
  padding: 0.5vh;
  position: absolute;
  bottom: 3vh;
  right: 3vh;
}
.footer {
  position: absolute;
  bottom: 3vh;
  height: 7vh;
  width: 80%;
  padding-left: 3vh;
  font-weight: 700;
  font-size: 2vh;
  color: #ffffff;
}
/*
.serviceSelection {
  text-align: left;
  line-height: 5.3vh;
  height: 5.3vh;
  margin: 1vh 2vh;
  position: relative;
  padding-left: 5.5vh;
  font-weight: bold;
  text-wrap: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.serviceSelection .tick {
  position: absolute;
  height: 2.28vh;
  width: 2.28vh;
  border-radius: 100%;
  background-color: #212121;
  top: 50%;
  margin-top: -1.14vh;
  left: 2vh;
  border: #ffffff solid 0.4vh;
}

.serviceSelection.keyselected {
  text-align: left;
  line-height: 5.3vh;
  height: 5.3vh;
  margin: 1vh 2vh;
  position: relative;
  padding-left: 5.5vh;
  background-color: white;
  color: #212121;
}

.serviceSelection.selected .tick {
  position: absolute;
  height: 2.28vh;
  width: 2.28vh;
  border-radius: 100%;
  background-color: #ffffff;
  top: 50%;
  margin-top: -1.14vh;
  left: 2vh;
  border: #ffffff solid 0.4vh;
}

.serviceSelection.keyselected .tick {
  position: absolute;
  height: 2.28vh;
  width: 2.28vh;
  border-radius: 100%;
  background-color: #212121;
  top: 50%;
  margin-top: -1.14vh;
  left: 2vh;
  border: #212121 solid 0.4vh;
}
.serviceSelection.keyselected.selected .tick {
  position: absolute;
  height: 2.28vh;
  width: 2.28vh;
  border-radius: 100%;
  background-color: #ffffff;
  top: 50%;
  margin-top: -1.14vh;
  left: 2vh;
  border: #212121 solid 0.4vh;
}
.settings-screen .input {
  border: white 0.5vh solid;
  padding: 1vh 1.5vh 1vh 1.5vh;
  font-weight: 700;
  font-size: 4.2vh;
  line-height: 5.2vh;
  color: #212121;
  text-align: center;
}
.settings-screen .option-mode {
  border: white 0.5vh solid;
  padding: 1vh 1.5vh 1vh 1.5vh;
  cursor: pointer;
  font-weight: 700;
  font-size: 4.2vh;
  line-height: 5.2vh;
  display: inline-block;
  width: auto;
}

.settings-screen .option-mode.left-option {
  border-right: none;
}
.settings-screen .option-mode.selected {
  background: white;
  color: #212121;
}

.settings-screen .option-mode:hover {
  background: white;
  color: #212121;
}

.settings-screen .title {
  color: #ffffff;
  margin-top: 10vh;
  margin-bottom: 2vh;
  font-weight: 700;
  font-size: 2.8vh;
  line-height: 5.2vh;
  padding-left: 4vh;
  padding-right: 4vh;
}

.settings-screen .actionText {
  text-transform: uppercase;
  color: #ffffff;
  font-size: 2vh;
  line-height: 2vh;
  font-weight: 700;
  margin-bottom: 2vh;
}
.settings-screen .option {
  margin: 0.8vh;
  padding: 1vh 1.5vh 1vh 1.5vh;
  border: transparent 0.5vh solid;
  cursor: pointer;
  font-weight: 700;
  font-size: 4.2vh;
  line-height: 5.2vh;
  display: inline-block;
}
.settings-screen .option:hover {
  border: white 0.5vh solid;
}

.settings-screen .option.red-option {
  border: crimson 0.5vh solid;
  margin-top: 3vh;
  padding: 0.5vh 1vh 0.5vh 1vh;
  font-size: 3vh;
}
.settings-screen .option.red-option:hover {
  border: crimson 0.5vh solid;
  background-color: crimson;

}

.settings-screen .option-selector {
  margin-top: 0.0vh;
  margin-bottom: 3vh;
}

.qrcode-interactive {
  background-color: #212121;
  border-radius: 1vh;
  height: 8vh;
  width: 8vh;
  padding: 0.5vh;
  position: absolute;
  bottom: 3vh;
  right: 3vh;
}
.footer {
  position: absolute;
  bottom: 3vh;
  height: 7vh;
  width: 80%;
  padding-left: 3vh;
  font-weight: 700;
  font-size: 2vh;
  color: #ffffff;
}
 */
</style>
