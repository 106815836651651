/***************************************************
 *
 * API Class
 * Backend connectivity
 *
 ***************************************************/

import axios from "axios";

export default class UCMotionScreenApi {
  constructor(qFlowHost, ucPagesHost) {
    this.qFlowHost = qFlowHost;
    this.ucPagesHost = ucPagesHost;
    this.endpoints = {
      listServices: this.qFlowHost + "/1.0/get/services.json",
      updateService: this.qFlowHost + "/1.0/get/queues.json",
      //TODO: REVER SISTEMA DE POOLING!!! ?preview=true
      infoService: this.qFlowHost + "/1.0/info/service.json?preview=true", //TODO: REVER SISTEMA DE POOLING!!! ?preview=true
      forceInfoService: this.qFlowHost + "/1.0/info/service.json?preview=true",
      activateQueueScreen: this.qFlowHost + "/1.0/activate/display.json",
      activateDispenser: this.qFlowHost + "/1.0/activate/dispenser.json",
      screenInfo: this.qFlowHost + "/1.0/get/display.json",
      newTicket: this.qFlowHost + "/1.0/add/call.json",
      printerStatus: this.qFlowHost + "/1.0/update/dispenser/printer/state.json",
      printerPaperStatus: this.qFlowHost + "/1.0/update/dispenser/printer/paper.json",
      printerIp: this.qFlowHost + "/1.0/get/dispenser/printer/ip.json",
      broadcastPlaylist: this.ucPagesHost + "api/get-screen",
    };
  }

  _activateDevice(deviceType = "dispenser", activationCode) {
    return new Promise((resolve, reject) => {
      if (typeof activationCode === "string" && activationCode.length > 1) {
        let bodyFormData = new FormData();
        bodyFormData.append("activationCode", activationCode);
        let url = this.endpoints.activateQueueScreen;
        if (deviceType === "dispenser") {
          url = this.endpoints.activateDispenser;
        }
        axios.post(url, bodyFormData).then((response) => {
          if (response.data && typeof response.data.apiKey === "string" && response.data.apiKey.length > 0) {
            resolve(response.data.apiKey);
          } else {
            console.error(response.data);
            reject("UNEXPECTED_SERVER_RESPONSE");
          }
        });
      } else {
        reject("ACTIVATION_CODE_INVALID");
      }
    });
  }

  activateDispenser(activationCode) {
    return this._activateDevice("dispenser", activationCode);
  }

  activateQueueScreen(activationCode) {
    return this._activateDevice("queueScreen", activationCode);
  }

  getServicesList() {
    return axios.get(this.endpoints.listServices, {
      headers: {
        Accept: "application/json, text/plain, */*",
      },
    });
  }

  getScreenService(apiKey) {
    return axios.get(this.endpoints.forceInfoService, {
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: apiKey, //"apikey " + apiKey
      },
    });
  }

  getScreenInfo(apiKey) {
    return axios.get(this.endpoints.screenInfo, {
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: apiKey, //"apikey " + apiKey
      },
    });
  }

  getServiceInfo(serviceID) {
    return axios.get(this.endpoints.forceInfoService + "&id=" + serviceID, {
      headers: {
        Accept: "application/json, text/plain, */*",
      },
    });
  }

  getScreenServiceInfo(apiKey) {
    return axios.get(this.endpoints.infoService, {
      //timeout: 5500,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: apiKey, //"apikey " + apiKey,
      },
    });
  }

  getPrinterIp(apiKey) {
    return axios.post(this.endpoints.printerIp, null, {
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: apiKey, //"apikey " + apiKey
      },
    });
  }

  getTicket(apiKey, queueID, phoneNumber = null, language = "pt") {
    return new Promise((resolve, reject) => {
      if (typeof apiKey === "string" && apiKey.length > 1) {
        /* let bodyFormData = new FormData();
                bodyFormData.append('idQueue', queueID);

                if(phoneNumber && phoneNumber!==null && typeof phoneNumber !== 'undefined') {
                    bodyFormData.append('contactNr', phoneNumber);
                } */

        let body = {
          idQueue: queueID,
          language: language,
        };

        if (phoneNumber !== null) {
          body["contactNr"] = phoneNumber;
        }

        axios
          .post(this.endpoints.newTicket, body, {
            headers: {
              Accept: "application/json, text/plain, */*",
              Authorization: apiKey, //"apikey " + apiKey,
            },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      } else {
        reject("APIKEY_INVALID");
      }
    });
  }

  getBroadcastPlaylist(screenID, token) {
    axios.defaults.headers.common["Authorization"] = token;
    return axios.get(this.endpoints.broadcastPlaylist + "?key=" + screenID);
  }

  setPrinterPapersStatus(apiKey, lowPaperWarning) {
    return new Promise((resolve, reject) => {
      let bodyFormData = new FormData();
      bodyFormData.append("status", lowPaperWarning ? "true" : "false");
      axios
        .post(this.endpoints.printerPaperStatus, bodyFormData, {
          headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: apiKey, //"apikey " + apiKey,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  setPrinterStatus(apiKey, errorCode) {
    if (errorCode === "OK" || errorCode === "ok") {
      errorCode = "NO_PROBLEM";
    }
    if (
      errorCode !== "NO_PROBLEM" &&
      errorCode !== "EPTR_REC_EMPTY" &&
      errorCode !== "EPTR_COVER_OPEN" &&
      errorCode !== "NO_CONNECTION"
    ) {
      //We assume there is an unkown error with the printer, so we change its status to NO_CONNECTION
      errorCode = "NO_CONNECTION";
    }

    return new Promise((resolve, reject) => {
      let bodyFormData = new FormData();
      bodyFormData.append("errorState", errorCode);
      axios
        .post(this.endpoints.printerStatus, bodyFormData, {
          headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: apiKey, //"apikey " + apiKey,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }
}

/*



    function getPrinterIp(){
      return $http({
        method: 'POST',
        url: Config.apiAddress.printerIp,
        headers: {'Authorization': 'apikey ' + Config.apiKey}});
    }

 */
