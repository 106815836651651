<template>
  <div id="marketing" class="marketing-screen bg-ucframework relative h-screen w-screen">
    <!-- Full Screen Media -->
    <transition name="fade" mode="out-in">
      <div class="bg-image" v-if="image!=null && showDetails==false" :style="{backgroundImage: 'url('+image+')'}"></div>
    </transition>
    <transition name="fade" mode="out-in">
      <VideoPlayer :key="video" v-if="video!=null && showDetails==false" :thumbnail-url="image" :video-url="video" v-on:videoended="videoEnded"></VideoPlayer>
    </transition>
    <!-- Full Screen Details Text -->
    <div class="flex flex-col h-screen w-screen justify-center text-center text-white">
      <div class="title free-text">
        {{title}}
      </div>
      <div class="subtitle free-text">
        {{subtitle}}
      </div>
      <div class="description" v-if="QRcodeUrl!==null && QRcodeUrl!==''">Saiba mais aqui</div>
      <div class="qrcode-marketing" v-if="QRcodeUrl!==null && QRcodeUrl!==''">
        <qr-code :url="QRcodeUrl" :color="'#ffffff'" :bg-color="'#212121'" :level="'M'" ></qr-code>
        <!-- <qrcode-vue :value="QRcodeUrl" :background="'#212121'" :foreground="'#ffffff'" :size="qrCodeSize" :level="'M'" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import VideoPlayer from "/src/components/VideoPlayer.vue"
import QRcode from "@/components/QRcode";
//import QrcodeVue from "qrcode.vue";

export default {
  name: "Marketing",
  data: function () {
    return {
      showDetails: false,

      //Timer with 10 minutes used to go to next slide in the event of software failure
      //backupNextSlideTimer: null,

      timers: []
    }
  },
  computed: {
    qrCodeSize: function() {
      let size = parseInt(localStorage.getItem('qrcode_size_marketing'));
      if(size > 0) {
        return size;
      }
      return 50;
    },
    videoWidth: function() {
      return window.innerWidth;
    },
    videoHeight: function() {
      return window.innerHeight;
    },
    screenData: function () {
      return this.$store.state.currentScreenData;
    },
    QRcodeUrl:function () {
      return typeof this.screenData['QRcodeUrl'] != 'undefined' && this.screenData['QRcodeUrl'] != '' ? this.screenData['QRcodeUrl'] : null;
    },
    QRcodeDescription: function () {
      return this.screenData['QRcodeDescription'];
    },
    title: function () {
      return this.screenData['title'];
    },
    subtitle: function () {
      return this.screenData['subtitle'];
    },
    mustShowDetails() {
      return this.title != null && this.title != '' && this.subtitle != null && this.subtitle != '';
    },
    video: function () {
      if(this.screenData['video']!='' && typeof this.screenData['video'] != 'undefined') {
        return this.screenData['video'];
      } else {
        return null;
      }
    },
    image: function () {
      if(this.screenData['image']!='' && typeof this.screenData['image'] != 'undefined') {
        return this.screenData['image'];
      } else {
        return null;
      }
    },
    slideSeconds: function () {
      return this.$store.state.carouselSeconds;
    }
  },
  methods: {
    videoEnded() {
      let self = this;
      let time = this.slideSeconds * 1000;
      if(this.video !== null && this.mustShowDetails) {
        self.showDetails = true;
      }
      //start timer to change slide
      //console.log("VIDEO TERMINOU");
      this.timers.push(setTimeout(function () {
        //console.log("VIDEO TERMINOU -> next slide!");
        self.$store.dispatch('nextSlide')
      }, time));
    }
  },
  beforeDestroy: function () {
    /*if(this.backupNextSlideTimer!==null) {
      clearTimeout(this.backupNextSlideTimer);
    }*/

    //REMOVE ALL TIMERS
    //console.log("REMOVING ALL TIMERS");
    for (let i = 0; i < this.timers.length; i++) {
      if(this.timers[i]!==null) {
        clearTimeout(this.timers[i]);
      }
    }
  },
  mounted() {
    let self = this;
    let time = this.slideSeconds * 1000;
    if(this.video === null) {
      //console.log("TIMER SEM VIDEO");
      //Timer to pass to next slide
      this.timers.push(setTimeout(function () {
        //console.log("TIMER SEM VIDEO --> next");
        //self.$store.dispatch('nextSlide')
        self.videoEnded();
      }, time));
    }

    //BACKUP NEXT SLIDE TIMER (10 minutes):
    ///this.backupNextSlideTimer =
    this.timers.push(setTimeout(function(){
      console.log("Software Error - BACKUP next slide working....");
      self.$store.dispatch('nextSlide');
    }, 600000));

  },
  components: {
    'qr-code': QRcode,
    //QrcodeVue,
    VideoPlayer},
}
</script>

<style scoped>
.marketing-screen .bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-filter: brightness(90%); /* Safari 6.0 - 9.0 */
  filter: brightness(90%);
  background-color: #212121;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.qrcode-marketing {
  margin-top: 2vh;
  background-color: #212121;
  width: 20vw;
  height: 20vw;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.qrcode-marketing div {
  display: inline-block;
}
.marketing-screen .title {
  color: #ffffff;
  margin-bottom: 6vh;
  font-weight: 700;
  font-size: 4.3vh;
  line-height: 5.2vh;
  padding-left: 4vh;
  padding-right: 4vh;
}
.marketing-screen .subtitle {
  color: #ffffff;
  margin-bottom: 0.3vh;
  font-weight: 700;
  font-size: 2.4vh;
  line-height: 3.5vh;
  padding-left: 4vh;
  padding-right: 4vh;
}
.marketing-screen .description {
  color: #ffffff;
  margin-bottom: 2.3vh;
  margin-top: 12vh;
  font-weight: 700;
  font-size: 1.8vh;
  line-height: 2.2vh;
  padding-left: 10vh;
  padding-right: 10vh;
}
</style>