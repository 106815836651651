<template>
  <div
    id="QueueTicket"
    class="ticket"
    :class="{
      selected: selected,
      'small-ticket': size == 'small',
      'big-ticket': size == 'big',
      'medium-ticket': size == 'medium',
    }"
  >
    <div class="queueName" v-if="size == 'small'">
      <span>{{ translation["QUEUE"][language] }} {{ queueName }}</span>
    </div>
    <div class="main flex flex-col justify-center">
      <div v-if="isNewTicket" class="ticketTitle">{{ translation["NEW_TICKET"][language] }}</div>
      <div v-else class="ticketTitle">{{ translation["TICKET"][language] }}</div>
      <div class="ticketNumber" :class="{ md: ticketNumber.length > 2, sm: ticketNumber.length > 3 }">
        {{ ticketNumber }}
      </div>
      <div class="goTo" v-if="size == 'big'">{{ translation["GO_TO"][language] }}</div>
      <div class="ticketPost" v-if="post && selected">{{ translation["POST"][language] }} {{ post }}</div>
    </div>
    <div class="time">
      <span v-if="post && size !== 'big'">{{ translation["POST"][language] }} {{ post }}</span
      ><span v-else>&nbsp;</span>
    </div>
  </div>
</template>

<script>
import translation from "@/config/translation";

export default {
  name: "QueueTicket",
  props: [
    "size",
    "selected",
    "ticketNumber",
    "time",
    "post",
    "queuePos",
    "previous",
    "currentTicketNumber",
    "language",
    "isNewTicket",
    "queueName",
  ],
  computed: {
    translation: function() {
      return translation["QUEUES_SCREEN"];
    },
    parsedTicketNumber: function() {
      if (typeof this.ticketNumber === "undefined") {
        return "00";
      } else {
        let numberString = this.ticketNumber.toString();
        if (numberString.length === 1) {
          numberString = "0" + numberString;
        }
        return numberString;
      }
    },
    // get only
    /*tkParts: function () {
      if(typeof this.ticketNumber != 'undefined') {
        return this.ticketNumber.split(':');
      } else {
        return ['']
      }
    },*/
    status: function() {
      if (this.selected) {
        return this.translation["STATUS_ONGOING"][this.language];
      } else if (typeof this.previous == "undefined") {
        return this.translation["STATUS_NEXT"][this.language];
      } else if (typeof this.previous != "undefined" && this.previous["ticketNumber"] == this.currentTicketNumber) {
        return this.translation["STATUS_NEXT"][this.language];
      } else if (typeof this.time != "undefined") {
        return this.translation["STATUS_SCHEDULE"][this.language];
      } else {
        return this.translation["STATUS_WAITING"][this.language];
      }
    },
  },
};
</script>

<style scoped>
.medium-ticket {
  width: 19vh;
  margin-right: auto;
  margin-left: auto;
}
.medium-ticket .main {
  height: 19vh;
  width: 19vh;
}
.medium-ticket .main .ticketTitle {
  font-weight: 700; /* Bold */
  font-size: 2.1vh;
  line-height: 3.5vh;
  margin-bottom: 0.2vh;
}
.medium-ticket .main .ticketNumber {
  font-weight: 700; /* Black */
  font-size: 8.5vh;
  line-height: 8.5vh;
}

.big-ticket {
  width: 25.4vh;
  margin-right: auto;
  margin-left: auto;
}
.big-ticket .main {
  height: 25.4vh;
  width: 25.4vh;
}

.big-ticket .main .ticketTitle {
  font-weight: 700; /* Bold */
  font-size: 2.7vh;
  line-height: 3.5vh;
}
.big-ticket .main .ticketNumber {
  font-weight: 700; /* Black */
  font-size: 8.5vh;
  line-height: 8.5vh;
}

.medium-ticket .main .ticketNumber.md {
  font-weight: 700; /* Black */
  font-size: 7vh;
  line-height: 8vh;
}

.medium-ticket .main .ticketNumber.sm {
  font-weight: 700; /* Black */
  font-size: 5.5vh;
  line-height: 6.5vh;
}

.big-ticket .main .ticketNumber span {
  font-weight: 700; /* Black */
  font-size: 2.7vh;
}
.big-ticket .main .goTo {
  font-weight: 900; /* Black */
  font-size: 2.7vh;
  line-height: 2.7vh;
}
.big-ticket .main .ticketPost {
  font-weight: 900; /* Black */
  font-size: 3.5vh;
  line-height: 4vh;
}

.small-ticket {
  width: 10vh;
  margin-right: 2vh;
}
.small-ticket .main {
  height: 10vh;
  width: 10vh;
}
.ticket {
  text-align: center;
  color: #ffffff;
}
.ticket.selected {
  font-weight: 700;
}

.ticket .main {
  border: #ffffff solid 0.5vh;
  background-color: #212121;
  padding: 10px;
}

.ticket.selected .main {
  color: #000000;
  border: #ffffff solid 0.5vh;
  background-color: #ffffff;
}

.small-ticket .main .ticketTitle {
  font-weight: 700; /* Bold */
  font-size: 1.7vh;
  line-height: 1.9vh;
}
.small-ticket .main .ticketNumber {
  font-weight: 700; /* Black */
  font-size: 3.6vh;
  line-height: 3.6vh;
}
.small-ticket .main .ticketNumber span {
  font-weight: 700; /* Black */
  font-size: 2.7vh;
}
.small-ticket .main .ticketPost {
  font-weight: 900; /* Black */
  font-size: 2vh;
  line-height: 2vh;
}

.ticket.selected .main .ticketNumber {
  font-weight: 900; /* Black */
}

.ticket.selected .main .ticketNumber span {
  font-weight: 900; /* Black */
}
.time {
  height: 2.2vh;
  font-size: 2vh;
  line-height: 2.2vh;
  margin-top: 1.1vh;
}
.big-ticket .queueName {
  height: 2.2vh;
  font-size: 2vh;
  line-height: 2.2vh;
  margin-top: 1.1vh;
  font-weight: bold;
  margin-bottom: 2.1vh;
}
.small-ticket .queueName {
  height: 2.2vh;
  font-size: 2vh;
  line-height: 2.2vh;
  margin-top: 1.1vh;
  font-weight: bold;
  margin-bottom: 1.1vh;
}
.status {
  margin-top: 0.6vh;
  height: 1.5vh;
  font-size: 1.5vh;
  line-height: 1.5vh;
}
</style>
