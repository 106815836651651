<template>
  <div id="divulgation-agenda" :class="{'agenda_today': screenData.type == 'agenda_today' }" class="divulgation-agenda-screen bg-ucframework relative h-screen w-screen flex flex-col">

    <!-- Hero Image/video -->
    <VideoPlayer v-if="video!==null" :thumbnail-url="heroImage" :key="video" :video-url="video" v-on:videoended="videoEnded"></VideoPlayer>
    <div v-else-if="heroImage!==null" class="w-screen heroimage flex-1" :style="{backgroundImage: 'url('+heroImage+')', height: heroHeigth + 'px'}"></div>
    <div v-else class="flex-1"></div>


    <div v-if="events!=null" ref="eventsbox" class="outside-events-box" :class="{'dense' :events.length>2}">
      <!-- Agenda - events list -->
      <div class="flex evento" v-for="(event, evt) in events" :key="'event_'+evt" :class="{'main':evt==0}">
        <div class="date-box">
          <p class="date" v-html="dateProcessor(event.date)"></p>
          <transition name="fade" mode="out-in">
            <div class="qrcode-event z-20" v-if="event.QRcodeUrl!=null && event.QRcodeUrl!=''">
              <qr-code :url="event.QRcodeUrl" :color="'#ffffff'" :bg-color="'#212121'" :level="'L'" ></qr-code>
              <!-- <qrcode-vue :value="event.QRcodeUrl" background="#212121" foreground="#ffffff" :size="QRcodeSizeEvent" level="L" /> -->
            </div>
          </transition>
        </div>
        <div>
          <p class="title free-text">
            {{event.title}}
          </p>

          <div class="place free-text" v-if="event.place!==''">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="destaque-icon svg-inline--fa fa-map-marker-alt"><path fill="currentColor" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z" class=""></path></svg> {{event.place}}</div>
          <div class="place free-text" v-if="event.place==='' && event.QRcodeUrl!== ''">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-square-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="destaque-icon small svg-inline--fa fa-external-link-square-alt"><path fill="currentColor" d="M448 80v352c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h352c26.51 0 48 21.49 48 48zm-88 16H248.029c-21.313 0-32.08 25.861-16.971 40.971l31.984 31.987L67.515 364.485c-4.686 4.686-4.686 12.284 0 16.971l31.029 31.029c4.687 4.686 12.285 4.686 16.971 0l195.526-195.526 31.988 31.991C358.058 263.977 384 253.425 384 231.979V120c0-13.255-10.745-24-24-24z" class=""></path></svg>
            {{event.QRcodeUrl | removeHTTPurl}}
          </div>
        </div>
      </div>
    </div>
    <div v-else ref="eventsbox2">
      <!-- Agenda - Highlights -->
      <div class="mega-title" v-if="isToday">Hoje na UC</div>
      <div class="mega-title" v-else>Não perca na UC</div>
      <div class="flex evento main live">
        <div class="date-box">
          <p class="date"  v-if="isToday === false && date" v-html="dateProcessor(date)"></p>
          <p class="date" v-else>{{time}}</p>
          <transition name="fade" mode="out-in">
            <div class="qrcode-event z-20" v-if="QRcodeUrl!=null && QRcodeUrl!=''">
              <qr-code :url="QRcodeUrl" :color="'#ffffff'" :bg-color="'#212121'" :level="'L'" ></qr-code>
            </div>
          </transition>
        </div>
        <div>
          <p class="title free-text">
            {{title}}
          </p>
            <div class="place free-text" v-if="place!==''">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="destaque-icon svg-inline--fa fa-map-marker-alt"><path fill="currentColor" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z" class=""></path></svg> {{place}}
            </div>
            <div class="place free-text" v-if="place==='' && QRcodeUrl!== null">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-square-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="destaque-icon small svg-inline--fa fa-external-link-square-alt"><path fill="currentColor" d="M448 80v352c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h352c26.51 0 48 21.49 48 48zm-88 16H248.029c-21.313 0-32.08 25.861-16.971 40.971l31.984 31.987L67.515 364.485c-4.686 4.686-4.686 12.284 0 16.971l31.029 31.029c4.687 4.686 12.285 4.686 16.971 0l195.526-195.526 31.988 31.991C358.058 263.977 384 253.425 384 231.979V120c0-13.255-10.745-24-24-24z" class=""></path></svg>
              {{QRcodeUrl | removeHTTPurl}}
            </div>
            <div v-if="place==='' && QRcodeUrl!== null && isToday" class="place live">dentro de {{ countdownText }}</div>
          </div>
      </div>
    </div>

    <!-- Footer - Agenda -->
    <div class="footerBox" v-if="screenData.type == 'agenda'">
      <transition name="fade" mode="out-in">
        <div class="footer flex flex-col justify-center z-20" v-if="footerText && screenData.type == 'agenda'">
          <div>{{footerText}}</div>
        </div>
      </transition>
      <transition name="fade" mode="out-in">
        <div class="qrcode-footer z-20" v-if="QRcodeUrl!=null && screenData.type == 'agenda'">
          <qr-code :url="QRcodeUrl" :color="'#ffffff'" :bg-color="'#212121'" :level="'L'" ></qr-code>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

import VideoPlayer from "@/components/VideoPlayer.vue"
import QRcode from "@/components/QRcode.vue";

export default {
  name: "DivulgationAgenda",
  data: function () {
    return {
      currentEventIndex: 0,
      countdownText: '',
      timer: null,
      heroHeigth: 100,

      //Timer with 10 minutes used to go to next slide in the event of software failure
      //backupNextSlideTimer: null,

      timers: []
    }
  },
  filters: {
    removeHTTPurl: function (value) {
      if (!value) return ''
      value = value.toString().toLowerCase()
      value = value.replace("https://", "");
      value = value.replace("http://", "");
      value = value.replace("www.", "");
      return value;
    }
  },
  methods: {
    videoEnded: function () {
      this.$store.dispatch('nextSlide');
    },
    dateProcessor: function (value) {
      if (!value) return ''
      value = value.toString()
      let parts = value.split('-');
      let mes = parseInt(parts[1]);
      let meses = ['Jan','Fev','Mar','Abr','Mai','Jun','Jul','Ago','Set','Out','Nov','Dez']
      return "Dia "+parts[2]+'<br />'+meses[mes]+" "+parts[0].substring(2,4);
    }
  },
  beforeDestroy: function () {
    /*if(this.backupNextSlideTimer!==null) {
      clearTimeout(this.backupNextSlideTimer);
    }*/

    if(this.timer!=null) {
      clearInterval(this.timer);
    }

    //REMOVE ALL TIMERS
    console.log("REMOVING ALL TIMERS");
    for (let i = 0; i < this.timers.length; i++) {
      if(this.timers[i]!==null) {
        clearTimeout(this.timers[i]);
      }
    }

  },
  mounted: function() {
    //calculate heroImage height
    //console.log(this.$refs)
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
    if(this.screenData.type == 'agenda_today') {
      this.heroHeigth = vh - this.$refs.eventsbox2.clientHeight;
    } else {
      this.heroHeigth = vh - this.$refs.eventsbox.clientHeight;
    }

    if(this.QRcodeUrl!== null && this.isToday) {
      let eventDate = new Date();
      let timeParser = this.time.split(':');

      eventDate.setHours(timeParser[0]);
      eventDate.setMinutes(timeParser[1]);
      let self = this;
      this.timer = setInterval(function () {
        let now = new Date().getTime();
        let diff = eventDate - now;

        let hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((diff % (1000 * 60)) / 1000);

        let result = "";
        if(hours>0) {
          result+=hours + "h ";
        }
        self.countdownText = result + minutes + "m " + seconds + "s";

        // If the count down is finished, write some text
        if (diff < 0) {
          clearInterval(self.timer);
          self.countdownText = "momentos";
        }
      }, 1000);


    }

    let self = this;

    this.timers.push(setTimeout(function(){ self.$store.dispatch('nextSlide') }, self.slideSeconds*1000));

    //BACKUP NEXT SLIDE TIMER (10 minutes):
    //this.backupNextSlideTimer =
        this.timers.push(setTimeout(function(){
      console.log("Software Error - BACKUP next slide working....");
      self.$store.dispatch('nextSlide') }, 600000));

  },
  computed: {
    isToday: function() {
      let today = new Date().toISOString().split('T');
      //console.log("TODAY STRING: "+today[0])
      return this.date === today[0];
    },
    /*QRcodeSizeEvent: function () {
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
      return vh*0.06;
    },*/
    screenData: function () {
      return this.$store.state.currentScreenData;
    },
    heroImage: function () {
      /*if(this.screenData.type == 'agenda_today') {
        return this.screenData['image']
      } else {
        if(this.events!==null && this.events.length>0 && typeof this.events[this.currentEventIndex]['image'] != 'undefined' && this.events[this.currentEventIndex]['image']!="") {
          return this.events[this.currentEventIndex]['image'];
        } else {
          return null;
        }
      }*/
      if(this.screenData['image']!==null) {
        return this.screenData['image'];
      } else {
        return null;
      }
    },
    video: function () {
      /*if(this.screenData.type == 'agenda_today' && typeof this.screenData['video']!=='undefined') {
        return this.screenData['video']
      } else {
        if(this.events!==null && this.events.length>0 && typeof this.events[this.currentEventIndex]['video'] !== 'undefined' && this.events[this.currentEventIndex]['video']!=="" && this.events[this.currentEventIndex]['video']!==null) {
          return this.events[this.currentEventIndex]['video'];
        } else {
          return null;
        }
      }*/
      if(this.screenData['video']!==null) {
        return this.screenData['video'];
      } else {
        return null;
      }
    },
    events: function () {
      return typeof this.screenData.events != 'undefined' ? this.screenData.events : null;
    },
    QRcodeUrl:function () {
      return typeof this.screenData['QRcodeUrl'] != 'undefined' && this.screenData['QRcodeUrl'] != '' ? this.screenData['QRcodeUrl'] : null;
    },
    /*parsedQRcodeUrl:function () {
      if(this.QRcodeUrl!==null) {
        let url = this.QRcodeUrl
        url = url.replace("https://", "");
        url = url.replace("http://", "");
        url = url.replace("www.", "");
        return url;
      } else {
        return ''
      }
    },
    QRcodeSize: function () {
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
      return vh*0.065;
    },*/
    footerText: function () {
      return this.screenData['footerText'];
    },

    //HOJE NA UC
    date:function () {
      return this.screenData['date'];
    },
    time:function () {
      return this.screenData['time'];
    },
    liveStream:function () {
      return this.screenData['liveStream'];
    },
    place:function () {
      if(this.screenData['place'].indexOf('<br>')>=0) {
        let place = this.screenData['place'].split('<br>');
        return place[1];
      } else {
        return this.screenData['place'];
      }

    },
    title:function () {
      return this.screenData['title'];
    },
    slideSeconds: function () {
      return this.$store.state.carouselSeconds;
    }
  },
  components: {
    'qr-code': QRcode,
    //'qrcode-vue':QrcodeVue,
    'VideoPlayer': VideoPlayer},
}
</script>

<style scoped>
.outside-events-box {
  margin-bottom: 6vh;
}
.outside-events-box.dense {
  margin-bottom: 2vh;
}
.evento {
  padding: 4vh 4vh 0vh 4vh;
}
.evento.live {
  padding: 4vh 4vh 4vh 4vh;
}
.heroimage {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-filter: brightness(80%); /* Safari 6.0 - 9.0 */
  filter: brightness(80%);
}
.evento.main .title {
  color: #ffffff;
  margin-bottom: 1.2vh;
  font-weight: 700;
  font-size: 3.5vh;
  line-height: 4vh;
  padding-left: 0vh;
  padding-right: 0vh;
  margin-top: 0vh;
}
.evento .title {
  color: #ffffff;
  margin-top: 0.5vh;
  margin-bottom: 1.6vh;
  font-weight: 700;
  font-size: 2.5vh;
  line-height: 2.5vh;
  padding-left: 0vh;
  padding-right: 0vh;
}
.evento .place {
  color: #ffffff;
  margin-bottom: 1.6vh;
  font-weight: 400;
  font-size: 2.2vh;
  line-height: 3vh;
  word-break: break-word;
  position: relative;
  margin-left: 2.8vh;
}

.destaque-icon {
  height: 2vh;
  margin-right: 0.5vh;
  display: inline-block;
  position: absolute;
  left: -2.4vh;
  top: 0.3vh;
}
.destaque-icon.small {
  height: 2vh;
  margin-right: 0.5vh;
  display: inline-block;
  position: absolute;
  left: -2.4vh;
  top: 0.5vh;
}

.evento .place.live {
  margin-bottom: 0.5vh;
}

.evento .date-box {
  min-width: 10vh;
  max-width: 10vh;
  margin-right: 4vh;
  padding-top: 0.5vh;
  color: #ffffff;
  font-weight: 700;
  font-size: 2.5vh;
  line-height: 2.8vh;
  text-align: center;
}
.qrcode-event {
  margin-top: 2vh;
  background-color: #212121;
  width: 10.2vw;
  margin-left: auto;
  margin-right: auto;
}

.qrcode-event div {
  display: inline-block;
}

.footerBox {
  height: 18vh;
}

.divulgation-agenda-screen .footer {
  left: 1.8vh;
}

.divulgation-agenda-screen .footer {
  left: 0vh;
  bottom: 0px;
  right: 0px;
  position: absolute;
  height: 13.2vh;
  font-weight: 700;
  font-size: 2vh;
  min-height: 2vh;
  color: #ffffff;
  z-index: 15;
}
.divulgation-agenda-screen .footer div {
  margin-left: 5vh;
}
.divulgation-agenda-screen .qrcode-footer {
  padding: 1vh;
}

.mega-title {
  color: #ffffff;
  margin-bottom: 0.8vh;
  font-weight: 700;
  font-size: 4.5vh;
  line-height: 4.5vh;
  padding-top: 4vh;
  padding-left: 4vh;
  padding-right: 0vh;
}

</style>