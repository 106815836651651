<template>
  <div id="interactive-menu" class="interactive-menu bg-ucframework relative h-screen w-screen">


    <div class="h-full flex flex-col h-full absolute top-0 left-0 w-full bg-ucframework-overlay z-10">

      <!-- Header Placeholder -->
      <div class="header-placeholder"></div>

      <!-- Main Content -->
      <div class="flex-1 interactive-content text-center text-white">
        <div class="flex flex-col justify-start h-full">
          <div class="upper-section">
            <div class="title" :class="{'home':navigationPath.length==0, 'main-title': serviceTitle===null||serviceTitle===''}">
              {{title}}
            </div>
            <div v-if="serviceTitle!==null" class="main-title">
              {{serviceTitle}}
            </div>
          </div>
          <div class="down-section flex flex-col justify-center container mx-auto">
            <div class="actionText" v-if="actionText!==''">
              {{actionText}}
            </div>
            <div v-for="(option, o) in options" :key="'option_'+o">
              <div @click="goTo(option.goTo)" class="option inline-block" :class="{'one-option-highlight': options.length === 1, 'md': options.length>=2, 'inactive': option.goTo === 'INACTIVE'}">
                {{option[selectedLanguage]}}
              </div>
            </div>

            <div class="paperless-warning" v-if="navigationPath.length==0 && !isPrinterAvailable">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="mobile" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="mobile-icon svg-inline--fa fa-mobile fa-w-10 fa-3x"><path fill="currentColor" d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z" class=""></path></svg>
              {{paperless_warning}}
            </div>

          </div>
        </div>
      </div>
      <!-- Footer -->
      <div class="footer z-20 container mx-auto" v-if="footerText && footerText!=null">
        <div class="positioner">
          <div class="label" v-if="editingFooter">Editing footer in {{editingLanguage}}</div>
          <div v-if="editingFooter" ref="footerEditor" :contenteditable="editingFooter" class="footerEditor" :class="{'editing':editingFooter}" @input="onChangeFooter">{{footerTextEditing}}</div>
          <div v-else class="footerEditor">{{footerText}}</div>
        </div>

        <div class="qrcode-interactive-menu z-20" v-if="QRcodeUrl!==''">
          <div class="w-full h-full">
            <qr-code :url="QRcodeUrl" :color="'#ffffff'" :bg-color="'#212121'" :level="'L'" ></qr-code>
          </div>
        </div>
      </div>
      <div v-else class="footer-placeholder"></div>
    </div>

    <div class="full-screen-media" v-if="image!=null" :style="{backgroundImage: 'url('+image+')'}"></div>
      <video id="video" ref="video" v-if="video!=null" autoplay muted loop>
        <source :src="video" type="video/mp4">
      </video>
    </div>
</template>

<script>
import QRcode from "@/components/QRcode.vue";
import translation from "@/config/translation";

export default {
  name: "PaginaInteractiva",
  props: ['pageData', 'navigationPath'],
  data: function () {
    return {
      editingFooter: false
    }
  },
  computed: {
    translation() {
      return translation['SERVICES_SCREEN']; //both for get ticket and give feedback
    },
    paperless_warning() {
      return this.translation["PAPERLESS_WARNING"][this.selectedLanguage];
    },
    image() {
      return typeof this.pageData['backgroundImage'] != 'undefined' ? this.pageData['backgroundImage'] : null;
    },
    video() {
      return typeof this.pageData['backgroundVideo'] != 'undefined' ? this.pageData['backgroundVideo'] : null;
    },
    selectedLanguage() {
      return this.$store.state.selectedLanguage
    },
    languagesAvailable() {
      return this.$store.state.languagesAvailable
    },
    title() {
      return this.pageData['title'][this.selectedLanguage];
    },
    serviceTitle() {
      return this.pageData['serviceTitle'] && this.pageData['serviceTitle']['en'] ? this.pageData['serviceTitle'][this.selectedLanguage] : null;
    },
    actionText() {
      return this.pageData['actionText'][this.selectedLanguage];
    },
    options() {
      return this.pageData['options'];
    },
    footerText() {
      return typeof this.pageData['footerText'] !== 'undefined' ? this.pageData['footerText'][this.selectedLanguage] : null;
    },
    QRcodeUrl() {
      return typeof this.pageData['QRcodeUrl'] !== 'undefined' && this.pageData['QRcodeUrl'] != '' ? this.pageData['QRcodeUrl'] : null;
    },
    QRcodeSize() {
      return window.innerHeight*0.07;
    },
    isPrinterAvailable() {
      return this.$store.getters.isPrinterAvailable;
    }
  },
  methods: {
    goTo: function(id) {
      if (id === 'INACTIVE') {
        let message = this.$store.state.selectedLanguage === 'pt' ? 'Senhas desativadas temporariamente' : 'Tickets temporarily disabled'
        this.$store.dispatch('showError', message)
      } else {
        this.$store.dispatch('openInteractiveScreen',[id]);
      }
      //this.$emit('selectedOption', id);
    }
  },
  components: {'qr-code': QRcode},
}
</script>

<style scoped>
.interactive-menu .header-placeholder {
  height: 8vh;
}

.interactive-menu .one-option-highlight {
  border: rgba(255,255,255,0.5) 0.5vh solid !important;
}
.interactive-menu .footer-placeholder {
  height: 12vh;
}
.interactive-menu .title {
  color: #ffffff;
  font-weight: 500;
  font-size: 2.5vh;
  line-height: 3vh;
  padding-left: 4vh;
  padding-right: 4vh;
  margin: 1vh 1vh 0vh 1vh;
}

.interactive-menu .upper-section {
  margin: 6vh 2vh;
  min-height: 10vh;
}
.interactive-menu .title.home {
  color: #ffffff;
  margin-top: 10vh;
  margin-bottom: 9vh;
  font-weight: 700;
  font-size: 4.8vh;
  line-height: 5.2vh;
  padding-left: 4vh;
  padding-right: 4vh;
}

.interactive-menu .actionText {
  text-transform: uppercase;
  color: #ffffff;
  font-size: 2vh;
  line-height: 2vh;
  font-weight: 700;
  margin: 2vh;
}
.interactive-menu .option {
  margin: 0.8vh;
  padding: 1vh 1.5vh 1vh 1.5vh;
  border: transparent 0.5vh solid;
  cursor: pointer;
  font-weight: 700;
  font-size: 3.8vh;
  line-height: 5.2vh;
  display: inline-block;
}
.interactive-menu .option.md {
  font-size: 3.2vh;
  line-height: 4.4vh;
}
.interactive-menu .option.inactive {
  opacity: 0.5;
}
.interactive-menu .option:hover {
  border: white 0.5vh solid;
}

.interactive-menu .main-title {
  color: #ffffff;
  font-weight: 700;
  font-size: 3.2vh;
  margin: 1vh 1vh 0vh 1vh;
  line-height: 3.7vh;
  line-height: 4vh;
  padding-left: 4vh;
  padding-right: 4vh;
}

.interactive-menu .down-section {
  padding-left: 3vh;
  padding-right: 3vh;
}

.qrcode-interactive-menu {
  background-color: #212121;
  border-radius: 0.6vh;
  height: 13vh;
  width: 13vh;
  padding: 0.8vh;
  position: absolute;
  bottom: 2.5vh;
  right: 2.5vh;
}
.footer {
  height: 12.5vh;
  font-weight: 700;
  font-size: 2vh;
  min-height: 2vh;
  color: #ffffff;
}
.footer .positioner {
  position: relative;
  margin-left: 1.8vh;
}
.footer .positioner .label {
  position: absolute;
  height: 3.5vh;
  line-height: 3.5vh;
  top: -3.5vh;
  left: 0.5vh;
  padding-left: 1.5vh;
  padding-right: 1.5vh;
  background-color: cornflowerblue;
  border-top-right-radius: 0.7vh;
  border-top-left-radius: 0.7vh;
  color: rgba(0,0,0,0.6);
}
.footerEditor {
  min-height: 2vh;
  border: transparent solid 0.3vh;
  border-radius: 0.5vh;
  outline: none;
  padding: 1.5vh 0vh;
}
.footerEditor.editing {
  border: cornflowerblue solid 0.3vh;
}
.bg-ucframework-overlay {
  background-color: rgba(33, 33, 33, 0.5);
}
.interactive-menu video {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  z-index: 0;
}

.paperless-warning {
  margin:  2.5vh auto 0vh auto;
  padding: 0.5vh 1vh 0.5vh 1vh;
  border: transparent 0.5vh solid;
  background: white;
  color: #212121;
  font-weight: 600;
  font-size: 2vh;
  line-height: 3.2vh;
  max-width: 48vh;
}
.paperless-warning .mobile-icon {
  height: 2.5vh;
  margin-top: -0.2vh;
  margin-right: 1vh;
  display: inline-block;
}
</style>