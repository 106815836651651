<template>
  <div
    id="QueueTicket2"
    class="ticket"
    :class="{
      selected: selected,
      'small-ticket': size == 'small',
      'big-ticket': size == 'big',
      'medium-ticket': size == 'medium',
      highlight: highlight,
      reduced: reduced,
      'inactive-ticket': inactive,
    }"
  >
    <div class="main flex flex-col justify-center">
      <div
        class="ticketNumber"
        :class="{
          smaller: (parsedTicketNumber.length > 2 && queueName.length <= 2) || queueName.length === 2,
          'extra-smaller': parsedTicketNumber.length > 2 && queueName.length > 1,
        }"
      >
        <span class="queueName" :class="{ 'long-queue-name': queueName.length > 1 }">{{ queueName }}</span
        >{{ parsedTicketNumber }}
      </div>
      <div class="goTo" v-if="size == 'big'">{{ translation["GO_TO"][language] }}</div>
      <div class="ticketPost" v-if="size == 'big' && post && selected">
        {{ translation["POST"][language] }} {{ post }}
      </div>
      <div v-if="size != 'big'">
        <span class="postName" v-if="post">{{ translation["POST"][language] }} {{ post }}</span
        ><span class="postName waiting" v-else>---</span>
      </div>
    </div>
  </div>
</template>

<script>
import translation from "@/config/translation";

export default {
  name: "QueueTicket2",
  props: [
    "size",
    "selected",
    "ticketNumber",
    "time",
    "post",
    "queuePos",
    "previous",
    "currentTicketNumber",
    "language",
    "isNewTicket",
    "queueName",
    "highlight",
    "reduced",
    "inactive",
  ],
  computed: {
    parsedTicketNumber: function() {
      if (typeof this.ticketNumber === "undefined") {
        return "00";
      } else {
        let numberString = this.ticketNumber.toString();
        if (numberString.length === 1) {
          numberString = "0" + numberString;
        }
        return numberString;
      }
    },
    translation: function() {
      return translation["QUEUES_SCREEN"];
    },
    // get only
    /*tkParts: function () {
      if(typeof this.ticketNumber != 'undefined') {
        return this.ticketNumber.split(':');
      } else {
        return ['']
      }
    },*/
    status: function() {
      if (this.selected) {
        return this.translation["STATUS_ONGOING"][this.language];
      } else if (typeof this.previous == "undefined") {
        return this.translation["STATUS_NEXT"][this.language];
      } else if (typeof this.previous != "undefined" && this.previous["ticketNumber"] == this.currentTicketNumber) {
        return this.translation["STATUS_NEXT"][this.language];
      } else if (typeof this.time != "undefined") {
        return this.translation["STATUS_SCHEDULE"][this.language];
      } else {
        return this.translation["STATUS_WAITING"][this.language];
      }
    },
  },
};
</script>

<style scoped>
.medium-ticket {
  width: 19vh;
  margin-right: auto;
  margin-left: auto;
}
.medium-ticket .main {
  height: 19vh;
  width: 19vh;
}
.medium-ticket .main .ticketTitle {
  font-weight: 700; /* Bold */
  font-size: 2.1vh;
  line-height: 3.5vh;
  margin-bottom: 0.2vh;
}
.medium-ticket .main .ticketNumber {
  font-weight: 700; /* Black */
  font-size: 8.5vh;
  line-height: 8.5vh;
}
.inactive-ticket {
  opacity: 0.5;
}

.big-ticket {
  width: 25.4vh;
  margin-right: auto;
  margin-left: auto;
}
.big-ticket .main {
  height: 25.4vh;
  width: 25.4vh;
}

.big-ticket .main .ticketTitle {
  font-weight: 700; /* Bold */
  font-size: 2.7vh;
  line-height: 3.5vh;
}
.big-ticket .main .ticketNumber {
  font-weight: 700; /* Black */
  font-size: 8.5vh;
  line-height: 8.5vh;
}
.big-ticket .main .ticketNumber span {
  font-weight: 700; /* Black */
  font-size: 2.7vh;
}

.big-ticket .main .ticketNumber .queueName {
  font-weight: 700 !important; /* Black */
  font-size: 8.5vh !important;
  line-height: 8.5vh !important;
  height: initial !important;
  margin: 0 !important;
}

/* */
.big-ticket .main .ticketNumber.smaller {
  font-weight: 700; /* Black */
  font-size: 6.5vh;
  line-height: 7.5vh;
}
.big-ticket .main .ticketNumber .long-queue-name {
  font-weight: 400;
  padding-right: 0.3vh;
}

.big-ticket .main .ticketNumber.extra-smaller .long-queue-name {
  font-weight: 400;
  padding-right: 0.3vh;
  font-size: 2.3vh;
}

.big-ticket .main .ticketNumber.extra-smaller {
  font-weight: 700; /* Black */
  font-size: 5.5vh;
  line-height: 6.5vh;
}

/* */

.big-ticket .main .goTo {
  font-weight: 900; /* Black */
  font-size: 2.7vh;
  line-height: 2.7vh;
  margin-top: 1.5vh;
}
.big-ticket .main .ticketPost {
  font-weight: 900; /* Black */
  font-size: 3.5vh;
  line-height: 4vh;
}

.ticket {
  text-align: center;
  color: #ffffff;
}
.ticket.selected {
  font-weight: 700;
}

.ticket .main {
  border: #ffffff solid 0.5vh;
  background-color: #212121;
}

.ticket.selected .main {
  color: #000000;
  border: #ffffff solid 0.5vh;
  background-color: #ffffff;
}

.small-ticket {
  width: 10vh;
  margin-right: 2vh;
  margin-bottom: 2vh;
}
.small-ticket .main {
  height: 10vh;
  width: 10vh;
}
.small-ticket .main .postName {
  font-weight: 700; /* Bold */
  font-size: 1.7vh;
  line-height: 1.9vh;
}

.small-ticket .main .postName.waiting {
  color: rgba(255, 255, 255, 0.5);
}

.small-ticket .main .ticketTitle {
  font-weight: 700; /* Bold */
  font-size: 1.7vh;
  line-height: 1.9vh;
}

.small-ticket .main .ticketNumber {
  font-weight: 700; /* Black */
  font-size: 3.4vh;
  line-height: 3.6vh;
}
.small-ticket .main .ticketNumber.smaller {
  font-weight: 700; /* Black */
  font-size: 2.6vh;
  line-height: 3.6vh;
}
.small-ticket .main .ticketNumber .long-queue-name {
  font-weight: 400;
  padding-right: 0.3vh;
}

.small-ticket .main .ticketNumber.extra-smaller .long-queue-name {
  font-weight: 400;
  padding-right: 0.3vh;
  font-size: 2.3vh;
}

.small-ticket .main .ticketNumber.extra-smaller {
  font-weight: 700; /* Black */
  font-size: 2.3vh;
  line-height: 3.6vh;
}

.small-ticket .main .ticketPost {
  font-weight: 900; /* Black */
  font-size: 2vh;
  line-height: 2vh;
}

.ticket.selected .main .ticketNumber {
  font-weight: 900; /* Black */
}

.time {
  height: 2.2vh;
  font-size: 2vh;
  line-height: 2.2vh;
  margin-top: 1.1vh;
}
.queueName {
  height: 2.2vh;
  line-height: 2.2vh;
  margin-top: 1.1vh;
  font-weight: bold;
  margin-bottom: 1.1vh;
}
.status {
  margin-top: 0.6vh;
  height: 1.5vh;
  font-size: 1.5vh;
  line-height: 1.5vh;
}

/*//// REDUCED VERSION - For high density screens //////*/
.small-ticket.reduced .main {
  border: #ffffff solid 0.38vh;
  background-color: #212121;
}

.small-ticket.reduced.selected .main {
  color: #000000;
  border: #ffffff solid 0.38vh;
  background-color: #ffffff;
}

.small-ticket.reduced {
  width: 8vh;
  margin-right: 2vh;
  margin-bottom: 2vh;
}
.small-ticket.reduced .main {
  height: 8vh;
  width: 8vh;
}
.small-ticket.reduced .main .postName {
  font-weight: 700; /* Bold */
  font-size: 1.5vh;
  line-height: 1.7vh;
}

.small-ticket.reduced .main .ticketTitle {
  font-weight: 700; /* Bold */
  font-size: 1.5vh;
  line-height: 1.7vh;
}

.small-ticket.reduced .main .ticketNumber {
  font-weight: 700; /* Black */
  font-size: 2.7vh;
  line-height: 3.2vh;
}
.small-ticket.reduced .main .ticketNumber.smaller {
  font-weight: 700; /* Black */
  font-size: 2.2vh;
  line-height: 3.2vh;
}
.small-ticket.reduced .main .ticketNumber .long-queue-name {
  font-weight: 400;
  padding-right: 0.1vh;
}

.small-ticket.reduced .main .ticketNumber.extra-smaller .long-queue-name {
  font-weight: 400;
  padding-right: 0.1vh;
  font-size: 2.1vh;
}

.small-ticket.reduced .main .ticketNumber.extra-smaller {
  font-weight: 700; /* Black */
  font-size: 2.1vh;
  line-height: 3.2vh;
}

.small-ticket.reduced .main .ticketPost {
  font-weight: 900; /* Black */
  font-size: 1.8vh;
  line-height: 1.8vh;
}
</style>
