var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ticket",class:{
    selected: _vm.selected,
    'small-ticket': _vm.size == 'small',
    'big-ticket': _vm.size == 'big',
    'medium-ticket': _vm.size == 'medium',
    highlight: _vm.highlight,
    reduced: _vm.reduced,
    'inactive-ticket': _vm.inactive,
  },attrs:{"id":"QueueTicket2"}},[_c('div',{staticClass:"main flex flex-col justify-center"},[_c('div',{staticClass:"ticketNumber",class:{
        smaller: (_vm.parsedTicketNumber.length > 2 && _vm.queueName.length <= 2) || _vm.queueName.length === 2,
        'extra-smaller': _vm.parsedTicketNumber.length > 2 && _vm.queueName.length > 1,
      }},[_c('span',{staticClass:"queueName",class:{ 'long-queue-name': _vm.queueName.length > 1 }},[_vm._v(_vm._s(_vm.queueName))]),_vm._v(_vm._s(_vm.parsedTicketNumber)+" ")]),(_vm.size == 'big')?_c('div',{staticClass:"goTo"},[_vm._v(_vm._s(_vm.translation["GO_TO"][_vm.language]))]):_vm._e(),(_vm.size == 'big' && _vm.post && _vm.selected)?_c('div',{staticClass:"ticketPost"},[_vm._v(" "+_vm._s(_vm.translation["POST"][_vm.language])+" "+_vm._s(_vm.post)+" ")]):_vm._e(),(_vm.size != 'big')?_c('div',[(_vm.post)?_c('span',{staticClass:"postName"},[_vm._v(_vm._s(_vm.translation["POST"][_vm.language])+" "+_vm._s(_vm.post))]):_c('span',{staticClass:"postName waiting"},[_vm._v("---")])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }