<template>
  <div id="FilasAtendimento" class="h-screen w-screen relative overflow-hidden">
    <!-- <transition name="fade" mode="out-in">
      <div v-if="callingTicket!=null" class="ticket-caller-screen h-screen text-white w-screen absolute bg-ucframework top-0 left-0 right-0 bottom-0 flex flex-col justify-center text-center z-20">
        <div class="queue-title queue-title-vertical">{{callingTicket['serviceName']}}</div>
        <div class="queue-subtitle">{{translation['CALLING_TICKET'][language]}}</div>
        <QueueTicket2 :language="language" :key="'calling_ticket'" size="big" :selected="true" :ticketNumber="callingTicket.ticketNumber" :post="callingTicket['workstationName']" :queueName="callingTicket['queueName']" :queuePos="0" />
      </div>
    </transition> -->

    <div class="flex flex-col h-full justify-between">

      <!-- Header Placeholder -->
      <div class="header-placeholder flex-none"></div>

      <!-- Main content -->
      <div class="flex-grow">
        <div class="flex flex-col justify-evenly h-full">
          <div class="queue" v-for="(service, s) in services" :key="'service_'+s">
            <div class="queue-title-wrapper" :ref="'service_title_wrapper_'+s">
              <div v-if="service['long_name']" class="queue-title long-queue-name-title">{{service['name']}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{service['name']}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{service['name']}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{service['name']}}</div>
              <div v-else :ref="'service_title_'+s" class="queue-title">{{service['name']}}</div>
            </div>
            <div class="queue-content">
              <QueueTicket2 v-for="(queue, q) in service.queues" class="list-complete-item" :reduced="denseTickets" :language="language" :inactive="!queue.active" :key="'ticket_'+q" size="small" :selected="lastCalled!==null && lastCalled.ticketNumber === queue['currentTicketNumber'] && lastCalled.queueName === queue['name']" :ticketNumber="queue['currentTicketNumber']" :post="queue['workstationName']" :queueName="queue['name']" />
              <!-- <transition-group name="list-complete" tag="div" class="flex">
                <QueueTicket class="list-complete-item" v-for="(ticket, t) in queue['elements']" :language="language" :previous="queue['elements'][t-1]" :key="'ticket_'+ticket['ticketNumber']" size="small" :time="ticket['time']" :selected="ticket['ticketNumber'] == queue['currentElement']" :currentTicketNumber="queue['currentElement']" :ticketNumber="ticket['ticketNumber']" :post="queue['currentElementPost']" :queuePos="t" />
              </transition-group> -->
            </div>
          </div>
        </div>
      </div>

      <!-- Footer -->
      <div class="footer z-20 flex flex-col justify-center flex-none" v-if="footerText">
        <div class="positioner">
          <div class="label" v-if="editingFooter">Editing footer in {{editingLanguage}}</div>
          <div v-if="editingFooter" ref="footerEditor" :contenteditable="editingFooter" class="footerEditor" :class="{'editing':editingFooter}" @input="onChangeFooter">{{footerTextEditing}}</div>
          <div v-else class="footerEditor">{{footerText}}</div>
        </div>

        <div class="qrcode-interactive-menu z-20" v-if="QRcodeUrl!==''">
          <qr-code :url="QRcodeUrl" :color="'#ffffff'" :bg-color="'#212121'" :level="'L'" ></qr-code>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { nextTick } from 'vue'
//https://www.npmjs.com/package/qrcode.vue
import QRcode from "@/components/QRcode.vue";
import QueueTicket2 from "@/components/qflow/QueueTicket2.vue";
import translation from "@/config/translation";

export default {
  name: "FilasAtendimento",
  components: {QueueTicket2, 'qr-code': QRcode},
  props: {
    infoPlaylistIndex: Number
  },
  data: function () {
    return {
      editingLanguage: "PT",
      crtlPressed: false,
      editingFooter: false,
      footerTextEditing: "",
      //callingTicket: null,
      lastCalled: null,
      //busy: false,
      //refreshDataTimer: null,
      //language: "pt", //auto change from X to X seconds
      //languageChangerTimer: null,
      //languageChangeSeconds: 10,
      QRcodeUrl: ""
    }
  },
  computed: {
    language() {
      return this.$store.state.selectedLanguage;
    },
    denseTickets: function () {
      //true if there is a lot of queues (it reduces the size of que queues boxes)
      return this.services.length >=3;
    },
    translation: function () {
      return translation['QUEUES_SCREEN'];
    },
    // get only
    screenData: function () {
      return {} //this.$index.state.infoPlaylist[this.infoPlaylistIndex];
    },
    services: function () {
      return this.$store.state.servicesData;
    },
    footerText: function () {
      return this.$store.state.footerText[this.language]; //this.screenData['footerText'][this.language];
    },
    QRcodeSize: function () {
      return window.innerHeight*0.08;
    },
    callingTickets: function () {
      return this.$store.state.callingTickets;
    },
    callingTimeSeconds: function () {
      return this.$store.state.callingTimeSeconds;
    },
    refreshDataSeconds:  function () {
      return this.$store.state.refreshDataSeconds;
    },
    callingTicketNumber: function () {
      if(this.callingTicket!==null) {
        return this.callingTicket['queueName'] + this.callingTicket['ticketNumber'].toString();
      } else {
        return "";
      }
    },
  },
  methods: {
    async _keyDownListener(e) {
      if (e.which === 17) {
        this.crtlPressed = true
      } else if (e.which === 70 && this.crtlPressed) {
        console.log(this.$refs);
        //enter editing mode
        if (this.editingFooter === false) {
          this.editingFooter = true;
          this.editingLanguage = "PT";
          this.footerTextEditing = this.$store.state.footerText['pt'];
          //await nextTick()
          console.log('Now DOM is updated')
          let self = this;
          this.$nextTick(() => {
            self.$refs.footerEditor.focus();
          });

        } else {
          if (this.editingLanguage === "PT") {
            this.editingFooter = true;
            this.editingLanguage = "EN";
            this.footerTextEditing = this.$store.state.footerText['en'];
            //await nextTick()
            //this.$refs.footerEditor.focus();
            let self = this;
            this.$nextTick(() => {
              self.$refs.footerEditor.focus();
            });
          } else {
            this.editingFooter = false;
          }
        }
      }
    },
    _keyListener(e) {
      if(e.which === 17) {
        this.crtlPressed = false
      }
    },
    onChangeFooter(e) {
      if(this.editingFooter) {
        this.$store.commit('saveFooterText', {
          language: this.editingLanguage,
          text: e.target.innerText
        })
      }
    },
    autoChangeLanguage: function () {
      if(this.language == "pt") {
        this.language = "en";
      } else {
        this.language = "pt"
      }
    },
    /*refreshData: function () {
      this.$index.dispatch('refreshData');
    },*/
    parseQRCodeUrl: function () {
      let footerText = this.footerText;
      let urlTks = footerText.split(' ');
      for (let t = 0; t < urlTks.length; t++) {
        let urlSubParts = urlTks[t].split('.');
        if(urlSubParts.length > 1) {
          //é provavelmente um link
          let url = urlTks[t].toLowerCase();
          if(url.startsWith('http') === false) {
            url='https://'+url
          }
          this.QRcodeUrl = url;
        }
      }
    },
    checkQueueNamesSize: function() {
      //let windows_width = window.innerWidth;
      let services = this.services;
      for (let s = 0; s < services.length; s++) {
        if(this.$refs['service_title_'+s] && this.$refs['service_title_'+s]!==null) {
          let queue_name_width = this.$refs['service_title_' + s].offsetWidth;
          let queue_wrapper_width = this.$refs['service_title_wrapper_' + s].offsetWidth;
          //console.log("queue_name_width", queue_name_width, queue_wrapper_width);
          if (queue_name_width > queue_wrapper_width && services[s].long_name !== true) {
            this.$store.commit('setLongQueueName', s);
          }
        }
      }
    }
  },
  watch: {
    services: {
      deep: true,
      async handler() {
        let self = this;
        this.$nextTick(() => {
          self.checkQueueNamesSize();
        });
        //await nextTick()
        //this.checkQueueNamesSize();
      }
    },
    footerText: function () {
      this.parseQRCodeUrl();
    }
  },
  mounted: function () {
    /*if(this.refreshDataTimer!=null) {
      clearInterval(this.refreshDataTimer);
    }*/

    /*if(this.languageChangerTimer!=null) {
      clearInterval(this.languageChangerTimer);
    }*/

    /*if(this.refreshDataSeconds>0) {
      let self = this;
      self.refreshData();
      this.refreshDataTimer = setInterval(function(){
        self.refreshData()
      }, this.refreshDataSeconds * 1000);
    }*/

    /*if(this.languageChangeSeconds>0) {
      let self = this;
      this.languageChangerTimer = setInterval(function(){ self.autoChangeLanguage() }, this.languageChangeSeconds * 1000);
    }*/

    window.addEventListener('keydown', this._keyDownListener);
    window.addEventListener('keyup', this._keyListener);

    //CHECK SERVICES NAME SIZE
    this.checkQueueNamesSize();

    //Parse footer url into QR code
    this.parseQRCodeUrl();
  },
  unmounted() {
    /*if(this.refreshDataTimer!=null) {
      clearInterval(this.refreshDataTimer);
    }*/

    /*if(this.languageChangerTimer!=null) {
      clearInterval(this.languageChangerTimer);
    }*/

    window.removeEventListener('keydown', this._keyDownListener);
    window.removeEventListener('keyup', this._keyListener);
  }

}
</script>

<style scoped>
.ticket-caller-screen {
  padding-bottom: 8vh;
}
.header-placeholder {
  height: 8vh;
  min-height: 6vh;
}

.list-complete-item {
  transition: all 1s;
  display: inline-block;
  margin-right: 1.8vh;
}
.list-complete-enter, .list-complete-leave-to
  /* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.list-complete-leave-active {
  position: absolute;
}

.queue {
  margin-bottom: 1.5vh;
}

.queue-title {
  color: #ffffff;
  margin-bottom: 1.6vh;
  font-weight: 700;
  font-size: 3.2vh;
  margin-left: 3vh;
  white-space: nowrap;
  position: absolute;
}
.queue-title-vertical {
  word-spacing: 9999rem;
  line-height: 3.8vh;
  position: initial !important;
  white-space: normal !important;
  margin-left: 0vh !important;
}
.queue-subtitle {
  color: #ffffff;
  margin-bottom: 4.5vh;
  font-weight: 700;
  font-size: 1.5vh;
}
.footer {
  height: 13vh;
  font-weight: 700;
  font-size: 2vh;
  min-height: 2vh;
  color: #ffffff;
}
.footer .positioner {
  position: relative;
  margin-left: 3vh;
}
.footer .positioner .label {
  position: absolute;
  height: 3.5vh;
  line-height: 3.5vh;
  top: -3.5vh;
  left: 0.5vh;
  padding-left: 1.5vh;
  padding-right: 1.5vh;
  background-color: #49a17c;
  border-top-right-radius: 0.7vh;
  border-top-left-radius: 0.7vh;
  color: rgba(0,0,0,0.6);
}
.footerEditor {
  min-height: 2vh;
  border: transparent solid 0.3vh;
  border-radius: 0.5vh;
  outline: none;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  padding-right: 12vh;
}
.footerEditor.editing {
  border: #49a17c solid 0.3vh;
}
.qrcode-interactive-menu {
  background-color: #212121;
  border-radius: 1vh;
  height: 8vh;
  width: 8vh;
  position: absolute;
  bottom: 2.5vh;
  right: 3vh;
}
.queue-content {
  margin-left: 3vh;
}
.queue-title-wrapper {
  height: 5.5vh;
  width: 100%;
  max-width: 100%;
  max-height: 5.5vh;
}
.long-queue-name-title {
  animation-iteration-count: infinite;

  animation-timing-function: linear;
  animation-duration: 34s;
  animation-name: infiniteloopqueuename;
  text-align: left;
  position: absolute;
  left: 0;
  width: 200%;
}
@keyframes infiniteloopqueuename {
  0% {
    transform: translateX(0%);
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    transform: translateX(-175%);
    opacity: 0;
  }
}
</style>