<template>
<div id="VideoPlayer" ref="videob" class="video-box">
  <div class="backlayer-youtube" v-if="videoType === 'youtube'"></div>

  <video v-if="videoUrl!==null && videoType === 'normal'" id="video" ref="video" class="video" autoplay muted>
    <source :src="videoUrl" type="video/mp4">
  </video>
  <!-- Facebook video -->
  <div v-else-if="videoUrl!==null && videoType === 'facebook'" data-controls="false" class="fb-video video" :data-href="videoUrl" :data-height="videoHeight" data-show-text="false" data-autoplay="true"></div>
  <!-- Youtube video -->
  <div :id="'youtube-player'" :style="{height: videoHeight_pre }" v-else-if="videoUrl!==null && videoType === 'youtube'"></div>
  <!-- VIMEO video -->
  <div id="vimeo-player" :style="{height: videoHeight_pre }" v-else-if="videoUrl!==null && videoType === 'vimeo'"></div>

  <transition name="quick-fade" mode="in-out">
    <div class="bg-ucframework more-videos-cover" :style="{backgroundImage: 'url('+videoThumb+')'}" v-if="videoUrl!==null && (videoType === 'facebook' || videoType === 'youtube') && hide"></div>
  </transition>

</div>
</template>

<script>
export default {
  name: "VideoPlayer",
  props: ['videoUrl','showDetails','thumbnailUrl'],
  data: function () {
    return {
      videoPlayer: null,
      eventHandler: null,
      hide: false,
      randomID: 0
    }
  },
  methods: {
    emitEndedEvent() {
      this.$emit('videoended');
    },
    createFBvideo() {
      let self = this;
      // eslint-disable-next-line no-undef
      FB.XFBML.parse();
      // eslint-disable-next-line no-undef
      FB.Event.subscribe('xfbml.ready', function (msg) {
        if (msg.type === 'video') {
          self.videoPlayer = msg.instance;
          self.eventHandler = self.videoPlayer.subscribe('finishedPlaying', function () {
            console.log("Facebook video ended");
            self.hide = true;
            self.emitEndedEvent();
          });
        }
      });
    },
    lodadYTplayer() {
      let self = this;
      if (typeof YT !== 'undefined') {
        this.createYTvideoPlayer();
      } else {
        console.log("Loading Youtube player API");
        //Vue.onYouTubeIframeAPIReady = self.createYTvideoPlayer;
        window.onYouTubeIframeAPIReady = window.onYouTubeIframeAPIReady || self.createYTvideoPlayer;

        var tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      }

    },
    onYTPlayerStateChange(event) {
      // eslint-disable-next-line no-undef
      if (event.data === YT.PlayerState.ENDED) {
        this.hide = true;
        this.emitEndedEvent();
      } else {
        console.log("Youtube player state changed", event);
        this.videoPlayer.mute();
        this.videoPlayer.playVideo();
      }
    },
    onYTPlayerReady() {
      if (this.videoPlayer !== null) {
        this.videoPlayer.mute();
        this.videoPlayer.playVideo();
      }
    },
    YTerror(event) {
      console.log("Youtube player error: ", event);
      this.hide = true;
      this.emitEndedEvent();
    },
    createYTvideoPlayer: function () {
      let self = this;
      console.log("Creating Youtube player: ", self.videoID, this.randomID);
      // eslint-disable-next-line no-undef
      this.videoPlayer = new YT.Player('youtube-player', {
        height: self.videoHeight,
        width: self.videoWidth,
        videoId: self.videoID,
        playerVars: {
          'autoplay': 1, 'controls': 0, 'cc_load_policy': 0,
          'showinfo': 0, 'rel': 0, 'modestbranding': 1
        },
        events: {
          'onReady': self.onYTPlayerReady,
          'onStateChange': self.onYTPlayerStateChange,
          'onError': self.YTerror
        }
      });

    },
    createVimeoPlayer: function () {
      //<iframe  src="https://player.vimeo.com/video/518404753?autoplay=1" style="position:absolute;top:0;left:0;width:100%;height:100%; z-index: 20;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
      // eslint-disable-next-line no-undef
      this.videoPlayer = new Vimeo.Player('vimeo-player', {
        url: this.videoUrl,
        width: this.videoPlayer,
        height: this.videoHeight,
        autoplay: true,
        background: true,
        controls: false,
        loop: false,
        muted: true
      });
      let self = this;
      this.videoPlayer.on('ended', function () {
        console.log("VIMEO video ended")
        this.hide = true;
        self.emitEndedEvent();
      });
    }
  },
  computed: {
    videoID: function () {
      console.log("VIDEOID: ", this.videoUrl);
      if (this.videoType === 'youtube') {
        let parts = this.videoUrl.split('/');
        let id = parts[parts.length - 1];
        console.log("ID: " + id)
        if (typeof id === 'undefined' || id === '') {
          id = parts[parts.length - 2];
        }
        return id //'TPZElgylFgU' //parse video ID!!!
      } else {
        return ''
      }
    },
    videoThumb: function () {
      let videoID = this.videoID;
      if(this.thumbnailUrl!=='' && this.thumbnailUrl!==null && typeof this.thumbnailUrl!=='undefined' && this.videoType !== 'normal') {
        return this.thumbnailUrl;
      } else {
        if (this.videoID !== '' && this.videoType === 'youtube') {
          return 'https://img.youtube.com/vi/' + videoID + '/maxresdefault.jpg'
        } else {
          return ''
        }
      }
    },
    videoWidth: function () {
      console.log(this.$refs, this.$refs['videob']);
      if(this.$refs['videob']) {
        return this.$refs['videob'].offsetWidth;
      } else {
        return window.innerWidth;
      }

      //return window.innerWidth;
    },
    videoType: function() {
      if(this.videoUrl!==null) {
        let video = this.videoUrl.toLowerCase();
        if(video.includes("youtube")) {
          return 'youtube';
        } else if(video.includes("vimeo")) {
          return 'vimeo';
        } else if(video.includes("facebook")) {
          return 'facebook';
        } else {
          return 'normal';
        }
      } else {
        return 'normal';
      }
    },
    videoHeight: function() {
      let height;
      //console.log(this.$refs)
      if(typeof this.$refs['videob'] === 'undefined') {
        height = window.innerHeight;
      } else {
        height = this.$refs['videob'].offsetHeight;
      }

      if(height<this.videoWidth) {
        //usamos o ratio default para os videos UC
        height = this.videoWidth * 0.5654;
      }
      return height;
    },
    videoHeight_pre: function () {
      if(this.videoHeight>this.videoWidth) {

        return '100%'
      } else {
        return this.videoHeight + 'px'
      }
    }
  },
  mounted: function () {
    this.randomID = Math.round(Math.random() * 999999);

    if (this.videoType === 'facebook') {
      this.createFBvideo();
    } else if (this.videoType === 'youtube') {
      this.lodadYTplayer();
    } else if (this.videoType === 'vimeo') {
      this.createVimeoPlayer();
    } else {
      let self = this;
      this.$refs.video.onended = function () {
        this.hide = true;
        self.emitEndedEvent();
      };
    }
  },
  beforeDestroy: function () {
    if(this.eventHandler!==null) {
      this.eventHandler.release('finishedPlaying');
    }
    if(this.videoPlayer!==null && this.videoType === 'youtube') {
      //destroy Youtube video
      //this.videoPlayer.stopVideo();
      this.videoPlayer.destroy();
      this.videoPlayer = null;
    }


  }
}
</script>
<style scoped>
.backlayer-youtube {
  z-index: 49;
  background: rgb(33,33,33);
  background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(33,33,33,1) 72%);
  @apply absolute top-0 left-0 right-0 h-1/6;
}
.video-box {
  position: relative;
  height: 100%;
  width: 100%;
}

#VideoPlayer {
  /*position: fixed;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1;*/
  position: relative;
  height: 100%;
  width: 100%;
  transition: all 1s ease;
}
#vimeo-player {
  /*width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;*/
  height: 100%;
  width: 100%;
  background-color: #212121;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 1s ease;
  -webkit-filter: brightness(80%); /* Safari 6.0 - 9.0 */
  filter: brightness(80%);
  transition: all 1s ease;
}
.more-videos-cover {
  /*width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;*/
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: #212121;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 1s ease;
  -webkit-filter: brightness(80%); /* Safari 6.0 - 9.0 */
  filter: brightness(80%);
  -webkit-filter: grayscale(100%) brightness(80%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%) brightness(80%);
}

.video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-filter: brightness(90%); /* Safari 6.0 - 9.0 */
  filter: brightness(90%);
  background-color: #212121;
  transition: all 1s ease;
}

.divulgation-screen.show-details #video {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-color: #212121;
  height: 50vh;
  -webkit-filter: grayscale(100%) brightness(80%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%) brightness(80%);
  object-fit: cover;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 1s ease;
}

#video {
  background-color: #212121;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-filter: brightness(90%); /* Safari 6.0 - 9.0 */
  filter: brightness(90%);
  transition: all 1s ease;
}
</style>