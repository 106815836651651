<template>
  <div id="app" class="relative w-screen h-screen overflow-hidden">
    <!-- Top Error Message -->
    <transition name="slideerroranimation">
      <div v-if="showError" class="errorMessage">
        {{ errorMessage }}
      </div>
    </transition>

    <!-- Header -->
    <div class="header w-full">
      <img
        @click="settingsClick"
        v-if="interactiveNavigationPath.length == 0"
        id="logo"
        src="@/assets/logo.svg"
      />
      <div
        @click="backInteractiveScreen"
        class="backButton"
        v-if="interactiveNavigationPath.length != 0 && mode === 'dispenser'"
      >
        <img id="arrowBack" class="arrowIcon" src="@/assets/arrowIcon.svg" />
        {{ translation["BACK_BUTTON"][selectedLanguage] }}
      </div>
      <div class="header-corner">
        <loader
          class="corner-loader"
          v-if="mode === 'broadcast' && loading_data"
        ></loader>
        <div class="flex flex-row-reverse" v-if="mode === 'dispenser'">
          <div
            @click="selectLanguage(language)"
            :class="{ selected: selectedLanguage == language }"
            class="language-option"
            v-for="(language, l) in languagesAvailable"
            :key="'language_option_' + l"
          >
            <span>{{ language }}</span>
          </div>
        </div>
        <div v-else-if="mode !== 'broadcast'" class="clock">
          {{ current_time }}
        </div>

        <div class="broken-icon text-ucframework" v-if="no_internet_connection">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>
    </div>

    <!-- Main content with fade transition -->
    <transition name="fade" mode="in-out">
      <LoadingScreen v-if="newTicketData === 'loading'"></LoadingScreen>
      <TicketSplashScreen
        v-else-if="newTicketData !== null"
      ></TicketSplashScreen>
      <Configuration
        v-else-if="
          currentScreenData !== null && currentScreenData['type'] === 'settings'
        "
      ></Configuration>
      <Divulgation
        :key="currentScreenData['id']"
        v-else-if="
          currentScreenData !== null &&
          currentScreenData['type'] == 'announcement'
        "
      />
      <DivulgationAgenda
        :key="currentScreenData['id']"
        v-else-if="
          currentScreenData !== null &&
          (currentScreenData['type'] == 'agenda' ||
            currentScreenData['type'] == 'agenda_today')
        "
      />
      <Marketing
        :key="currentScreenData['id']"
        v-else-if="
          currentScreenData !== null && currentScreenData['type'] == 'marketing'
        "
      />
      <!-- QFLOW SCREENS -->
      <SelectTicketMethodScreen
        v-else-if="
          currentScreenData !== null &&
          currentScreenData['type'] === 'select_ticket_method'
        "
      ></SelectTicketMethodScreen>
      <PhoneNumberInsertScreen
        v-else-if="
          currentScreenData !== null &&
          currentScreenData['type'] === 'insert_phone_number'
        "
      ></PhoneNumberInsertScreen>
      <InteractiveMenu
        v-else-if="
          currentScreenData !== null &&
          (currentScreenData['type'] === 'menu' ||
            currentScreenData['type'] === 'select_queue' ||
            currentScreenData['type'] === 'select_service')
        "
        :pageData="currentScreenData"
        :key="'interactive_' + currentScreenData['id']"
        :navigationPath="interactiveNavigationPath"
      />
      <Queue
        v-else-if="
          currentScreenData !== null &&
          currentScreenData['type'] === 'queues_screen'
        "
      />
      <FailedScreen
        v-else-if="
          currentScreenData !== null &&
          currentScreenData['type'] === 'error_screen'
        "
      ></FailedScreen>
      <!-- END QFLOW SCREENS -->
      <NoContent v-else></NoContent>
    </transition>
    <audio ref="audio" src="@/assets/ticket_call.mp3" preload="auto"></audio>
  </div>
</template>

<script>
import Divulgation from "@/pages/broadcast_screen/Divulgation";
import DivulgationAgenda from "@/pages/broadcast_screen/DivulgationAgenda";
import Marketing from "@/pages/broadcast_screen/Marketing";
import NoContent from "@/pages/NoContentScreen";
import Loader from "@/components/Loader";
// QFLOW //
import translation from "@/./config/translation";
import Queue from "@/pages/queues_screen/Queues.vue";
import InteractiveMenu from "@/pages/dispenser/InteractiveMenu.vue";
import Configuration from "@/pages/Configuration.vue";
import LoadingScreen from "@/pages/LoadingScreen.vue";
import FailedScreen from "@/pages/ScreenFailureScreen.vue";
import TicketSplashScreen from "@/pages/TicketSplashScreen.vue";
import PhoneNumberInsertScreen from "@/pages/dispenser/PhoneNumberInsertScreen";
import SelectTicketMethodScreen from "@/pages/dispenser/SelectTicketMethodScreen";

export default {
  name: "App",
  components: {
    PhoneNumberInsertScreen,
    Loader,
    Marketing,
    Divulgation,
    DivulgationAgenda,
    NoContent,
    // QFLOW COMPONENTS:
    TicketSplashScreen,
    FailedScreen,
    LoadingScreen,
    Configuration,
    InteractiveMenu,
    Queue,
    SelectTicketMethodScreen,
  },
  data: function () {
    return {
      scan: null,
      crtl_key: false,
      clicks: 0,
      clicks_timer: null,
      /*
      QFLOW DATA:
      */
      service: null, //GET_TICKET | GIVE_FEEDBACK
      serviceData: null, //id: NUMBER, name: STRING

      //Timer of no activity
      no_activity_timer: null,

      //Clock timer
      clock_timer: null,
      current_time: "--:--",
      //ticket calling:
      busy: false,
      soundPlaying: false,
    };
  },
  computed: {
    interactiveNavigationPath: function () {
      return this.$store.state.interactiveNavigationPath;
    },
    loading_data: function () {
      return this.$store.state.loading_data;
    },
    // connection error
    network_error: function () {
      return this.$store.state.network_error;
    },
    no_internet_connection: function () {
      return this.$store.state.no_internet_connection;
    },

    /* SHOW ERROR MESSAGE */
    errorMessage: function () {
      return this.$store.state.errorMessage;
    },
    showError: function () {
      return this.$store.state.showError;
    },

    screenID: function () {
      return this.$store.state.screenID;
    },
    infoPlaylist: function () {
      return this.$store.state.infoPlaylist;
    },
    currentScreen: function () {
      return this.$store.state.currentScreen;
    },
    currentScreenData: function () {
      return typeof this.$store.state.currentScreenData !== "undefined"
        ? this.$store.state.currentScreenData
        : null;
    },
    carouselSeconds: function () {
      return this.$store.state.carouselSeconds;
    },

    /// QFLOW COMPUTED COMPONENTS
    //USER INTERFACE TRANSLATION
    translation: function () {
      return translation["SERVICES_SCREEN"]; //both for get ticket and give feedback
    },
    //NEW TICKET
    newTicketData: function () {
      return this.$store.state.newTicketData;
    },
    streamData: function () {
      return this.$store.state.streamData;
    },
    mode: function () {
      return this.$store.state.mode;
    },
    languagesAvailable: function () {
      return this.$store.state.languagesAvailable;
    },
    selectedLanguage: function () {
      return this.$store.state.selectedLanguage;
    },
    // QFLOW
    callingTickets() {
      return this.$store.state.callingTickets;
    },
  },
  methods: {
    printTest() {
      this.$store.dispatch("testsPrinter");
    },
    settingsClick() {
      this.clicks++;
      if (this.clicks === 1) {
        this.clicks_timer = setTimeout(() => {
          this.clicks = 0;
        }, 1000);
      } else if (this.clicks === 5) {
        this.$store.dispatch("openScreen", { type: "settings" });
        clearTimeout(this.clicks_timer);
        this.clicks = 0;
      }
      //console.log(this.clicks);
    },

    timeTick() {
      let d = new Date();
      let h = d.getHours();
      if (h < 10) {
        h = "0" + h;
      }
      let m = d.getMinutes();
      if (m < 10) {
        m = "0" + m;
      }
      this.current_time = h + ":" + m;
    },
    _keyListener(e) {
      if (e.which === 17) {
        e.preventDefault();
        this.crtl_key = false;
      }
    },
    _keyDownListener(e) {
      if (e.which === 17) {
        e.preventDefault();
        this.crtl_key = true;
      } else if (e.which === 65) {
        if (this.crtl_key) {
          e.preventDefault();
          /*if(this.configurationScreen) {
            this.configurationScreen = false;
          } else {
            this.configurationScreen = true;
          }*/
          //TODO: when deactivating... go to default page!
          this.$store.commit("setCurrentScreen", "settings");

          /*
          if(this.currentScreen === 'add_service') {
            //retorna ao modo normal
            if(this.mode === 'screen') {
              this.$index.commit('setCurrentScreen','queues_screen');
            } else {
              this.$index.commit('setCurrentScreen','get_ticket_screen');
            }
          } else {

            this.$index.commit('setCurrentScreen','add_service');
            /*****
             * TODO: QFLOW ADMIN PANE
             */

          /* }*/
        }
      }
    },
    nextSlide() {
      this.$store.dispatch("nextSlide");
    },
    logDeviceData(labelOfDataSource, key, valueDataView) {
      const hexString = [...new Uint8Array(valueDataView.buffer)]
        .map((b) => {
          return b.toString(16).padStart(2, "0");
        })
        .join(" ");
      const textDecoder = new TextDecoder("ascii");
      const asciiString = textDecoder.decode(valueDataView.buffer);
      console.log(
        `  ${labelOfDataSource} Data: ` +
          key +
          "\n    (Hex) " +
          hexString +
          "\n    (ASCII) " +
          asciiString
      );
    },
    stopScan() {
      console.log("Stopping scan...");
      this.scan.stop();
      console.log("Stopped.  scan.active = " + this.scan.active);
    },
    async scanDevices() {
      // TODO : This method must be called by an user triggered action
      let options = {};
      options.acceptAllAdvertisements = true;
      let devices = {};
      let self = this;
      try {
        navigator.bluetooth
          .getAvailability()
          .then(async (isBluetoothAvailable) => {
            if (isBluetoothAvailable) {
              console.log(
                "Requesting Bluetooth Scan with options: " +
                  JSON.stringify(options)
              );
              self.scan = await navigator.bluetooth.requestLEScan(options);

              console.log(" Scan started with:");
              console.log(
                " acceptAllAdvertisements: " + self.scan.acceptAllAdvertisements
              );
              console.log(" active: " + self.scan.active);
              console.log(
                " keepRepeatedDevices: " + self.scan.keepRepeatedDevices
              );

              navigator.bluetooth.addEventListener(
                "advertisementreceived",
                (event) => {
                  //console.log('Advertisement received.');
                  //console. log('Device Name: ' + event.device.name+'  Device ID: ' + event.device.id);
                  devices[event.device.id] =
                    event.device.name + ":" + event.txPower + ":" + event.rssi;
                  console.log(devices);

                  //console.log('  RSSI: ' + event.rssi);
                  //console.log('  TX Power: ' + event.txPower);
                  //console.log('  UUIDs: ' + event.uuids);
                  /*event.manufacturerData.forEach((valueDataView, key) => {
                    self.logDeviceData('Manufacturer', key, valueDataView);
                  });
                  event.serviceData.forEach((valueDataView, key) => {
                    self.logDeviceData('Service', key, valueDataView);
                  });*/
                }
              );

              setTimeout(self.stopScan, 10000);
            }
          });
      } catch (error) {
        console.log("Argh! " + error);
      }
    },
    /**
     * QFLOW METHODS:
     */
    selectLanguage(language) {
      this.$store.commit("setCurrentLanguage", language);
    },
    backInteractiveScreen() {
      this.$store.dispatch("backInteractiveScreen");
    },
    playSound() {
      console.log("[CALLING TICKET] playing sound");
      this.$refs.audio.play();
    },
    callingNext() {
      console.log("[CALLING TICKET] callingNext");
      if (this.callingTickets.length > 0 && this.callingTicket == null) {
        if (this.soundPlaying === false) {
          this.soundPlaying = true;
          this.playSound();
        }

        this.busy = true;

        console.log("[CALLING TICKET] ", this.callingTickets[0]);

        //this.callingTicket = this.callingTickets[0];
        this.$store.commit("setNewTicketData", this.callingTickets[0]);

        //TODO: use service id to distinguish tickets with the same number...
        /*if(this.callingTicket) {
          console.log('callingTicketNumber', this.callingTicket );
          this.lastCalled = this.callingTicket ;
        }*/

        //remove senha da fila para chamar
        this.$store.commit("removeCallingTicket");

        //timer to call itself
        let self = this;
        window.setTimeout(function () {
          self.soundPlaying = false; //reset ao som após X callingTimeSeconds
          self.callingNext();
        }, this.$store.state.callingTimeSeconds * 1000);
      } else if (this.callingTickets.length == 0) {
        if (this.busy) {
          console.log("[CALLING TICKET] PÁRA DE MOSTRAR SENHAS");
          this.busy = false;
          this.soundPlaying = false;
          this.callingTicket = null;
          this.$store.commit("setNewTicketData", null);
        }
      }
    },
  },
  created: function () {
    //Save params from URL to localstorage
    let services = [];
    let apiKeys = [];
    const queryString = window.location.search.substring(1);
    let urlParts = queryString.split("&");
    console.log("queryString", queryString, urlParts)
    for (let i = 0; i < urlParts.length; i++) {
      let parameter = urlParts[i].split("=");
      if (
        parameter[0] === "screenID" ||
        parameter[0] === "accessToken" ||
        parameter[0] === "deviceMode"
      ) {
        localStorage.setItem(parameter[0], JSON.stringify(parameter[1]));
      }
      if (parameter[0] === "service") {
        services.push(parseInt(parameter[1]))
      }
      if (parameter[0] === "apiKey") {
        apiKeys.push(parameter[1])
      }
      if(parameter[0] === "ignore") {
        localStorage.setItem('ignoreQueuesIds', JSON.stringify(parameter[1].split(",").map(id => id.toString())));
      }
    }
    if(services.length > 0) {
      localStorage.setItem("selectedServicesIDS", JSON.stringify(services));
    }
    if(apiKeys.length > 0) {
      localStorage.setItem("apiKeys", JSON.stringify(apiKeys));
    }
    console.log(queryString);
  },
  mounted: function () {
    this.$store.dispatch("start");
    //this.scanDevices();
    window.addEventListener("keyup", this._keyListener);
    window.addEventListener("keydown", this._keyDownListener);
  },
  unmounted() {
    window.removeEventListener("keyup", this._keyListener);
    window.removeEventListener("keydown", this._keyDownListener);
  },
  watch: {
    mode: function (val) {
      if (this.clock_timer !== null) {
        clearInterval(this.clock_timer);
      }
      if (val === "screen") {
        let self = this;
        this.clock_timer = setInterval(function () {
          self.timeTick();
        }, 1000);
      }
    },
    //QUEUES SYSTEM
    callingTickets: {
      deep: true,
      handler() {
        console.log("[CALLING TICKET] watch trigger");
        if (this.busy === false) {
          console.log("[CALLING TICKET] watch trigger -> calling Next");
          this.callingNext();
        }
      },
    },
  },
};
</script>

<style>
.loader-pos {
  right: 3vh;
  top: 3vh;
}

.broken-icon {
  display: inline-block;
  margin-top: 2vh;
  margin-right: 0.5vh;
}
.broken-icon svg {
  height: 3vh;
  width: 3vh;
}

.errorMessage {
  position: absolute;
  font-size: 3vh;
  line-height: 3.5vh;
  color: white;
  background-color: crimson;
  font-weight: bold;
  padding: 2vh;
  z-index: 99;
  left: 0;
  right: 0;
  top: 0;
  text-align: center;
}

.slideerroranimation-enter-active {
  -moz-transition-duration: 0.6s;
  -webkit-transition-duration: 0.6s;
  -o-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}

.slideerroranimation-leave-active {
  -moz-transition-duration: 0.6s;
  -webkit-transition-duration: 0.6s;
  -o-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}

.slideerroranimation-enter-to,
.slideerroranimation-leave {
  overflow: hidden;
  max-height: 50%;
}

.slideerroranimation-enter,
.slideerroranimation-leave-to {
  overflow: hidden;
  max-height: 0;
}

.language-option {
  padding: 1.8vh 1.3vh;
  margin-left: 0vh;
  margin-right: 0vh;
  cursor: pointer;
  text-transform: uppercase;
}
.language-option.selected {
  font-weight: bold;
}

.language-option.selected span {
  border-bottom: white solid 0.6vh;
  padding: 0.4vh 0.1vh;
}

#logo {
  height: 4vh;
  position: absolute;
  top: 2.5vh;
  left: 3vh;
  z-index: 50;
}
.backButton {
  position: absolute;
  top: 2.5vh;
  left: 3vh;
  color: white;
  font-size: 2vh;
  line-height: 2.6vh;
  z-index: 50;
  cursor: pointer;
}
.backButton .arrowIcon {
  display: inline-block;
  margin-right: 1vh;
  height: 2.3vh;
  margin-top: -0.35vh;
}

/* QFLOW CSS */
.header-corner .clock {
  height: 3vh;
  line-height: 3vh;
  padding-top: 1.75vh;
  padding-right: 1vh;
}
.header-corner {
  @apply w-1/2 flex flex-row-reverse;
  color: white;
  font-size: 2vh;
  text-transform: uppercase;
  right: 1.8vh;
  top: 1vh;
  position: absolute;
  z-index: 11;
}

.header-corner .corner-loader {
  @apply mt-3 mr-3;
}

/* QFLOW CSS
.broken-icon {
  display: inline-block;
  padding-top: 0.75vh;
  padding-right: 1vh;
}
.broken-icon svg {
  height: 3vh;
  width: 3vh;
}

.errorMessage {
  position: absolute;
  font-size: 3vh;
  line-height: 3.5vh;
  color: white;
  background-color: crimson;
  font-weight: bold;
  padding: 2vh;
  z-index: 99;
  left: 0;
  right: 0;
  top: 0;
  text-align: center;
}

.slideerroranimation-enter-active {
  -moz-transition-duration: 0.6s;
  -webkit-transition-duration: 0.6s;
  -o-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function:ease-in-out;
  transition-timing-function: ease-in-out;
}

.slideerroranimation-leave-active {
  -moz-transition-duration: 0.6s;
  -webkit-transition-duration: 0.6s;
  -o-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}

.slideerroranimation-enter-to, .slideerroranimation-leave {
  overflow: hidden;
  max-height: 50%;
}

.slideerroranimation-enter, .slideerroranimation-leave-to {
  overflow: hidden;
  max-height: 0;
}
.header-corner {
  color: white;
  font-size: 2vh;
  text-transform: uppercase;
  margin-right: 3vh;
}

.invisible-button-bottom-right.showBorder {
  border: 1vh solid chartreuse;
}

.invisible-button-bottom.showBorder {
  border: 1vh solid wheat;
}

.invisible-button-top.showBorder {
  border: 1vh solid red;
}

.debugpanel {
  z-index: 51;
}
.header {
  position: absolute;
  top: 2.5vh;
  z-index: 50;
}
#logo {
  height: 4.2vh;
  margin-left: 3vh;
}
.backButton {
  color: white;
  font-size: 2.2vh;
  line-height: 4.2vh;
  cursor: pointer;
  margin-left: 3vh;
}
.backButton .arrowIcon {
  display: inline-block;
  margin-right: 1vh;
  height: 2.8vh;
  margin-top: -0.2vh;
}
 */
</style>
