<template>
  <div
    id="divulgation"
    class="divulgation-screen bg-ucframework relative h-screen w-screen"
    :class="{ 'show-details': showDetails }"
  >
    <!-- Background media -->
    <VideoPlayer
      :key="bgvideo"
      v-if="bgvideo !== null"
      :video-url="bgvideo"
      :thumbnail-url="bgimage"
      :show-details="showDetails"
      v-on:videoended="onVideoEnded"
    ></VideoPlayer>
    <div
      v-else-if="bgimage !== null"
      class="bg-image h-screen w-screen z-0 absolute"
      :style="{ backgroundImage: 'url(' + bgimage + ')' }"
    ></div>

    <!-- Details Text -->
    <div class="text-box">
      <p class="title free-text">{{ title }}</p>
      <p class="tag">{{ tag }}</p>
      <div class="details" :style="{ height: detailsHeight + 'px' }">
        <p class="innerbox free-text" ref="detail-text-inner-box">
          {{ details }}
        </p>
      </div>
    </div>

    <!-- Footer -->
    <transition name="fade" mode="out-in">
      <div
        class="footer flex flex-col justify-center z-30"
        v-if="footerText && footerText !== null && showDetails"
      >
        <div class="free-text">{{ footerText }}</div>
      </div>
    </transition>

    <!-- Footer QRcode -->
    <transition name="fade" mode="out-in">
      <div class="qrcode-footer" v-if="QRcodeUrl != null && showDetails">
        <qr-code
          :url="QRcodeUrl"
          :color="'#ffffff'"
          :bg-color="'#212121'"
          :level="'L'"
        ></qr-code>
      </div>
    </transition>
  </div>
</template>

<script>
import VideoPlayer from "@/components/VideoPlayer";
import QRcode from "@/components/QRcode.vue";

export default {
  name: "divulgation",
  data: function () {
    return {
      showDetails: false,
      //timer: null,
      detailsHeight: 0,
      //videoPlayer: null,
      //eventHandler: null,

      //Timer with 10 minutes used to go to next slide in the event of software failure
      //backupNextSlideTimer: null,

      timers: [],
    };
  },
  beforeDestroy: function () {
    /*if(this.eventHandler!==null) {
      this.eventHandler.release('finishedPlaying');
    }*/

    //REMOVE ALL TIMERS
    console.log("REMOVING ALL TIMERS");
    for (let i = 0; i < this.timers.length; i++) {
      if (this.timers[i] !== null) {
        clearTimeout(this.timers[i]);
      }
    }

    /*if(this.backupNextSlideTimer!==null) {
      clearTimeout(this.backupNextSlideTimer);
    }*/
  },
  mounted: function () {
    // `this` points to the vm instance
    let self = this;
    if (this.bgvideo === null) {
      //If is not a video we define the time to show details and to pass to next slide
      this.timers.push(
        setTimeout(function () {
          self.toogleDetails();
        }, (self.slideSeconds * 1000) / 3)
      );
      this.timers.push(
        setTimeout(function () {
          self.$store.dispatch("nextSlide");
        }, self.slideSeconds * 1000)
      );
    }

    //BACKUP NEXT SLIDE TIMER (10 minutes):
    //this.backupNextSlideTimer =
    this.timers.push(
      setTimeout(function () {
        console.log("Software Error - BACKUP next slide working....");
        self.$store.dispatch("nextSlide");
      }, 600000)
    );
  },
  methods: {
    toogleDetails: function () {
      //Show details only if there is information to show
      if (
        this.details !== null &&
        this.details !== "" &&
        this.title !== null &&
        this.title !== ""
      ) {
        this.showDetails = !this.showDetails;
        if (this.showDetails) {
          this.detailsHeight = this.$refs["detail-text-inner-box"].offsetHeight;
        } else {
          this.detailsHeight = 0;
        }
      }
    },
    onVideoEnded() {
      let time = this.slideSeconds * 1000;
      console.log("Video ended!");
      this.toogleDetails();
      let self = this;
      this.timers.push(
        setTimeout(function () {
          console.log("VIDEO TERMINOU -> next slide!");
          self.$store.dispatch("nextSlide");
        }, time)
      );
    },
  },
  computed: {
    videoHeight: function () {
      return window.innerHeight;
    },
    slideSeconds: function () {
      return this.$store.state.carouselSeconds;
    },
    screenData: function () {
      return this.$store.state.currentScreenData;
    },
    bgimage: function () {
      return this.screenData["backgroundImage"] !== "" &&
        this.screenData["backgroundImage"] !== null
        ? this.screenData["backgroundImage"]
        : null;
    },
    bgvideo: function () {
      return this.screenData["backgroundVideo"] !== "" &&
        this.screenData["backgroundVideo"] !== null &&
        typeof this.screenData["backgroundVideo"] !== "undefined"
        ? this.screenData["backgroundVideo"]
        : null;
    },
    title: function () {
      return this.screenData["title"];
    },
    tag: function () {
      //Remove tag
      return ""; //this.screenData['tag'];
    },
    details: function () {
      return this.screenData["detailText"];
    },
    QRcodeUrl: function () {
      return typeof this.screenData["QRcodeUrl"] != "undefined" &&
        this.screenData["QRcodeUrl"] != ""
        ? this.screenData["QRcodeUrl"]
        : null;
    },
    QRcodeSize: function () {
      return window.innerHeight * 0.06;
    },
    footerText: function () {
      return this.screenData["footerText"];
    },
  },
  components: { VideoPlayer, "qr-code": QRcode },
};
</script>

<style scoped>
.divulgation-screen .bg-image {
  background-color: #212121;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 1s ease;
  -webkit-filter: brightness(80%); /* Safari 6.0 - 9.0 */
  filter: brightness(80%);
}
.divulgation-screen.show-details .bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 50vh;
  -webkit-filter: grayscale(100%) brightness(80%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%) brightness(80%);
}

.divulgation-screen.show-details .text-box {
  background-color: rgb(33, 33, 33);
}
.divulgation-screen .text-box {
  background: linear-gradient(rgba(33, 33, 33, 0.2), rgba(33, 33, 33, 0.9));
  color: white;
  z-index: 12;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 5vh;
  transition: all 1s ease;
}
.text-box .title {
  color: #ffffff;
  margin-bottom: 1.6vh;
  font-weight: 700;
  font-size: 5.5vh;
  line-height: 5.2vh;
  padding-left: 0vh;
  padding-right: 0vh;
}
.text-box .tag {
  color: #ffffff;
  margin-bottom: 1.6vh;
  font-weight: 700;
  font-size: 2.5vh;
  transition: all 1s ease;
}
.divulgation-screen.show-details .text-box .title {
  color: #ffffff;
  margin-bottom: 1.6vh;
  font-weight: 700;
  font-size: 3.5vh;
  line-height: 4.2vh;
}
.divulgation-screen.show-details .text-box .tag {
  color: #ffffff;
  margin-bottom: 1.6vh;
  font-weight: 500;
  font-size: 2.5vh;
}
.divulgation-screen .text-box .details .innerbox {
  padding-bottom: 14vh;
  padding-top: 4vh;
  font-size: 2.6vh;
  line-height: 3.3vh;
}
.divulgation-screen .text-box .details {
  height: 0px;
  overflow: hidden;
  transition: all 1s ease;
}

.divulgation-screen .footer {
  left: 0vh;
  bottom: 0px;
  right: 0px;
  position: absolute;
  height: 13.2vh;
  font-weight: 700;
  font-size: 2vh;
  min-height: 2vh;
  color: #ffffff;
  z-index: 15;
}
.divulgation-screen .footer div {
  margin-left: 5vh;
}
.divulgation-screen .qrcode-footer {
  padding: 0vh;
}
</style>